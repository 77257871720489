import React from 'react';




export default function HomeSidePanel (props) {


    const useraccessDescription = ('New users can join your company by signing up with either access code.\n\n*Admins have full view/edit capabilities.\n**Standard users get interview access only.')

    return (
        <div className="home-sidepanel-body">
            <div className="home-sidepanel-header">App Portal</div>
            <div className="home-sidepanel-section" > 
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', marginRight: '10px'}}>
                    <div>User Access Codes</div>
                </div>
                <div className="home-sidepanel-access-codes">
                    <div className="home-sidepanel-access-code">Admin* : A{props.companyInfo.id}</div>
                    <div className="copy-button" style={{display: 'flex', margin: '0'}}>
                        <img
                        src="./Assets/copy-icon-white.png"
                        style={{height: '25px'}}
                        onClick={() => navigator.clipboard.writeText(`A${props.companyInfo.id}`)}
                        alt="copy"
                        />
                    </div>
                </div>
                <div className="home-sidepanel-access-codes">
                    <div className="home-sidepanel-access-code">Standard** : S{props.companyInfo.id}</div>
                    <div className="copy-button" style={{display: 'flex', margin: '0'}}>
                        <img
                        src="./Assets/copy-icon-white.png"
                        style={{height: '25px'}}
                        onClick={() => navigator.clipboard.writeText(`S${props.companyInfo.id}`)}
                        alt="copy"
                        />
                    </div>
                </div>
            </div>
            <div className="home-sidepanel-section" > 
                <div className="home-sidepanel-section-instructions">{useraccessDescription}</div>
            </div>
            <div className="home-sidepanel-section" > 
                <div className="home-sidepanel-section-item" onClick={()=>props.selectModule('users')}>
                    Manage Users
                </div>
            </div>
        </div>
    )
}
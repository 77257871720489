import React, { useState, useEffect } from 'react'
import './analysis.css'
import Examination from './Examination'
import {API, graphqlOperation} from 'aws-amplify';
import * as mutations from '../../../graphql/mutations';
import * as queries from '../../../graphql/queries';

export default function Analysis(props) {

    const [showMenu, setShowMenu] = useState(false)
    const [view, setView] = useState('dashboard')
    const [examinationInfo, setExaminationInfo] = useState()
    const [examinations, setExaminations] = useState([])

    useEffect(() => {
        if (props.view) {
            setView(props.view)
        }
    }, [props.view])

    useEffect(() => {
        if (props.info) {
            setExaminationInfo(props.info)
        }
    }, [props.info])

    useEffect(() => {
        if (props.userInfo) {
            fetchExaminations()
        }
    }, [props.userInfo, view])

    async function fetchExaminations() {
        try {
            const {data} = await API.graphql(graphqlOperation(queries.getCompany, {id: props.userInfo.company.id}))
            console.log('Examinations:', data.getCompany.examinations.items)
            setExaminations(data.getCompany.examinations.items)
        } catch (error) {
            console.log('Error:', error)
        }
    }

    const examinationsList = examinations.map((examination, index) => {
        return (
            <div className="analysis-examination-list-item" key={index} onClick={()=>{setExaminationInfo(examination); setView('examination')}}>{examination.name}</div>
        )
    })

    async function deleteExamination() {
        setView('dashboard')
        setShowMenu(false)
        try {
            const {data} = await API.graphql(graphqlOperation(mutations.deleteExamination, {input: {id: examinationInfo.id}}))
            console.log('Examination Deleted:', data.deleteExamination)
        } catch (error) {
            console.log('Error:', error)
        }
    }

    const title = (
        <div className="standard-floating-header-background" style={{width: '100%', height: '150px', marginTop: '30px', paddingTop: '20px'}}>
            Examine Strategy Interviews
        </div>
    )

    const [showMeatballMenu, setShowMeatballMenu] = useState(false)
    const meatballMenu = (
        <div>
            <div className="meatballMenu" onClick={()=>setShowMeatballMenu(!showMeatballMenu)}></div>
            {showMeatballMenu && <div className="main-menu meatballLeft">
                <button className="button small" onClick={()=>setView('dashboard')} >Back</button>
                <button className="button small" onClick={deleteExamination}>Delete</button>
            </div>}
        </div>
    )
    
    const [showSkewerMenu, setShowSkewerMenu] = useState(false)
    const skewerMenu = (
        <div className="skewerMenu" onClick={()=>setShowSkewerMenu(!showSkewerMenu)}>
            {showSkewerMenu && <div className="main-menu skewerRight">
                {view === 'examination' && <div className='main-menu-item' onClick={deleteExamination}>Delete</div>}
            </div>}
        </div>
    )

    const pageHeader = (
        <div className="standard-floating-header">
            <div className="standard-floating-menu-container left">{view === 'examination' && meatballMenu}</div>
            <div className="standard-floating-title"></div>
            <div className="standard-floating-menu-container right">{view === 'test' && skewerMenu}</div>
        </div>
    )
    
    return (
        <div className="standard-main">
            {pageHeader}
            {view === 'dashboard' && title}
            {view === 'dashboard' ? <div className="analysis-dashboard">
                <div className="analysis-dashboard-lens">
                    <img src="./Assets/mag-glass.png" alt='lens' style={{width: '90%'}} />
                </div>
                <div className="analysis-dashboard-card">
                    <div className="analysis-dashboard-table-header">Examination Groups</div>
                    <div className="standard-divider short"></div>
                    <div className="analysis-dashboard-table">{examinationsList}</div>
                </div>
            </div>
            : null}
            {view === 'examine' && <div>
                <div>Examine</div>
            </div>}
            {view === 'examination' && <Examination info={examinationInfo} />}
        </div>
    )
}
import React, {useState, useEffect} from 'react'
import './Settings.css'
import { API, graphqlOperation } from 'aws-amplify';
import * as mutations from '../../graphql/mutations';
import * as queries from '../../graphql/queries';


export default function Prompts(props){
    const [update, setUpdate] = useState(false);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [promptData, setPromptData] = useState({id: 'default'});

    useEffect(() => {
        async function fetchPrompts() {
            const { data } = await API.graphql(graphqlOperation(queries.getPrompt, {id: 'default'}));
            setPromptData(data.getPrompt);
        }
        fetchPrompts();
    }, []);
    
    function handleChange(e) {
        setPromptData(prevState => {
          if (e.target.name === 'externalFull') {
            return {...prevState, externalFull: e.target.value}
            } else if (e.target.name === 'cultureFull') {
            return {...prevState, cultureFull: e.target.value}
            } else if (e.target.name === 'internalFull') {
            return {...prevState, internalFull: e.target.value}
            } else if (e.target.name === 'foundationFull') {
            return {...prevState, foundationFull: e.target.value}
            } else if (e.target.name === 'customerFull') {
            return {...prevState, customerFull: e.target.value}
            } else if (e.target.name === 'customerShort') {
            return {...prevState, customerShort: e.target.value}
            } else if (e.target.name === 'productFull') {
            return {...prevState, productFull: e.target.value}
            } else if (e.target.name === 'productShort') {
            return {...prevState, productShort: e.target.value}
            } else if (e.target.name === 'operationsFull') {
            return {...prevState, operationsFull: e.target.value}
            } else if (e.target.name === 'operationsShort') {
            return {...prevState, operationsShort: e.target.value}
            } else if (e.target.name === 'peopleFull') {
            return {...prevState, peopleFull: e.target.value}
            } else if (e.target.name === 'peopleShort') {
            return {...prevState, peopleShort: e.target.value}
            } else if (e.target.name === 'interviewSummary') {
            return {...prevState, interviewSummary: e.target.value}
            } else {
            return prevState;
          }
        });
        setShowSuccessMessage(false)
        setUpdate(true)
      }

    async function handleUpdate() {
        const { createdAt, updatedAt, ...inputData } = promptData;
        console.log('inputData', inputData)
        try {
            const updatedPrompt = await API.graphql(graphqlOperation(mutations.updatePrompt, { input: inputData }));
            console.log('Updated prompt: ', updatedPrompt);
            setUpdate(false)
            setShowSuccessMessage(true)
          } catch (error) {
            console.log('Error updating prompt: ', error);
          }
    }

    return (
        <div className="settings-prompts">
            <div className="settings-prompts-section">
                <div className="settings-instructions">
                    <div className="instruction">
                        <div>Interview Summary</div>
                        <textarea className="instruction-text prompt" name="interviewSummary" value={promptData.interviewSummary} onChange={handleChange}></textarea>
                    </div>
                    <div className="instruction">
                        <div>External Full</div>
                        <textarea className="instruction-text prompt" name="externalFull" value={promptData.externalFull} onChange={handleChange}></textarea>
                    </div>
                    <div className="instruction">
                        <div>Internal Full</div>
                        <textarea className="instruction-text prompt" name="internalFull" value={promptData.internalFull} onChange={handleChange}></textarea>
                    </div>
                </div>
                <div className="settings-instructions">
                    <div className="instruction">
                        <div>Culture Full</div>
                        <textarea className="instruction-text prompt" name="cultureFull" value={promptData.cultureFull} onChange={handleChange}></textarea>
                    </div>
                    <div className="instruction">
                        <div>Foundation Full</div>
                        <textarea className="instruction-text prompt" name="foundationFull" value={promptData.foundationFull} onChange={handleChange}></textarea>
                    </div>
                    <div className="instruction">
                        <div>Customer Full</div>
                        <textarea className="instruction-text prompt" name="customerFull" value={promptData.customerFull} onChange={handleChange}></textarea>
                    </div>
                </div>   
                <div className="settings-instructions">
                    <div className="instruction">
                        <div>Product Full</div>
                        <textarea className="instruction-text prompt" name="productFull" value={promptData.productFull} onChange={handleChange}></textarea>
                    </div>
                    <div className="instruction">
                        <div>Operations Full</div>
                        <textarea className="instruction-text prompt" name="operationsFull" value={promptData.operationsFull} onChange={handleChange}></textarea>
                    </div>
                    
                    <div className="instruction">
                        <div>People Full</div>
                        <textarea className="instruction-text prompt" name="peopleFull" value={promptData.peopleFull} onChange={handleChange}></textarea>
                    </div>
                </div>
            </div>
            <div>
                {update ? <button className="button" onClick={handleUpdate}>Update</button> : null}
                {showSuccessMessage ? <div className="success-message">Saved prompt data!</div> : null}
            </div> 
        </div>
        
    )
}


import React, {useState, useEffect} from 'react';
import './demo.css';
import InterviewDemo from './InterivewDemo';
import SummaryDemo from './SummaryDemo';
import StrategyDemo from './StrategyDemo';



export default function Demo () {
    const toggle = true

    const [showFirst, setShowFirst] = useState(false)
    const [showSecond, setShowSecond] = useState(false)
    const [showThird, setShowThird] = useState(false)
    const [frameFour, setFrameFour] = useState(false)
    const [showTitle, setShowTitle] = useState(false);
    const [title, setTitle] = useState('Our virtual consultant facilitates strategy interviews with your employees.')
    const [showComponent, setShowComponent] = useState(false);
    const [shrinkComponent, setShrinkComponent] = useState(false);
    const [showInterview, setShowInterview] = useState(false)
    const [showSummarize, setShowSummarize] = useState(false)
    const [showStrategy, setShowStrategy] = useState(false)
    const [final, setFinal] = useState(false)

    useEffect(() => {
        const timers = [
            setTimeout(() => setShowFirst(true), 500),
            setTimeout(() => setShowSecond(true), 1000),
            setTimeout(() => setShowThird(true), 1500),
            setTimeout(() => setFrameFour(true), 4000),
            setTimeout(() => setShowComponent(true), 5000),
            setTimeout(() => setShowInterview(true), 5000),
            setTimeout(() => setShowTitle(true), 6000),
            setTimeout(() => {
                setShowFirst(false);
                setShowSecond(false);
                setShowThird(false);
                setFrameFour(false);
            }, 7000),
        ]
        return () => timers.forEach(timer => clearTimeout(timer))
    }, [])

    function nextSequence (input) {
        if (input === 'summary') {
            setShowTitle(false)
            setShrinkComponent(true)
            setTitle('Strategy interviews are automatically summarized and consolidated.')
            setTimeout(() => setShowTitle(true), 2000)
            setTimeout(() => setShowInterview(false), 1500)
            setTimeout(() => setShrinkComponent(false), 1500)
            setTimeout(() => setShowSummarize(true), 1500)
        } else if (input === 'strategy') {
            setShowTitle(false)
            setShrinkComponent(true)
            setTitle('Key insights are examined then synthesized into a Strategy Whiteboard.')
            setTimeout(() => setShowSummarize(false), 1500)
            setTimeout(() => setShowStrategy(true), 1500)
            setTimeout(() => setShowTitle(true), 2000)
            setTimeout(() => setShrinkComponent(false), 1500)
        }
    }

    function endSequence () {
        setShrinkComponent(true)
        setShowTitle(false)
        setTimeout(() => setShowComponent(false), 1000)
        setTimeout(() => setFinal(true), 2000)
    }

    return (
        <div className="demo-main" >
            <div className={`demo-step ${showFirst && 'show'} ${frameFour && 'circle-and-move'} ${showTitle && 'hide'} ${final && 'appear'}`}>
                <div><img src="./Assets/step1.png" style={{width: '100px', marginRight: '25px'}}/></div>
                <div>AI facilitated Strategy Interviews</div>
            </div>
            <div className={`demo-step ${showSecond && 'show'} ${frameFour && 'hide'} ${final && 'appear'}`}>
                <div><img src="./Assets/step2.png" style={{width: '100px', marginRight: '25px'}}/></div>
                <div>Automatically Summarize Interviews</div>
            </div>
            <div className={`demo-step ${showThird && 'show'} ${frameFour && 'hide'} ${final && 'appear'}`}>
                <div><img src="./Assets/step3.png" style={{width: '100px', marginRight: '25px'}}/></div>
                <div>Consolidate Insights into Strategy</div>
            </div>
            <div className={`demo-header ${showComponent && 'show-demo-header'}`}>
                {showTitle && <div className="demo-header-text">{title}</div>}
            </div>
            <div className={`demo-component ${showComponent && 'show-demo-component'} ${shrinkComponent && 'shrink-demo-component'}`}>
                {showInterview && <InterviewDemo next={nextSequence}/>}
            </div>
            <div className={`demo-component ${showComponent && 'show-demo-component'} ${shrinkComponent && 'shrink-demo-component'}`}>
                {showSummarize && <SummaryDemo next={nextSequence}/>}
                {showStrategy && <StrategyDemo next={endSequence}/>}
            </div>
        </div>
    )
}
import React from 'react';
import { useState, useEffect} from 'react';
import DetailedBlock from './DetailedBlock.js'
import {API, graphqlOperation} from 'aws-amplify';
import * as queries from '../../../graphql/queries.js';
import * as mutations from '../../../graphql/mutations.js';
import ReactPlayer from 'react-player/lazy'



export default function WhiteboardNav(props) {
    const [view, setView] = useState('whiteboard')
    const [blockDetails, setBlockDetails] = useState({})
    const [isProcessing, setIsProcessing] = useState(false)
    const [userInfo, setUserInfo] = useState({})
    const [companyInfo, setCompanyInfo] = useState({})
    const [edit, setEdit] = useState(false)
    const [content, setContent] = useState()
    const [showVideo, setShowVideo] = useState(false)
    const [showGuide, setShowGuide] = useState(false)
    const [showMenu, setShowMenu] = useState(false)
    const [guideAlert, setGuideAlert] = useState(true)
    const [oscillate, setOscillate] = useState(false)

    useEffect (() => {
        if (props.userInfo.company) {
            console.log('User Info: ', props.userInfo)
            setUserInfo(props.userInfo)
            fetchCompanyInfo()
            toggleGuideAlert()
        }
    }, [props.userInfo, isProcessing, view])

    async function fetchCompanyInfo() {
        const {data} = await API.graphql(graphqlOperation(queries.getCompany, {id: props.userInfo.company.id}))
        setCompanyInfo(data.getCompany)
    }

    function handleBlockClick(name, info){
        setBlockDetails({name: name, info: info})
        setView('block')
    }

    async function regenerate(){
        let updateInfo = {id: userInfo.company.id}
        let message
        if (blockDetails.name === 'Culture') {
            updateInfo = {...updateInfo, culture: null}
            message = 'The Strategy Whiteboard Culture block is being regenerated.'
        } else if (blockDetails.name === 'External' || blockDetails.name === 'Internal') {
            updateInfo = {...updateInfo, external: null, internal: null}
            message = 'The Strategy Whiteboard External and Internal blocks are being regenerated.'
        } else if (blockDetails.name === 'Foundation') {
            updateInfo = {...updateInfo, foundation: null}
            message = 'The Strategy Whiteboard Foundation block is being regenerated.'
        } else if (blockDetails.name === 'Customer' || blockDetails.name === 'Product' || blockDetails.name === 'Operations' || blockDetails.name === 'People') {
            updateInfo = {...updateInfo, customer: null, product: null, operations: null, people: null}
            message = 'The Strategy Whiteboard Customer, Product, Operations, and People blocks are being regenerated.'
        }
        await API.graphql(graphqlOperation(mutations.updateCompany, {input : updateInfo}))
        setBlockDetails({...blockDetails, info: message})
        processBlock(blockDetails.name)
    }

    async function updateBlock(){
        let updateInfo = {id: userInfo.company.id}
        if (blockDetails.name === 'Culture') {
            updateInfo = {...updateInfo, culture: content}
        } else if (blockDetails.name === 'External') {
            updateInfo = {...updateInfo, external: content}
        } else if (blockDetails.name === 'Internal') {
            updateInfo = {...updateInfo, internal: content}
        } else if (blockDetails.name === 'Foundation') {
            updateInfo = {...updateInfo, foundation: content}
        } else if (blockDetails.name === 'Customer') {
            updateInfo = {...updateInfo, customer: content}
        } else if (blockDetails.name === 'Product') {
            updateInfo = {...updateInfo, product: content}
        } else if (blockDetails.name === 'Operations') {
            updateInfo = {...updateInfo, operations: content}
        } else if (blockDetails.name === 'People') {
            updateInfo = {...updateInfo, people: content}
        }
        await API.graphql(graphqlOperation(mutations.updateCompany, {input : updateInfo}))
        console.log('Block Updated:', updateInfo)
    }

    async function processBlock(name) {
        console.log('processing block:', name);
      
        const groups = await API.graphql(graphqlOperation(queries.listInterviewGroups, { filter: { companyInterviewGroupsId: { eq: props.userInfo.company.id } } }));
        const groupList = groups.data.listInterviewGroups.items;
        console.log('groupList: ', groupList)
        const findGroup = (groupList, name) => {
          const conditions = {
            "Culture": ["culture", "https://kebp5do3u7.execute-api.us-west-2.amazonaws.com/Prod/cultureblock"],
            "External": ["strategicanalysis", "https://kebp5do3u7.execute-api.us-west-2.amazonaws.com/Prod/buildstrategy"],
            "Internal": ["strategicanalysis", "https://kebp5do3u7.execute-api.us-west-2.amazonaws.com/Prod/buildstrategy"],
            "Customer": ["focus", "https://kebp5do3u7.execute-api.us-west-2.amazonaws.com/Prod/focuslensprocess"],
            "Product": ["focus", "https://kebp5do3u7.execute-api.us-west-2.amazonaws.com/Prod/focuslensprocess"],
            "Operations": ["focus", "https://kebp5do3u7.execute-api.us-west-2.amazonaws.com/Prod/focuslensprocess"],
            "People": ["focus", "https://kebp5do3u7.execute-api.us-west-2.amazonaws.com/Prod/focuslensprocess"],
            "Foundation": ["foundation", "https://kebp5do3u7.execute-api.us-west-2.amazonaws.com/Prod/foundationblock"]
          };
      
          let foundGroup = groupList.find(item => item.Template && item.Template.tempid === "comprehensive");
          let apiValue
      
          if (foundGroup) {
            const condition = conditions[name];
            if (condition) {
              apiValue = condition[1];
            }
          } else {
            const condition = conditions[name];
            if (condition) {
              foundGroup = groupList.find(item => item.Template && item.Template.tempid === condition[0]);
              apiValue = condition[1];
            }
          }
          console.log('foundGroup: ', foundGroup)
          return { foundGroup, apiValue };
        };
        const { foundGroup, apiValue } = findGroup(groupList, name);
      
        if (foundGroup && apiValue) {
          startProcessing(foundGroup.Groupid, apiValue, name);
          console.log('Processing group:', foundGroup.Groupname)
          console.log('Calling API:', apiValue)
        }
      }

    function processAllBlocks() {
        const blocks = ['Culture', 'External', 'Foundation', 'Customer']
        blocks.forEach(block => processBlock(block))
    }

    async function startProcessing(id, api, blockName){
        let info;
        switch (blockName) {
            case 'Culture':
                info = 'culture';
                break;
            case 'External':
                info = 'external';
                break;
            case 'Internal':
                info = 'internal';
                break;
            case 'Foundation':
                info = 'foundation';
                break;
            case 'Customer':
                info = 'customer';
                break;
            case 'Product':
                info = 'product';
                break;
            case 'Operations':
                info = 'operations';
                break;
            case 'People':
                info = 'people';
                break;
            default:
                info = null;
        }
        setIsProcessing(true)
        const groupid = id
        const {data} = await API.graphql(graphqlOperation(queries.listInterviews, {filter: {active: {eq: false}, process: {eq: true}, interviewGroupInterviewsGroupid: {eq: groupid}}}))
        const interviews = data.listInterviews.items
        const content = interviews.map(interview => interview.summary).join('\n')
        console.log('content: ', content)
        try {
            const response = await fetch(api, {
            method: "POST",
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify({
                companyid: userInfo.companyUserId,
                profile: userInfo.company.profile,
                content: content,
                }),
            });
            const apiResponse = await response.json();
            console.log('responses: ', apiResponse)
        } catch (error) { console.log('error: ', error) }
        polling(info)
    }

    async function polling (info) {
        const {data} = await API.graphql(graphqlOperation(queries.getCompany, {id: userInfo.company.id}))
        const blockData = data.getCompany[info]
        console.log('checking: ', blockData)
        if (blockData) {
            console.log('processing complete')
            setIsProcessing(false)
            setCompanyInfo(data.getCompany)
            setBlockDetails(prevDetails => ({...prevDetails, info: blockData}))
        } else {
            setTimeout(() => polling(info), 5000)
        }
    }

    function handleImplement() {
        setShowVideo(true)
    }
    const video = 
        <div style={{display: 'flex'}}>
            <ReactPlayer
                url="https://vimeo.com/683020352/957e87058d?share=copy"
                controls={true}
                height='30vh'
            />
        </div>

    function toggleGuideAlert(){
        setGuideAlert(true)
        setTimeout(() => {
            setGuideAlert(false)
        }, 3000)
        setOscillate(true)
        setTimeout(() => {
            setOscillate(false)
        }, 5000)
    }

    const instructionsText = [
        'Review all the employee interviews and confirm they are complete.',
        'Click on any Strategy Whiteboard block to access the detailed block view.',
        'Click the “Generate” button to process the relevant interview information for the respective block(s).',
        'This may take a couple minutes to populate depending on the number and length of interviews.',
        'Click "Next Steps" to see how to implement this analysis using the Strategy Whiteboard application.'
    ];
    
    const guideInstructions = (
        <div className='standard-guide-instructions-box'>
            <div className='standard-guide-instructions-title'>Once all employee interviews are completed, you can build your Strategy Whiteboard from the insights learned from those interviews.</div>
            <div className='standard-divider long' style={{margin: 'auto', marginTop: '15px', marginBottom: '15px'}}></div>
            <div className='standard-guide-instructions-header'>Generate Each Strategy Whiteboard Block</div>
            {instructionsText.map((text, index) => (
                <div key={index} className='standard-guide-instructions-lineitem'>
                    <div className='standard-guide-instructions-bullet'></div>
                    <div className='standard-guide-instructions-text'>{text}</div>
                </div>
            ))}
        </div>
    )

    const [showMeatballMenu, setShowMeatballMenu] = useState(true)
    const meatballMenu = (
        <div>
            <div className="meatballMenu" onClick={()=>setShowMeatballMenu(!showMeatballMenu)}></div>
            {showMeatballMenu && <div className="main-menu meatballLeft">
                <button className="button small" onClick={()=>setView('whiteboard')}>Back</button>
                {!edit && <button className="button small" onClick={()=>setEdit(true)} >Edit</button>}
                {edit && <button className="button small" onClick={()=>{setEdit(false); updateBlock()}} >Save</button>}
                {blockDetails.info ? <button className="button small" onClick={regenerate}>Regenerate</button> : null}
            </div>}
        </div>
    )

    const pageHeader = (
        <div className="standard-floating-header">
            <div className="standard-floating-menu-container left">{view === 'block' && meatballMenu}</div>
            <div className="standard-floating-title"></div>
            <div className="standard-floating-menu-container right"></div>
        </div>
    )

    return (
        <div className="standard-main">
            {showGuide && <div className='popup-mask standard' >
                <div className='popup' style={{justifyContent: 'space-between', width: '80%'}}>
                    {guideInstructions}
                    <div>
                        <button className="button small" onClick={() => setShowGuide(false)}>Close</button>
                    </div>
                </div>
            </div>
            }
            {pageHeader}
            {view === 'block' && <DetailedBlock details={blockDetails} process={processAllBlocks} isProcessing={isProcessing} userInfo={userInfo} edit={edit} update={(data)=>setContent(data)}/>}
            {view === 'whiteboard' && <div className="standard-box" style={{height: '85vh'}}>
                <div className='standard-whiteboard-magnet tl'></div>
                <div className='standard-whiteboard-magnet tr'></div>
                <div className='standard-whiteboard-magnet br'></div>
                <div className='standard-whiteboard-magnet bl'></div>
                
                <div className="whiteboard-header" style={{fontSize: '40px', fontFamily: 'Permanent marker', fontWeight: '500', height: '6%'}}>Strategic Analysis</div>
                <div className="whiteboard-section half">
                    <div className="whiteboard-block" onClick={()=>handleBlockClick('Culture', companyInfo.culture)}>
                        <div className="whiteboard-block-header blue">Culture</div>
                        <div className="whiteboard-block-content">
                            {companyInfo.culture !== null ? 
                                <div>{companyInfo.culture}</div> : 
                                <img src="./Assets/Icons-SWB-Culture-Icon-Blue.png" 
                                    style={{width: "60%"}}>
                                </img>}
                        </div>
                    </div>
                    <div className="whiteboard-block" onClick={()=>handleBlockClick('External', companyInfo.external)}>
                        <div className="whiteboard-block-header red">External</div>
                        <div className="whiteboard-block-content">
                            {companyInfo.external !== null ? 
                                <div>{companyInfo.external}</div> : 
                                <img src="./Assets/Icons-SWB-External-Icon-Blue.png" 
                                    style={{width: "50%", marginTop: '6%'}}>
                                </img>}
                        </div>
                    </div>
                    <div className="whiteboard-block" onClick={()=>handleBlockClick('Internal', companyInfo.internal)}>
                        <div className="whiteboard-block-header red">Internal</div>
                        <div className="whiteboard-block-content">
                            {companyInfo.internal !== null ? 
                                <div>{companyInfo.internal}</div> : 
                                <img src="./Assets/Icons-SWB-Internal-Icon-Blue.png" 
                                    style={{width: "50%", marginTop: '6%'}}>
                                </img>}
                        </div>
                    </div>
                    <div className="whiteboard-block" onClick={()=>handleBlockClick('Foundation', companyInfo.foundation)}>
                        <div className="whiteboard-block-header blue">Foundation</div>
                        <div className="whiteboard-block-content">
                            {companyInfo.foundation !== null ? 
                                <div>{companyInfo.foundation}</div> : 
                                <img src="./Assets/Icons-SWB-Foundation-Icon-Blue.png" 
                                    style={{width: "60%", marginTop: '6%'}}>
                                </img>}
                        </div>
                    </div>
                </div>
                <div className="whiteboard-header" style={{fontSize: '40px', fontFamily: 'Permanent marker',  height: '6%', fontWeight: '500'}}>Strategic Focus and Outcomes</div>
                <div className="whiteboard-section half">
                    <div className="whiteboard-block" onClick={()=>handleBlockClick('Customer', companyInfo.customer)}>
                        <div className="whiteboard-block-header">Customer</div>
                        <div className="whiteboard-block-content">
                            {companyInfo.customer !== null ? 
                                <div>{companyInfo.customer}</div> : 
                                <img src="./Assets/Icons-SWB-Customer-Icon-Blue.png" 
                                    style={{width: "50%"}}>
                                </img>}
                        </div>
                    </div>
                    <div className="whiteboard-block" onClick={()=>handleBlockClick('Product', companyInfo.product)}>
                        <div className="whiteboard-block-header">Product</div>
                        <div className="whiteboard-block-content">
                            {companyInfo.product !== null ? 
                                <div>{companyInfo.product}</div> : 
                                <img src="./Assets/Icons-SWB-Product-Icon-Blue.png" 
                                    style={{width: "50%", marginTop: '4%'}}>
                                </img>}
                        </div>
                    </div>
                    <div className="whiteboard-block" onClick={()=>handleBlockClick('Operations', companyInfo.operations)}>
                        <div className="whiteboard-block-header">Operations</div>
                        <div className="whiteboard-block-content">
                            {companyInfo.operations !== null ? 
                                <div>{companyInfo.operations}</div> : 
                                <img src="./Assets/Icons-SWB-Operations-Icon-Blue.png" 
                                    style={{width: "60%"}}>
                                </img>}
                        </div>
                    </div>
                    <div className="whiteboard-block" onClick={()=>handleBlockClick('People', companyInfo.people)}>
                        <div className="whiteboard-block-header">People</div>
                        <div className="whiteboard-block-content">
                            {companyInfo.people !== null ? 
                                <div>{companyInfo.people}</div> : 
                                <img src="./Assets/Icons-SWB-People-Icon-Blue.png" 
                                    style={{width: "50%", marginTop: '5%'}}>
                                </img>}
                        </div>
                    </div>
                </div>
            </div>}
        </div>
    )
}
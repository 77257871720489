/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getInterviewGroup = /* GraphQL */ `
  query GetInterviewGroup($Groupid: ID!) {
    getInterviewGroup(Groupid: $Groupid) {
      Groupid
      Groupname
      GPTmodel
      MaxTokens
      Temperature
      Company {
        id
        name
        access
        user {
          nextToken
        }
        profile
        industry
        InterviewGroups {
          nextToken
        }
        dataFiles {
          nextToken
        }
        examinations {
          nextToken
        }
        swb {
          nextToken
        }
        rootsGroups {
          nextToken
        }
        meetings {
          nextToken
        }
        culture
        external
        internal
        foundation
        customer
        product
        operations
        people
        stepProfile
        stepInterviews
        stepData
        stepAnalysis
        stepFocus
        createdAt
        updatedAt
      }
      NameSpace
      Template {
        tempid
        name
        GPTmodel
        MaxTokens
        Temperature
        profile
        goal
        systemcontext
        instruction
        firstMessage
        instructions {
          nextToken
        }
        createdAt
        updatedAt
      }
      Interviews {
        items {
          intid
          summary
          conclusions
          active
          process
          status
          createdAt
          updatedAt
          interviewGroupInterviewsGroupid
          userInterviewsUserid
        }
        nextToken
      }
      createdAt
      updatedAt
      companyInterviewGroupsId
      interviewGroupTemplateTempid
    }
  }
`;
export const listInterviewGroups = /* GraphQL */ `
  query ListInterviewGroups(
    $Groupid: ID
    $filter: ModelInterviewGroupFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listInterviewGroups(
      Groupid: $Groupid
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        Groupid
        Groupname
        GPTmodel
        MaxTokens
        Temperature
        Company {
          id
          name
          access
          profile
          industry
          culture
          external
          internal
          foundation
          customer
          product
          operations
          people
          stepProfile
          stepInterviews
          stepData
          stepAnalysis
          stepFocus
          createdAt
          updatedAt
        }
        NameSpace
        Template {
          tempid
          name
          GPTmodel
          MaxTokens
          Temperature
          profile
          goal
          systemcontext
          instruction
          firstMessage
          createdAt
          updatedAt
        }
        Interviews {
          nextToken
        }
        createdAt
        updatedAt
        companyInterviewGroupsId
        interviewGroupTemplateTempid
      }
      nextToken
    }
  }
`;
export const getTemplate = /* GraphQL */ `
  query GetTemplate($tempid: ID!) {
    getTemplate(tempid: $tempid) {
      tempid
      name
      GPTmodel
      MaxTokens
      Temperature
      profile
      goal
      systemcontext
      instruction
      firstMessage
      instructions {
        items {
          insid
          content
          createdAt
          updatedAt
          templateInstructionsTempid
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listTemplates = /* GraphQL */ `
  query ListTemplates(
    $tempid: ID
    $filter: ModelTemplateFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listTemplates(
      tempid: $tempid
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        tempid
        name
        GPTmodel
        MaxTokens
        Temperature
        profile
        goal
        systemcontext
        instruction
        firstMessage
        instructions {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getInstructions = /* GraphQL */ `
  query GetInstructions($insid: ID!) {
    getInstructions(insid: $insid) {
      insid
      template {
        tempid
        name
        GPTmodel
        MaxTokens
        Temperature
        profile
        goal
        systemcontext
        instruction
        firstMessage
        instructions {
          nextToken
        }
        createdAt
        updatedAt
      }
      content
      createdAt
      updatedAt
      templateInstructionsTempid
    }
  }
`;
export const listInstructions = /* GraphQL */ `
  query ListInstructions(
    $insid: ID
    $filter: ModelInstructionsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listInstructions(
      insid: $insid
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        insid
        template {
          tempid
          name
          GPTmodel
          MaxTokens
          Temperature
          profile
          goal
          systemcontext
          instruction
          firstMessage
          createdAt
          updatedAt
        }
        content
        createdAt
        updatedAt
        templateInstructionsTempid
      }
      nextToken
    }
  }
`;
export const getInterview = /* GraphQL */ `
  query GetInterview($intid: ID!) {
    getInterview(intid: $intid) {
      intid
      user {
        userid
        username
        emailid
        permissions
        accessStrat360
        accessSWB
        accessRG
        role
        strategyRole
        jobFunction
        company {
          id
          name
          access
          profile
          industry
          culture
          external
          internal
          foundation
          customer
          product
          operations
          people
          stepProfile
          stepInterviews
          stepData
          stepAnalysis
          stepFocus
          createdAt
          updatedAt
        }
        interviews {
          nextToken
        }
        rgmembership {
          nextToken
        }
        createdAt
        updatedAt
        companyUserId
      }
      group {
        Groupid
        Groupname
        GPTmodel
        MaxTokens
        Temperature
        Company {
          id
          name
          access
          profile
          industry
          culture
          external
          internal
          foundation
          customer
          product
          operations
          people
          stepProfile
          stepInterviews
          stepData
          stepAnalysis
          stepFocus
          createdAt
          updatedAt
        }
        NameSpace
        Template {
          tempid
          name
          GPTmodel
          MaxTokens
          Temperature
          profile
          goal
          systemcontext
          instruction
          firstMessage
          createdAt
          updatedAt
        }
        Interviews {
          nextToken
        }
        createdAt
        updatedAt
        companyInterviewGroupsId
        interviewGroupTemplateTempid
      }
      summary
      conclusions
      conversation {
        items {
          logid
          chatsummary
          createdAt
          updatedAt
          interviewConversationIntid
        }
        nextToken
      }
      active
      process
      status
      createdAt
      updatedAt
      interviewGroupInterviewsGroupid
      userInterviewsUserid
    }
  }
`;
export const listInterviews = /* GraphQL */ `
  query ListInterviews(
    $intid: ID
    $filter: ModelInterviewFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listInterviews(
      intid: $intid
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        intid
        user {
          userid
          username
          emailid
          permissions
          accessStrat360
          accessSWB
          accessRG
          role
          strategyRole
          jobFunction
          createdAt
          updatedAt
          companyUserId
        }
        group {
          Groupid
          Groupname
          GPTmodel
          MaxTokens
          Temperature
          NameSpace
          createdAt
          updatedAt
          companyInterviewGroupsId
          interviewGroupTemplateTempid
        }
        summary
        conclusions
        conversation {
          nextToken
        }
        active
        process
        status
        createdAt
        updatedAt
        interviewGroupInterviewsGroupid
        userInterviewsUserid
      }
      nextToken
    }
  }
`;
export const getChatLog = /* GraphQL */ `
  query GetChatLog($logid: ID!) {
    getChatLog(logid: $logid) {
      logid
      interview {
        intid
        user {
          userid
          username
          emailid
          permissions
          accessStrat360
          accessSWB
          accessRG
          role
          strategyRole
          jobFunction
          createdAt
          updatedAt
          companyUserId
        }
        group {
          Groupid
          Groupname
          GPTmodel
          MaxTokens
          Temperature
          NameSpace
          createdAt
          updatedAt
          companyInterviewGroupsId
          interviewGroupTemplateTempid
        }
        summary
        conclusions
        conversation {
          nextToken
        }
        active
        process
        status
        createdAt
        updatedAt
        interviewGroupInterviewsGroupid
        userInterviewsUserid
      }
      chatsummary
      messages {
        items {
          msgid
          content
          user
          createdAt
          updatedAt
          chatLogMessagesLogid
        }
        nextToken
      }
      createdAt
      updatedAt
      interviewConversationIntid
    }
  }
`;
export const listChatLogs = /* GraphQL */ `
  query ListChatLogs(
    $logid: ID
    $filter: ModelChatLogFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listChatLogs(
      logid: $logid
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        logid
        interview {
          intid
          summary
          conclusions
          active
          process
          status
          createdAt
          updatedAt
          interviewGroupInterviewsGroupid
          userInterviewsUserid
        }
        chatsummary
        messages {
          nextToken
        }
        createdAt
        updatedAt
        interviewConversationIntid
      }
      nextToken
    }
  }
`;
export const getChatmessage = /* GraphQL */ `
  query GetChatmessage($msgid: ID!) {
    getChatmessage(msgid: $msgid) {
      msgid
      chatLog {
        logid
        interview {
          intid
          summary
          conclusions
          active
          process
          status
          createdAt
          updatedAt
          interviewGroupInterviewsGroupid
          userInterviewsUserid
        }
        chatsummary
        messages {
          nextToken
        }
        createdAt
        updatedAt
        interviewConversationIntid
      }
      content
      user
      createdAt
      updatedAt
      chatLogMessagesLogid
    }
  }
`;
export const listChatmessages = /* GraphQL */ `
  query ListChatmessages(
    $msgid: ID
    $filter: ModelChatmessageFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listChatmessages(
      msgid: $msgid
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        msgid
        chatLog {
          logid
          chatsummary
          createdAt
          updatedAt
          interviewConversationIntid
        }
        content
        user
        createdAt
        updatedAt
        chatLogMessagesLogid
      }
      nextToken
    }
  }
`;
export const getDataFile = /* GraphQL */ `
  query GetDataFile($id: ID!) {
    getDataFile(id: $id) {
      id
      image
      company {
        id
        name
        access
        user {
          nextToken
        }
        profile
        industry
        InterviewGroups {
          nextToken
        }
        dataFiles {
          nextToken
        }
        examinations {
          nextToken
        }
        swb {
          nextToken
        }
        rootsGroups {
          nextToken
        }
        meetings {
          nextToken
        }
        culture
        external
        internal
        foundation
        customer
        product
        operations
        people
        stepProfile
        stepInterviews
        stepData
        stepAnalysis
        stepFocus
        createdAt
        updatedAt
      }
      description
      reference
      summary
      themes
      insights
      relevance
      conclusions
      content
      embeddings {
        items {
          vectorid
          index
          namespace
          text
          id
          createdAt
          updatedAt
          dataFileEmbeddingsId
        }
        nextToken
      }
      createdAt
      updatedAt
      companyDataFilesId
    }
  }
`;
export const listDataFiles = /* GraphQL */ `
  query ListDataFiles(
    $id: ID
    $filter: ModelDataFileFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDataFiles(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        image
        company {
          id
          name
          access
          profile
          industry
          culture
          external
          internal
          foundation
          customer
          product
          operations
          people
          stepProfile
          stepInterviews
          stepData
          stepAnalysis
          stepFocus
          createdAt
          updatedAt
        }
        description
        reference
        summary
        themes
        insights
        relevance
        conclusions
        content
        embeddings {
          nextToken
        }
        createdAt
        updatedAt
        companyDataFilesId
      }
      nextToken
    }
  }
`;
export const getEmbedding = /* GraphQL */ `
  query GetEmbedding($id: ID!) {
    getEmbedding(id: $id) {
      vectorid
      index
      namespace
      text
      id
      createdAt
      updatedAt
      dataFileEmbeddingsId
    }
  }
`;
export const listEmbeddings = /* GraphQL */ `
  query ListEmbeddings(
    $filter: ModelEmbeddingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEmbeddings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        vectorid
        index
        namespace
        text
        id
        createdAt
        updatedAt
        dataFileEmbeddingsId
      }
      nextToken
    }
  }
`;
export const getCompany = /* GraphQL */ `
  query GetCompany($id: ID!) {
    getCompany(id: $id) {
      id
      name
      access
      user {
        items {
          userid
          username
          emailid
          permissions
          accessStrat360
          accessSWB
          accessRG
          role
          strategyRole
          jobFunction
          createdAt
          updatedAt
          companyUserId
        }
        nextToken
      }
      profile
      industry
      InterviewGroups {
        items {
          Groupid
          Groupname
          GPTmodel
          MaxTokens
          Temperature
          NameSpace
          createdAt
          updatedAt
          companyInterviewGroupsId
          interviewGroupTemplateTempid
        }
        nextToken
      }
      dataFiles {
        items {
          id
          image
          description
          reference
          summary
          themes
          insights
          relevance
          conclusions
          content
          createdAt
          updatedAt
          companyDataFilesId
        }
        nextToken
      }
      examinations {
        items {
          id
          name
          information
          result
          createdAt
          updatedAt
          companyExaminationsId
        }
        nextToken
      }
      swb {
        items {
          id
          name
          createdAt
          updatedAt
          companySwbId
        }
        nextToken
      }
      rootsGroups {
        items {
          id
          name
          createdAt
          updatedAt
          companyRootsGroupsId
          rootsGroupSwbId
        }
        nextToken
      }
      meetings {
        items {
          id
          name
          type
          meetingDate
          notes
          createdAt
          updatedAt
          companyMeetingsId
        }
        nextToken
      }
      culture
      external
      internal
      foundation
      customer
      product
      operations
      people
      stepProfile
      stepInterviews
      stepData
      stepAnalysis
      stepFocus
      createdAt
      updatedAt
    }
  }
`;
export const listCompanies = /* GraphQL */ `
  query ListCompanies(
    $id: ID
    $filter: ModelCompanyFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCompanies(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        access
        user {
          nextToken
        }
        profile
        industry
        InterviewGroups {
          nextToken
        }
        dataFiles {
          nextToken
        }
        examinations {
          nextToken
        }
        swb {
          nextToken
        }
        rootsGroups {
          nextToken
        }
        meetings {
          nextToken
        }
        culture
        external
        internal
        foundation
        customer
        product
        operations
        people
        stepProfile
        stepInterviews
        stepData
        stepAnalysis
        stepFocus
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($userid: ID!) {
    getUser(userid: $userid) {
      userid
      username
      emailid
      permissions
      accessStrat360
      accessSWB
      accessRG
      role
      strategyRole
      jobFunction
      company {
        id
        name
        access
        user {
          nextToken
        }
        profile
        industry
        InterviewGroups {
          nextToken
        }
        dataFiles {
          nextToken
        }
        examinations {
          nextToken
        }
        swb {
          nextToken
        }
        rootsGroups {
          nextToken
        }
        meetings {
          nextToken
        }
        culture
        external
        internal
        foundation
        customer
        product
        operations
        people
        stepProfile
        stepInterviews
        stepData
        stepAnalysis
        stepFocus
        createdAt
        updatedAt
      }
      interviews {
        items {
          intid
          summary
          conclusions
          active
          process
          status
          createdAt
          updatedAt
          interviewGroupInterviewsGroupid
          userInterviewsUserid
        }
        nextToken
      }
      rgmembership {
        items {
          id
          rgId
          userId
          name
          createdAt
          updatedAt
          userRgmembershipUserid
          rootsGroupMembersId
        }
        nextToken
      }
      createdAt
      updatedAt
      companyUserId
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $userid: ID
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listUsers(
      userid: $userid
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        userid
        username
        emailid
        permissions
        accessStrat360
        accessSWB
        accessRG
        role
        strategyRole
        jobFunction
        company {
          id
          name
          access
          profile
          industry
          culture
          external
          internal
          foundation
          customer
          product
          operations
          people
          stepProfile
          stepInterviews
          stepData
          stepAnalysis
          stepFocus
          createdAt
          updatedAt
        }
        interviews {
          nextToken
        }
        rgmembership {
          nextToken
        }
        createdAt
        updatedAt
        companyUserId
      }
      nextToken
    }
  }
`;
export const getExamination = /* GraphQL */ `
  query GetExamination($id: ID!) {
    getExamination(id: $id) {
      id
      company {
        id
        name
        access
        user {
          nextToken
        }
        profile
        industry
        InterviewGroups {
          nextToken
        }
        dataFiles {
          nextToken
        }
        examinations {
          nextToken
        }
        swb {
          nextToken
        }
        rootsGroups {
          nextToken
        }
        meetings {
          nextToken
        }
        culture
        external
        internal
        foundation
        customer
        product
        operations
        people
        stepProfile
        stepInterviews
        stepData
        stepAnalysis
        stepFocus
        createdAt
        updatedAt
      }
      name
      information
      result
      createdAt
      updatedAt
      companyExaminationsId
    }
  }
`;
export const listExaminations = /* GraphQL */ `
  query ListExaminations(
    $id: ID
    $filter: ModelExaminationFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listExaminations(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        company {
          id
          name
          access
          profile
          industry
          culture
          external
          internal
          foundation
          customer
          product
          operations
          people
          stepProfile
          stepInterviews
          stepData
          stepAnalysis
          stepFocus
          createdAt
          updatedAt
        }
        name
        information
        result
        createdAt
        updatedAt
        companyExaminationsId
      }
      nextToken
    }
  }
`;
export const getSwb = /* GraphQL */ `
  query GetSwb($id: ID!) {
    getSwb(id: $id) {
      id
      name
      company {
        id
        name
        access
        user {
          nextToken
        }
        profile
        industry
        InterviewGroups {
          nextToken
        }
        dataFiles {
          nextToken
        }
        examinations {
          nextToken
        }
        swb {
          nextToken
        }
        rootsGroups {
          nextToken
        }
        meetings {
          nextToken
        }
        culture
        external
        internal
        foundation
        customer
        product
        operations
        people
        stepProfile
        stepInterviews
        stepData
        stepAnalysis
        stepFocus
        createdAt
        updatedAt
      }
      blocks {
        items {
          id
          section
          order
          name
          color
          content
          summary
          contentinstruction
          summaryinstruction
          createdAt
          updatedAt
          swbBlocksId
        }
        nextToken
      }
      blockItems {
        items {
          id
          name
          type
          status
          description
          order
          createdAt
          updatedAt
          swbBlockItemsId
          blockBlockItemsId
          blockElementItemsId
        }
        nextToken
      }
      createdAt
      updatedAt
      companySwbId
    }
  }
`;
export const listSwbs = /* GraphQL */ `
  query ListSwbs(
    $id: ID
    $filter: ModelSwbFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listSwbs(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        company {
          id
          name
          access
          profile
          industry
          culture
          external
          internal
          foundation
          customer
          product
          operations
          people
          stepProfile
          stepInterviews
          stepData
          stepAnalysis
          stepFocus
          createdAt
          updatedAt
        }
        blocks {
          nextToken
        }
        blockItems {
          nextToken
        }
        createdAt
        updatedAt
        companySwbId
      }
      nextToken
    }
  }
`;
export const getBlock = /* GraphQL */ `
  query GetBlock($id: ID!) {
    getBlock(id: $id) {
      id
      swb {
        id
        name
        company {
          id
          name
          access
          profile
          industry
          culture
          external
          internal
          foundation
          customer
          product
          operations
          people
          stepProfile
          stepInterviews
          stepData
          stepAnalysis
          stepFocus
          createdAt
          updatedAt
        }
        blocks {
          nextToken
        }
        blockItems {
          nextToken
        }
        createdAt
        updatedAt
        companySwbId
      }
      blockElements {
        items {
          id
          name
          order
          type
          createdAt
          updatedAt
          blockBlockElementsId
        }
        nextToken
      }
      blockItems {
        items {
          id
          name
          type
          status
          description
          order
          createdAt
          updatedAt
          swbBlockItemsId
          blockBlockItemsId
          blockElementItemsId
        }
        nextToken
      }
      section
      order
      name
      color
      content
      summary
      contentinstruction
      summaryinstruction
      createdAt
      updatedAt
      swbBlocksId
    }
  }
`;
export const listBlocks = /* GraphQL */ `
  query ListBlocks(
    $id: ID
    $filter: ModelBlockFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listBlocks(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        swb {
          id
          name
          createdAt
          updatedAt
          companySwbId
        }
        blockElements {
          nextToken
        }
        blockItems {
          nextToken
        }
        section
        order
        name
        color
        content
        summary
        contentinstruction
        summaryinstruction
        createdAt
        updatedAt
        swbBlocksId
      }
      nextToken
    }
  }
`;
export const getBlockElement = /* GraphQL */ `
  query GetBlockElement($id: ID!) {
    getBlockElement(id: $id) {
      id
      block {
        id
        swb {
          id
          name
          createdAt
          updatedAt
          companySwbId
        }
        blockElements {
          nextToken
        }
        blockItems {
          nextToken
        }
        section
        order
        name
        color
        content
        summary
        contentinstruction
        summaryinstruction
        createdAt
        updatedAt
        swbBlocksId
      }
      name
      order
      type
      items {
        items {
          id
          name
          type
          status
          description
          order
          createdAt
          updatedAt
          swbBlockItemsId
          blockBlockItemsId
          blockElementItemsId
        }
        nextToken
      }
      createdAt
      updatedAt
      blockBlockElementsId
    }
  }
`;
export const listBlockElements = /* GraphQL */ `
  query ListBlockElements(
    $id: ID
    $filter: ModelBlockElementFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listBlockElements(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        block {
          id
          section
          order
          name
          color
          content
          summary
          contentinstruction
          summaryinstruction
          createdAt
          updatedAt
          swbBlocksId
        }
        name
        order
        type
        items {
          nextToken
        }
        createdAt
        updatedAt
        blockBlockElementsId
      }
      nextToken
    }
  }
`;
export const getBlockItem = /* GraphQL */ `
  query GetBlockItem($id: ID!) {
    getBlockItem(id: $id) {
      id
      blockElement {
        id
        block {
          id
          section
          order
          name
          color
          content
          summary
          contentinstruction
          summaryinstruction
          createdAt
          updatedAt
          swbBlocksId
        }
        name
        order
        type
        items {
          nextToken
        }
        createdAt
        updatedAt
        blockBlockElementsId
      }
      block {
        id
        swb {
          id
          name
          createdAt
          updatedAt
          companySwbId
        }
        blockElements {
          nextToken
        }
        blockItems {
          nextToken
        }
        section
        order
        name
        color
        content
        summary
        contentinstruction
        summaryinstruction
        createdAt
        updatedAt
        swbBlocksId
      }
      swb {
        id
        name
        company {
          id
          name
          access
          profile
          industry
          culture
          external
          internal
          foundation
          customer
          product
          operations
          people
          stepProfile
          stepInterviews
          stepData
          stepAnalysis
          stepFocus
          createdAt
          updatedAt
        }
        blocks {
          nextToken
        }
        blockItems {
          nextToken
        }
        createdAt
        updatedAt
        companySwbId
      }
      name
      type
      status
      description
      order
      createdAt
      updatedAt
      swbBlockItemsId
      blockBlockItemsId
      blockElementItemsId
    }
  }
`;
export const listBlockItems = /* GraphQL */ `
  query ListBlockItems(
    $id: ID
    $filter: ModelBlockItemFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listBlockItems(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        blockElement {
          id
          name
          order
          type
          createdAt
          updatedAt
          blockBlockElementsId
        }
        block {
          id
          section
          order
          name
          color
          content
          summary
          contentinstruction
          summaryinstruction
          createdAt
          updatedAt
          swbBlocksId
        }
        swb {
          id
          name
          createdAt
          updatedAt
          companySwbId
        }
        name
        type
        status
        description
        order
        createdAt
        updatedAt
        swbBlockItemsId
        blockBlockItemsId
        blockElementItemsId
      }
      nextToken
    }
  }
`;
export const getRootsGroup = /* GraphQL */ `
  query GetRootsGroup($id: ID!) {
    getRootsGroup(id: $id) {
      id
      name
      actions {
        items {
          id
          name
          status
          createdAt
          updatedAt
          rootsGroupActionsId
          actionOwnerUserid
          actionCoordinatorUserid
        }
        nextToken
      }
      outcomes {
        items {
          id
          name
          blockItemId
          dueDate
          metric
          metricType
          numericalTarget
          numericalProgress
          qaulityTarget
          qualityProgress
          createdAt
          updatedAt
          rootsGroupOutcomesId
        }
        nextToken
      }
      swb {
        id
        name
        company {
          id
          name
          access
          profile
          industry
          culture
          external
          internal
          foundation
          customer
          product
          operations
          people
          stepProfile
          stepInterviews
          stepData
          stepAnalysis
          stepFocus
          createdAt
          updatedAt
        }
        blocks {
          nextToken
        }
        blockItems {
          nextToken
        }
        createdAt
        updatedAt
        companySwbId
      }
      company {
        id
        name
        access
        user {
          nextToken
        }
        profile
        industry
        InterviewGroups {
          nextToken
        }
        dataFiles {
          nextToken
        }
        examinations {
          nextToken
        }
        swb {
          nextToken
        }
        rootsGroups {
          nextToken
        }
        meetings {
          nextToken
        }
        culture
        external
        internal
        foundation
        customer
        product
        operations
        people
        stepProfile
        stepInterviews
        stepData
        stepAnalysis
        stepFocus
        createdAt
        updatedAt
      }
      members {
        items {
          id
          rgId
          userId
          name
          createdAt
          updatedAt
          userRgmembershipUserid
          rootsGroupMembersId
        }
        nextToken
      }
      createdAt
      updatedAt
      companyRootsGroupsId
      rootsGroupSwbId
    }
  }
`;
export const listRootsGroups = /* GraphQL */ `
  query ListRootsGroups(
    $id: ID
    $filter: ModelRootsGroupFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listRootsGroups(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        actions {
          nextToken
        }
        outcomes {
          nextToken
        }
        swb {
          id
          name
          createdAt
          updatedAt
          companySwbId
        }
        company {
          id
          name
          access
          profile
          industry
          culture
          external
          internal
          foundation
          customer
          product
          operations
          people
          stepProfile
          stepInterviews
          stepData
          stepAnalysis
          stepFocus
          createdAt
          updatedAt
        }
        members {
          nextToken
        }
        createdAt
        updatedAt
        companyRootsGroupsId
        rootsGroupSwbId
      }
      nextToken
    }
  }
`;
export const getRgmember = /* GraphQL */ `
  query GetRgmember($id: ID!) {
    getRgmember(id: $id) {
      id
      rgId
      userId
      group {
        id
        name
        actions {
          nextToken
        }
        outcomes {
          nextToken
        }
        swb {
          id
          name
          createdAt
          updatedAt
          companySwbId
        }
        company {
          id
          name
          access
          profile
          industry
          culture
          external
          internal
          foundation
          customer
          product
          operations
          people
          stepProfile
          stepInterviews
          stepData
          stepAnalysis
          stepFocus
          createdAt
          updatedAt
        }
        members {
          nextToken
        }
        createdAt
        updatedAt
        companyRootsGroupsId
        rootsGroupSwbId
      }
      user {
        userid
        username
        emailid
        permissions
        accessStrat360
        accessSWB
        accessRG
        role
        strategyRole
        jobFunction
        company {
          id
          name
          access
          profile
          industry
          culture
          external
          internal
          foundation
          customer
          product
          operations
          people
          stepProfile
          stepInterviews
          stepData
          stepAnalysis
          stepFocus
          createdAt
          updatedAt
        }
        interviews {
          nextToken
        }
        rgmembership {
          nextToken
        }
        createdAt
        updatedAt
        companyUserId
      }
      name
      createdAt
      updatedAt
      userRgmembershipUserid
      rootsGroupMembersId
    }
  }
`;
export const listRgmembers = /* GraphQL */ `
  query ListRgmembers(
    $id: ID
    $filter: ModelRgmemberFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listRgmembers(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        rgId
        userId
        group {
          id
          name
          createdAt
          updatedAt
          companyRootsGroupsId
          rootsGroupSwbId
        }
        user {
          userid
          username
          emailid
          permissions
          accessStrat360
          accessSWB
          accessRG
          role
          strategyRole
          jobFunction
          createdAt
          updatedAt
          companyUserId
        }
        name
        createdAt
        updatedAt
        userRgmembershipUserid
        rootsGroupMembersId
      }
      nextToken
    }
  }
`;
export const getOutcome = /* GraphQL */ `
  query GetOutcome($id: ID!) {
    getOutcome(id: $id) {
      id
      name
      rootsGroup {
        id
        name
        actions {
          nextToken
        }
        outcomes {
          nextToken
        }
        swb {
          id
          name
          createdAt
          updatedAt
          companySwbId
        }
        company {
          id
          name
          access
          profile
          industry
          culture
          external
          internal
          foundation
          customer
          product
          operations
          people
          stepProfile
          stepInterviews
          stepData
          stepAnalysis
          stepFocus
          createdAt
          updatedAt
        }
        members {
          nextToken
        }
        createdAt
        updatedAt
        companyRootsGroupsId
        rootsGroupSwbId
      }
      blockItemId
      dueDate
      metric
      metricType
      numericalTarget
      numericalProgress
      qaulityTarget
      qualityProgress
      createdAt
      updatedAt
      rootsGroupOutcomesId
    }
  }
`;
export const listOutcomes = /* GraphQL */ `
  query ListOutcomes(
    $id: ID
    $filter: ModelOutcomeFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listOutcomes(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        rootsGroup {
          id
          name
          createdAt
          updatedAt
          companyRootsGroupsId
          rootsGroupSwbId
        }
        blockItemId
        dueDate
        metric
        metricType
        numericalTarget
        numericalProgress
        qaulityTarget
        qualityProgress
        createdAt
        updatedAt
        rootsGroupOutcomesId
      }
      nextToken
    }
  }
`;
export const getAction = /* GraphQL */ `
  query GetAction($id: ID!) {
    getAction(id: $id) {
      id
      name
      rootsGroup {
        id
        name
        actions {
          nextToken
        }
        outcomes {
          nextToken
        }
        swb {
          id
          name
          createdAt
          updatedAt
          companySwbId
        }
        company {
          id
          name
          access
          profile
          industry
          culture
          external
          internal
          foundation
          customer
          product
          operations
          people
          stepProfile
          stepInterviews
          stepData
          stepAnalysis
          stepFocus
          createdAt
          updatedAt
        }
        members {
          nextToken
        }
        createdAt
        updatedAt
        companyRootsGroupsId
        rootsGroupSwbId
      }
      owner {
        userid
        username
        emailid
        permissions
        accessStrat360
        accessSWB
        accessRG
        role
        strategyRole
        jobFunction
        company {
          id
          name
          access
          profile
          industry
          culture
          external
          internal
          foundation
          customer
          product
          operations
          people
          stepProfile
          stepInterviews
          stepData
          stepAnalysis
          stepFocus
          createdAt
          updatedAt
        }
        interviews {
          nextToken
        }
        rgmembership {
          nextToken
        }
        createdAt
        updatedAt
        companyUserId
      }
      coordinator {
        userid
        username
        emailid
        permissions
        accessStrat360
        accessSWB
        accessRG
        role
        strategyRole
        jobFunction
        company {
          id
          name
          access
          profile
          industry
          culture
          external
          internal
          foundation
          customer
          product
          operations
          people
          stepProfile
          stepInterviews
          stepData
          stepAnalysis
          stepFocus
          createdAt
          updatedAt
        }
        interviews {
          nextToken
        }
        rgmembership {
          nextToken
        }
        createdAt
        updatedAt
        companyUserId
      }
      status
      createdAt
      updatedAt
      rootsGroupActionsId
      actionOwnerUserid
      actionCoordinatorUserid
    }
  }
`;
export const listActions = /* GraphQL */ `
  query ListActions(
    $id: ID
    $filter: ModelActionFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listActions(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        rootsGroup {
          id
          name
          createdAt
          updatedAt
          companyRootsGroupsId
          rootsGroupSwbId
        }
        owner {
          userid
          username
          emailid
          permissions
          accessStrat360
          accessSWB
          accessRG
          role
          strategyRole
          jobFunction
          createdAt
          updatedAt
          companyUserId
        }
        coordinator {
          userid
          username
          emailid
          permissions
          accessStrat360
          accessSWB
          accessRG
          role
          strategyRole
          jobFunction
          createdAt
          updatedAt
          companyUserId
        }
        status
        createdAt
        updatedAt
        rootsGroupActionsId
        actionOwnerUserid
        actionCoordinatorUserid
      }
      nextToken
    }
  }
`;
export const getPrompt = /* GraphQL */ `
  query GetPrompt($id: ID!) {
    getPrompt(id: $id) {
      id
      interviewSummary
      intsummarycult
      intsummarysa
      intsummaryfound
      intsummaryfocus
      cultureShort
      cultureFull
      externalFull
      externalShort
      internalFull
      internalShort
      foundationShort
      foundationFull
      customerFull
      customerShort
      productFull
      productShort
      operationsFull
      operationsShort
      peopleFull
      peopleShort
      createdAt
      updatedAt
    }
  }
`;
export const listPrompts = /* GraphQL */ `
  query ListPrompts(
    $id: ID
    $filter: ModelPromptFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listPrompts(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        interviewSummary
        intsummarycult
        intsummarysa
        intsummaryfound
        intsummaryfocus
        cultureShort
        cultureFull
        externalFull
        externalShort
        internalFull
        internalShort
        foundationShort
        foundationFull
        customerFull
        customerShort
        productFull
        productShort
        operationsFull
        operationsShort
        peopleFull
        peopleShort
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMeeting = /* GraphQL */ `
  query GetMeeting($id: ID!) {
    getMeeting(id: $id) {
      id
      name
      type
      company {
        id
        name
        access
        user {
          nextToken
        }
        profile
        industry
        InterviewGroups {
          nextToken
        }
        dataFiles {
          nextToken
        }
        examinations {
          nextToken
        }
        swb {
          nextToken
        }
        rootsGroups {
          nextToken
        }
        meetings {
          nextToken
        }
        culture
        external
        internal
        foundation
        customer
        product
        operations
        people
        stepProfile
        stepInterviews
        stepData
        stepAnalysis
        stepFocus
        createdAt
        updatedAt
      }
      meetingDate
      notes
      agenda {
        items {
          id
          name
          description
          status
          createdAt
          updatedAt
          meetingAgendaId
        }
        nextToken
      }
      createdAt
      updatedAt
      companyMeetingsId
    }
  }
`;
export const listMeetings = /* GraphQL */ `
  query ListMeetings(
    $id: ID
    $filter: ModelMeetingFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listMeetings(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        type
        company {
          id
          name
          access
          profile
          industry
          culture
          external
          internal
          foundation
          customer
          product
          operations
          people
          stepProfile
          stepInterviews
          stepData
          stepAnalysis
          stepFocus
          createdAt
          updatedAt
        }
        meetingDate
        notes
        agenda {
          nextToken
        }
        createdAt
        updatedAt
        companyMeetingsId
      }
      nextToken
    }
  }
`;
export const getAgendaItem = /* GraphQL */ `
  query GetAgendaItem($id: ID!) {
    getAgendaItem(id: $id) {
      id
      name
      description
      status
      meeting {
        id
        name
        type
        company {
          id
          name
          access
          profile
          industry
          culture
          external
          internal
          foundation
          customer
          product
          operations
          people
          stepProfile
          stepInterviews
          stepData
          stepAnalysis
          stepFocus
          createdAt
          updatedAt
        }
        meetingDate
        notes
        agenda {
          nextToken
        }
        createdAt
        updatedAt
        companyMeetingsId
      }
      createdAt
      updatedAt
      meetingAgendaId
    }
  }
`;
export const listAgendaItems = /* GraphQL */ `
  query ListAgendaItems(
    $id: ID
    $filter: ModelAgendaItemFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listAgendaItems(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        description
        status
        meeting {
          id
          name
          type
          meetingDate
          notes
          createdAt
          updatedAt
          companyMeetingsId
        }
        createdAt
        updatedAt
        meetingAgendaId
      }
      nextToken
    }
  }
`;
export const rgmembersByRgId = /* GraphQL */ `
  query RgmembersByRgId(
    $rgId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelrgmemberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    rgmembersByRgId(
      rgId: $rgId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        rgId
        userId
        group {
          id
          name
          createdAt
          updatedAt
          companyRootsGroupsId
          rootsGroupSwbId
        }
        user {
          userid
          username
          emailid
          permissions
          accessStrat360
          accessSWB
          accessRG
          role
          strategyRole
          jobFunction
          createdAt
          updatedAt
          companyUserId
        }
        name
        createdAt
        updatedAt
        userRgmembershipUserid
        rootsGroupMembersId
      }
      nextToken
    }
  }
`;
export const rgmembersByUserId = /* GraphQL */ `
  query RgmembersByUserId(
    $userId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelrgmemberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    rgmembersByUserId(
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        rgId
        userId
        group {
          id
          name
          createdAt
          updatedAt
          companyRootsGroupsId
          rootsGroupSwbId
        }
        user {
          userid
          username
          emailid
          permissions
          accessStrat360
          accessSWB
          accessRG
          role
          strategyRole
          jobFunction
          createdAt
          updatedAt
          companyUserId
        }
        name
        createdAt
        updatedAt
        userRgmembershipUserid
        rootsGroupMembersId
      }
      nextToken
    }
  }
`;

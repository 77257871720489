import React, {useState, useEffect} from 'react'
import './Settings.css'
import TemplateBuilder from './TemplateBuilder'
import CompanyTable from './CompanyTable'
import Prompts from './Prompts'



export default function Admin(props){
    const [adminTool, setAdminTool] = useState('CompanyTable');

    function selectTool(e) {
        setAdminTool(e.target.getAttribute('name'))
    }


    return (
        <div className='popup-mask'>
            <div className='settings-window'>
                    <div className="settings-header">
                        <div className='settings-header-item' ></div>
                        <div className='settings-header-item'>SuperAdmin Panel</div>
                        <div className='settings-header-item'></div>
                    </div>
                    <div className="settings-subheader">
                        <div className="button light small" style={{position: 'absolute', left: '20px', top: '0', transform: 'translate(0, 50%)'}} onClick={props.closeAdmin}>Exit Admin</div>
                        <div className={`settings-tool button light ${adminTool === "CompanyTable" ? "active" : ""}`} name="CompanyTable" onClick={selectTool}>Company Table</div>
                        <div className={`settings-tool button light ${adminTool === "TemplateBuilder" ? "active" : ""}`} name="TemplateBuilder" onClick={selectTool}>Interview Templates</div>
                        <div className={`settings-tool button light ${adminTool === "Prompts" ? "active" : ""}`} name="Prompts" onClick={selectTool}>Block Templates</div>
                    </div>
                        {adminTool === 'TemplateBuilder' && <TemplateBuilder />}
                        {adminTool === 'CompanyTable' && <CompanyTable userInfo={props.userInfo}/>}
                        {adminTool === 'Prompts' && <Prompts />} 
            </div>
        </div>
    )
}


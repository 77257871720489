import './chatlog.css'
import React, { useState, useEffect, useRef } from 'react';
import ChatMessage from './ChatMessage';
import MessageBubble from './MessageBubble';
import { API, graphqlOperation } from 'aws-amplify';
import * as queries from '../../../graphql/queries';
import * as mutations from '../../../graphql/mutations';

function ChatLog(props) {
  const chatLogRef = useRef(null); //used to scroll to bottom of chatlog when new message is added
  const inputRef = useRef(null); //used to adjust the height of the chat input field
  const [isTyping, setIsTyping] = useState(false); //state variable that triggers the typing animation
  const [inputValue, setInputValue] = useState(''); //state variable that holds the value of the chat input field
  const [chatLog, setChatLog] = useState([]) //state variable array that holds the chat log where each element of the array is an object with a user and message property
  const [interviewInfo, setInterviewInfo] = useState() //takes the value from the props.info object passed from the App parent component
  const [templateInfo, setTemplateInfo] = useState()
  const [chatLogSummary, setChatLogSummary] = useState('')
  const [toSummarize, setToSummarize] = useState('')
  const [profile, setProfile] = useState()

  

  useEffect (() => {
    if (props.userInfo.userid && props.groupId) {
      console.log("fetching interview", props.userInfo.username, props.groupId)
      fetchInterview()
    } else {
      setInterviewInfo(null)
      setChatLog([])
    }
  }, [props.userInfo, props.groupId])

  async function fetchInterview() {
    const intid = `${props.userInfo.userid}-${props.groupId}`
    const {data} = await API.graphql(graphqlOperation(queries.getInterview, {intid: intid}))
    console.log('interview:', data.getInterview)
    setInterviewInfo(data.getInterview)
  }

  useEffect(() => {
    if (interviewInfo){
      fetchTemplateData()
      fetchChatLog()
      setTimeout(() => {
        fetchChatLog()
    }, 1000)
    }
  }, [interviewInfo]);

  async function fetchTemplateData() {
      const tempid = interviewInfo.group.interviewGroupTemplateTempid
      const compid = interviewInfo.group.companyInterviewGroupsId
      try{
        const { data } = await API.graphql(graphqlOperation(queries.getTemplate, { tempid: tempid }))
        setTemplateInfo(data.getTemplate);
        console.log("Template info:", data.getTemplate);
        const profile = await API.graphql(graphqlOperation(queries.getCompany, { id: compid }))
        setProfile(profile.data.getCompany.profile)
      } catch (err) { console.log(err) }
  }

  async function fetchChatLog() {
    const logid = interviewInfo.conversation.items[0].logid
    console.log("DEBUGGING:", logid)
    const { data } = await API.graphql(graphqlOperation(queries.getChatLog, {logid: logid}))
    console.log("DEBUGGING:", data.getChatLog.messages.items)
    const chatMessages = data.getChatLog.messages.items
    if (Array.isArray(chatMessages)) {
      const sortedData = chatMessages.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
      const formattedData = sortedData.map((message) => ({ msgid: message.msgid, user: message.user, message: message.content }))
      setChatLog(formattedData)
      console.log("restored messages:", formattedData)
    }
    const logInfo = await API.graphql(graphqlOperation(queries.getChatLog, {logid: logid}))
    console.log('chatLog summary:', logInfo.data.getChatLog.chatsummary)
    setChatLogSummary(logInfo.data.getChatLog.chatsummary)
  }
  

  const handleChange = (e) => {
    setInputValue(e.target.value);
  };

  function handleEnter(event) {
    if (event.keyCode === 13 && !event.shiftKey) {
      event.preventDefault();
      sendMessage(inputValue, chatLog);
    }
  }
  

  function handleSubmit(event) {
    event.preventDefault()
    sendMessage(inputValue, chatLog)
  }

  async function storeMessage(messageInfo) {
    try {
      const createMessage = await API.graphql(graphqlOperation(mutations.createChatmessage, { input: messageInfo }))
      console.log("Message stored successfully:", messageInfo.user, createMessage)
    } catch (err) { console.log('error creating User message:', err) }
  }

  function regenerate() {
    console.log("chatLog:",chatLog)
    let usermsg = chatLog[chatLog.length - 2].message
    let log = chatLog.slice(0, chatLog.length - 2)
    console.log("log:", log)
    const deleteMessages = async () => {
      try {
        let gptmsgid = `${interviewInfo.intid}-msg-${chatLog.length}`
        const deleteGptMessage = await API.graphql(graphqlOperation(mutations.deleteChatmessage, {input: {msgid: gptmsgid}}))
        setChatLog(prevState => prevState.slice(0, prevState.length))
        console.log("deleted chat messages:" , deleteGptMessage)
      } catch (error) {console.log(error)}
    }
    deleteMessages()
    setTimeout(() => {
      sendMessage(usermsg, log);
    }, 0)
  }

  useEffect(() => {


  }, [chatLog])

  function sendMessage(msg, log) {
    setIsTyping(true)
    let chatLogNew = [...log, {user: "user", message: `${msg}`}]
    setChatLog(chatLogNew)
    let msgCount = chatLogNew.length
    let messageLast = msg
    setInputValue('')
    let logid = interviewInfo.conversation.items[0].logid
    let newUserMsgid = `${interviewInfo.intid}-msg-${msgCount}`
    let newGPTMsgid = `${interviewInfo.intid}-msg-${msgCount + 1}`
    let userMessageInfo = {chatLogMessagesLogid: logid, msgid: newUserMsgid, user: chatLogNew[msgCount - 1].user, content: chatLogNew[msgCount - 1].message}

    storeMessage(userMessageInfo)

    let chatArray = chatLogNew.map((item) => ({role: item.user, content: item.message})) //creates an array of message objects where the user is assigned as assistant or user
    let systemArray = [
      {role: "system", content: templateInfo.goal},
      {role: "system", content: templateInfo.systemcontext + "\n\nThe company's industry is  " + props.userInfo.company.industry},
      {role: "system", content: `The user is an employee at the company. Here is the company profile:  ${profile}`},
    ]
    let messageArray = [...systemArray, ...chatArray, {role: "system", content: templateInfo.instruction}] 
    setToSummarize(chatArray)

    let payload = JSON.stringify({
      model: templateInfo.GPTmodel,
      MaxTokens: templateInfo.MaxTokens,
      Temperature: templateInfo.Temperature,
      messageArray: messageArray,
      summary: chatLogSummary,
      lastMessage: messageLast,
      logid: logid,
      intid: interviewInfo.intid
    })
    console.log("sending payload: ", JSON.parse(payload))

    
    async function makeRequest(){
      const response = await fetch("https://2uzjzfregh.execute-api.us-west-2.amazonaws.com/dev/interview", {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: payload,
      });
      const data = await response.json();
      setChatLog([...chatLogNew, {user: "assistant", message: `${data.message}`}]);
      console.log("openai:", data);
      setIsTyping(false);
      let newGPTMessageInfo = {chatLogMessagesLogid: logid, msgid: newGPTMsgid, user: 'assistant', content: data.message};
      storeMessage(newGPTMessageInfo);
      const res = await fetch("https://kebp5do3u7.execute-api.us-west-2.amazonaws.com/Prod/interviewsupport", {
      method: "POST",
      headers: {"Content-Type": "application/json"},
      body: payload
    })
    console.log("Started summary request:", res)
    }
    makeRequest() 
  }
  
  useEffect(() => {
    chatLogRef.current.scrollTop = chatLogRef.current.scrollHeight;
  }, [chatLog]);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.rows = 1
      const numLines = Math.min( Math.floor(inputRef.current.scrollHeight / 30), 5 );
      inputRef.current.rows = numLines;
      console.log("number of rows:", inputRef.current.scrollHeight/30)
    }
  }, [inputValue, inputRef]);

  return (
    <div ref={chatLogRef} className="chat-log">
          {chatLog.map((message, index) => (
          <ChatMessage key={index} message={message} />
          ))}
        {isTyping && <MessageBubble content="Typing..." />}
        <div style={{height: "100px"}}>.</div>
        <form className="chat-input-holder">
              <div className="input-and-button-container">
                <textarea 
                rows="1"
                ref={inputRef}
                className="chat-input-area"
                value={inputValue}
                placeholder="Type your message here..."
                onChange={handleChange}
                onKeyDown={handleEnter}
                />
                <img className="button graphic"
                  src="./Assets/send-icon.png"
                  style={{width: "50px", height: "50px"}}
                  onClick={handleSubmit}
                  alt="send"
                />
              </div>
              <div style={{margin: "auto", marginRight: '2%'}}>
                <img className="button graphic"
                    src="./Assets/regen.png"
                    style={{width: "40px", height: "45px"}}
                    onClick={regenerate}
                    alt="regen"
                  />
              </div>
        </form>
    </div>
  );
}

export default ChatLog;

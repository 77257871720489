import { React, useState, useEffect, useRef } from 'react';
import {API, graphqlOperation} from 'aws-amplify';
import * as queries from '../../graphql/queries';
import * as mutations from '../../graphql/mutations';
import './roots.css'


export default function Roots(props) {


    const [rootsGroups, setRootsGroups] = useState([])
    const [selectedRG, setSelectedRG] = useState('')
    const [outcomes, setOutcomes] = useState([])
    const [actions, setActions] = useState([])
    const [members, setMembers] = useState([])
    const [rgName, setRgName] = useState('')
    const [swb, setSwb] = useState('')
    const [availableOutcomes, setAvailableOutcomes] = useState([])
    const [changes, setChanges] = useState(false)
    const [showNewItem, setShowNewItem] = useState('')
    const [openItemList, setOpenItemList] = useState(false)
    const [itemInput, setItemInput] = useState('')
    const [actionInput, setActionInput] = useState('')
    const [newActionInput, setNewActionInput] = useState('')
    const [edit, setEdit] = useState(-1)
    const [editMember, setEditMember] = useState(-1)
    const [openMenu, setOpenMenu] = useState(false)

    useEffect(() => {
        fetchInfo()
    }, [props.companyInfo])

    async function fetchInfo () {
        if (props.companyInfo.swb.items.length > 0) {
            const swbInfo = await API.graphql(graphqlOperation(queries.getSwb, {id: props.companyInfo.swb.items[0].id}))
            setSwb(swbInfo.data.getSwb)
            const itemsFilteredtoOutcomes = swbInfo.data.getSwb.blockItems.items.filter(item => item.type === 'outcome' && item.status === 'active')
            setAvailableOutcomes(itemsFilteredtoOutcomes)
            console.log("SWB Info Set:", swbInfo.data.getSwb)
        }
        if (props.companyInfo.rootsGroups.items.length > 0) {
            const sortedRootsGroups = props.companyInfo.rootsGroups.items.sort((a,b) => a.createdAt > b.createdAt ? 1 : -1)
            setRootsGroups(sortedRootsGroups)
        }
    }

    const groupedOutcomeOptions = availableOutcomes.reduce((acc, outcome) => {
        const blockId = outcome.blockBlockItemsId;
        if (!acc[blockId]) {
            acc[blockId] = [];
        }
        acc[blockId].push(outcome);
        return acc;
    }, {}); 
    
    let blockOutcomeGroups = {};

    if (Object.keys(groupedOutcomeOptions).length > 0) {
        blockOutcomeGroups = Object.keys(groupedOutcomeOptions).reduce((acc, blockId) => {
            const block = swb.blocks.items.find(item => item.id === blockId);
            const blockName = block ? block.name : 'No Block Name';
            acc[blockName] = groupedOutcomeOptions[blockId];
            return acc;
        }, {});
    }

    const rootsGroupsList = rootsGroups.map((rootsGroup, index) =>
        <div className={`roots-tile ${selectedRG.id === rootsGroup.id && "active"}`} key={index} onClick={()=>handleGroupClick(rootsGroup)}>
            {rootsGroup.name}
        </div>
    )

    async function handleGroupClick (group) {
        console.log("Roots Group Selected:", group)
        setSelectedRG(group)
        setShowNewItem('')
        setOpenItemList(false)
        setOpenMenu(false)
        if (group.name) {
            setRgName(group.name)
        } else {
            setRgName('')
        }
        const {data} = await API.graphql(graphqlOperation(queries.getRootsGroup, {id: group.id}))
        console.log("Roots Group Selected:", data.getRootsGroup)
        const sortedActions = data.getRootsGroup.actions.items.sort((a,b) => a.createdAt > b.createdAt ? 1 : -1)
        setActions(sortedActions)
        const sortedOutcomes = data.getRootsGroup.outcomes.items.sort((a,b) => a.createdAt > b.createdAt ? 1 : -1)
        setOutcomes(sortedOutcomes)
        const sortedMembers = data.getRootsGroup.members.items.sort((a,b) => a.createdAt > b.createdAt ? 1 : -1)
        setMembers(sortedMembers)
    }

    async function deleteGroup () {
        setOpenMenu(false)
        const deleteMemberPromises = members.map(async (member) => {
            const response = await API.graphql(graphqlOperation(mutations.deleteRgmember, {input: {id: member.id}}));
            console.log("Member Deleted:", response.data.deleteRgmember);
        });

        Promise.all(deleteMemberPromises)
            .then(() => {
                console.log("All members deleted");
            })
            .catch(error => {
                console.log("Error deleting a member:", error);
            });

        const deleteActionPromises = actions.map(async (action) => {
            const response = await API.graphql(graphqlOperation(mutations.deleteAction, {input: {id: action.id}}));
            console.log("Action Deleted:", response.data.deleteAction);
        });
        
        Promise.all(deleteActionPromises)
            .then(() => {
                console.log("All actions deleted");
            })
            .catch(error => {
                console.log("Error deleting an action:", error);
            });

        const deleteOutcomePromises = outcomes.map(async (outcome) => {
            const response = await API.graphql(graphqlOperation(mutations.deleteOutcome, {input: {id: outcome.id}}));
            const {data} = await API.graphql(graphqlOperation(mutations.updateBlockItem, {input: {id: outcome.blockItemId, status: 'active'}}))
            console.log("Outcome Deleted:", response.data.deleteOutcome, "\nBlock Item Updated:", data.updateBlockItem);
        });
        Promise.all(deleteOutcomePromises)
            .then(() => {
                console.log("All outcomes deleted");
            })
            .catch(error => {
                console.log("Error deleting an outcome:", error);
            });

        try {
            const {data} = await API.graphql(graphqlOperation(mutations.deleteRootsGroup, {input: {id: selectedRG.id}}))
            console.log("Roots Group Deleted:", data.deleteRootsGroup)
        } catch (error) {
            console.log("Error deleting Roots Group:", error)
        }
        const index = rootsGroups.findIndex(group => group.id === selectedRG.id)
        if (index !== -1) {
            const updatedRootsGroups = [...rootsGroups];
            updatedRootsGroups.splice(index, 1);
            setRootsGroups(updatedRootsGroups); 
        }
        setSelectedRG('')
        setRgName('')
    }

    const filteredMembers = members.filter(member => member.rgId === selectedRG.id)
    
    const membersList = filteredMembers.map((member,index) =>
        <div className="roots-group-user-item" key={index}>
            <img src="./Assets/user-icon.png" style={{width: '30px', cursor: 'pointer'}} onClick={()=>{setItemInput(member.name); setEditMember(index)}}></img>
            {editMember === index ? <input className="roots-newItem-field" placeholder="Add new member" value={itemInput} onChange={(e)=>setItemInput(e.target.value)}></input> 
            : <div className="roots-group-user-name" onClick={()=>{setItemInput(member.name); setEditMember(index)}}>{member.name}</div>}
            {editMember === index && <div className="roots-newItem-button" onClick={updateMember}></div>}
            {editMember === index && <div className="roots-newItem-button remove" onClick={removeMember}></div>}
        </div>
    )

    async function addMember() {
        setItemInput('')
        const newMember = {name: '', rgId: selectedRG.id, rootsGroupMembersId: selectedRG.id}
        let newMembersList = [...members, newMember]
        setMembers(newMembersList)
        setEditMember(newMembersList.length-1)
        try {
            const {data} = await API.graphql(graphqlOperation(mutations.createRgmember, {input: newMember}))
            const updatedMemberList = [...newMembersList]
            updatedMemberList[newMembersList.length-1].id = data.createRgmember.id
            setMembers(updatedMemberList)
            console.log("New Roots Group Member added:", data.createRgmember)
        } catch (error) {
            console.log("Error creating new member:", error)
        }
    }

    async function updateMember() {
        const updatedMembers = [...members]
        updatedMembers[editMember].name = itemInput
        setMembers(updatedMembers)
        setEditMember(-1)
        try {
            const {data} = await API.graphql(graphqlOperation(mutations.updateRgmember, {input: {id: members[editMember].id, name: itemInput}}))
            console.log("Member Updated:", data.updateRgmember)
        } catch (error) {
            console.log("Error updating member:", error)
        }
    }

    async function removeMember() {
        const updatedMembers = [...members]
        updatedMembers.splice(editMember, 1)
        setMembers(updatedMembers)
        setEditMember(-1)
        try {
            const {data} = await API.graphql(graphqlOperation(mutations.deleteRgmember, {input: {id: members[editMember].id}}))
            console.log("Member Deleted:", data.deleteRgmember)
        } catch (error) {
            console.log("Error deleting member:", error)
        }
    }

    const filteredOutcomes  = outcomes.filter(outcome => outcome.rootsGroupOutcomesId === selectedRG.id)

    const outcomesList = filteredOutcomes.map((outcome,index) =>
        <div className="roots-list-item" key={index}>
            <div className={`roots-table-graphic-button bullet`} style={{position: 'relative', marginRight: '20px'}}>
                <img src="./Assets/Bullet_black.png" style={{width: '20px'}}></img>
            </div>
            <div className="roots-list-item-name" onClick={()=>{edit[0] === index ? setEdit([-1, -1]) : setEdit([index, -1]); console.log(outcomes[index])}}>{outcome.name}</div>
            {edit[0] === index && <div className="roots-newItem-button remove" style={{marginLeft: '20px'}} onClick={removeOutcome}></div>}
        </div>
    )

    const actionsList = actions.map((action,index) =>
        <div className="roots-list-item" key={index}>
            <div className={`roots-table-graphic-button`} style={{position: 'relative', marginRight: '20px'}} onClick={()=>handleCheckBox(index)}>
                {action.status === "complete" ? <img src="./Assets/box_checked_black.png" style={{width: '25px'}}></img> :
                <img src="./Assets/box_unchecked_black.png" style={{width: '25px'}}></img>}
            </div>
            {edit[1] === index ? <input className="roots-newItem-field" placeholder="Edit Strategic Action" value={actionInput} onChange={(e)=>setActionInput(e.target.value)}></input>
            : <div className="roots-list-item-name" onClick={()=>{setEdit([-1, index]); setActionInput(action.name)}}>{action.name}</div>}
            {edit[1] === index && <div className="roots-newItem-button" onClick={updateAction}></div>}
            {edit[1] === index && <div className="roots-newItem-button remove" onClick={removeAction}></div>}
        </div>
    )

    async function handleCheckBox (index) {
        const updatedActions = [...actions]
        updatedActions[index].status = updatedActions[index].status === 'open' ? 'complete' : 'open' 
        setActions(updatedActions)
        const input = {
            id: actions[index].id,
            status: actions[index].status
        }
        try {
            const {data} = await API.graphql(graphqlOperation(mutations.updateAction, {input: input}))
            console.log("Action Updated:", data.updateAction)
        } catch (error) {
            console.log("Error updating Action:", error)
        }
    }

    function handleAddButton (type) {
        setNewActionInput('')
        if (type === 'outcome') {
            if (showNewItem === 'outcome') {
                setShowNewItem('')
                setOpenItemList(false)
            } else {
                setShowNewItem('outcome')
            }
        } else if (type === 'action') {
            if (showNewItem === 'action') {
                setShowNewItem('')
            } else {
                setShowNewItem('action')
            }
        }
    }

    // type isn't being used at the moment, but it will be when we add the ability to add new outcomes
    const newItem = (type) => {
        return (
            <div className="roots-list-newItem">
                <input className="roots-newItem-field" placeholder="Add Strategic Action" value={newActionInput} onChange={(e)=>setNewActionInput(e.target.value)}></input>
                <div className="roots-newItem-button" onClick={addNewAction}></div>
            </div>
        )
    }

    const selectOutcome = () => {
        return (
            <div className="roots-list-newItem">
                <div className={`roots-list-dropdown ${openItemList && 'open'}`}>
                    <div className="roots-list-selector-container" style={{justifyContent: 'center'}} onClick={()=>setOpenItemList(!openItemList)}>
                        <div className="roots-list-selector">Select a Strategic Outcome</div>
                        <div className={`roots-list-selection-arrow ${openItemList && 'rotated'}`}></div>
                    </div>
                    {openItemList && <div className="roots-list-options-menu">
                        {GroupedOutcomesList}
                        {GroupedOutcomesList.length === 0 && <div>No Available Outcomes</div>}
                    </div>}
                </div>
            </div>
        )
    }

    /*const outcomeOptionsList = availableOutcomes.map((item,index) =>
        <div className="roots-list-selection-item" key={index} onClick={()=>handleSelection(item)}>
            {item.name}
        </div>
    )*/

    const GroupedOutcomesList =(
            <div>
                {Object.entries(blockOutcomeGroups)
                .sort(([aName, aOutcomes], [bName, bOutcomes]) => aName > bName ? 1 : -1)
                .map(([categoryName, outcomes]) => (
                    <div key={categoryName} className="roots-list-selection-category">
                        <div className="roots-list-category-name">{categoryName} Block</div>
                        {outcomes.map(outcome => (
                            <div key={outcome.id} className="roots-list-selection-item" onClick={()=>handleSelection(outcome)}>
                                {outcome.name}
                            </div>
                        ))}
                    </div>
                ))}
            </div>
        )

    function handleSelection(item) {
        setOpenItemList(false)
        addNewOutcome(item)
        updateItem({id: item.id, status: 'assigned'})
        const newAvailableOutcomes = [...availableOutcomes]
        const index = availableOutcomes.findIndex(outcome => outcome.id === item.id)
        if (index !== -1) {
            newAvailableOutcomes.splice(index, 1)
            setAvailableOutcomes(newAvailableOutcomes)
        }
    }

    async function createNewGroup() {
        setRgName('')
        const input = {
            companyRootsGroupsId: props.companyInfo.id,
            rootsGroupSwbId: swb.id, 
        }
        try {
            const {data} = await API.graphql(graphqlOperation(mutations.createRootsGroup, {input: input}))
            setRootsGroups([...rootsGroups, data.createRootsGroup])
            setMembers([])
            setSelectedRG(data.createRootsGroup)
            setChanges(true)
            console.log("New Roots Group Created:", data.createRootsGroup)
        } catch (error) {
            console.log("Error creating new Roots Group:", error)
        }
    }

    async function handleSave() {
        const input = {
            id: selectedRG.id,
            name: rgName,
        }
        try {
            const {data} = await API.graphql(graphqlOperation(mutations.updateRootsGroup, {input: input}))
            setChanges(false)
            console.log("Roots Group Updated:", data.updateRootsGroup)
            const index = rootsGroups.findIndex(group => group.id === selectedRG.id)
            if (index !== -1) {
                const updatedRootsGroups = [...rootsGroups];
                updatedRootsGroups[index] = data.updateRootsGroup;
                setRootsGroups(updatedRootsGroups); 
            }
        } catch (error) {
            console.log("Error updating Roots Group:", error, input)
        }
    }


    function addNewOutcome(outcome) {
        const input = {
            rootsGroupOutcomesId: selectedRG.id,
            name: outcome.name,
            blockItemId: outcome.id
        }
        createOutcome(input)
    }

    function addNewAction() {
        if (newActionInput === '') {
            return
        }
        const input = {
            name: newActionInput,
            rootsGroupActionsId: selectedRG.id,
            status: 'open'
        }
        setActions([...actions, input])
        createAction(input)
        setNewActionInput('')
    }

    async function createOutcome(input) {
        try {
            const {data} = await API.graphql(graphqlOperation(mutations.createOutcome, {input: input}))
            console.log("New Outcome Created:", data.createOutcome)
            const newOutcome = data.createOutcome
            setOutcomes([...outcomes, newOutcome])
        } catch (error) {
            console.log("Error creating new Outcome:", error)
        }
    }

    async function createAction(input) {
        try {
            const {data} = await API.graphql(graphqlOperation(mutations.createAction, {input: input}))
            console.log("New Action Created:", data.createAction)
        } catch (error) {
            console.log("Error creating new Action:", error)
        }
    }

    async function updateAction() {
        const input = {
            id: actions[edit[1]].id,
            name: actionInput,
        }
        try {
            const {data} = await API.graphql(graphqlOperation(mutations.updateAction, {input: input}))
            console.log("Action Updated:", data.updateAction)
        } catch (error) {
            console.log("Error updating Action:", error)
        }
        const newActions = [...actions]
        newActions[edit[1]].name = actionInput
        setActions(newActions)
        setEdit([-1, -1])
        setActionInput('')
    }

    async function removeAction() {
        try {
            const {data} = await API.graphql(graphqlOperation(mutations.deleteAction, {input: {id: actions[edit[1]].id}}))
            console.log("Action Deleted:", data.deleteAction)
        } catch (error) {
            console.log("Error deleting Action:", error)
        }
        setEdit([-1, -1])
        const newActions = [...actions]
        newActions.splice(edit[1], 1)
        setActions(newActions)
    }

    async function removeOutcome() {
        const itemId = outcomes[edit[0]].id
        const blockItemId = outcomes[edit[0]].blockItemId
        try {
            const {data} = await API.graphql(graphqlOperation(mutations.deleteOutcome, {input: {id: itemId}}))
            console.log("Outcome Removed:", data)
        } catch (error) {
            console.log("Error removing Outcome:", error)
        }
        updateItem({id: blockItemId, status: 'active'})
        setEdit([-1, -1])
        const newOutcomes = [...outcomes]
        newOutcomes.splice(edit[0], 1)
        setOutcomes(newOutcomes)
        setAvailableOutcomes([...availableOutcomes, outcomes[edit[0]]])
    }

    async function updateItem(input) {
        try {
            console.log("Block Item to be updated:", input)
            const {data} = await API.graphql(graphqlOperation(mutations.updateBlockItem, {input: input}))
            console.log("Block Item Updated:", data.updateBlockItem)
        } catch (error) {
            console.log("Error updating Outcome:", error)
        }
    }

    return (
        <div className="roots-main">
            <div className="roots-tile-section">
                <div className="roots-add-container">
                    <div className="roots-add-button" onClick={()=>createNewGroup('New Group')}></div>
                    <div className="roots-add-text">Add New Roots Group</div>
                </div>
                <div className="roots-tile-container">
                    {rootsGroupsList}
                </div>
            </div>
            {selectedRG !== '' && <div className="roots-details-container">
                <div className="roots-table-menu" >
                    {openMenu && <div className="button small" style={{backgroundColor: '#771C1B'}} onClick={deleteGroup}>Delete</div>}
                    <img src="./Assets/skewer-menu.png" style={{height: '40px'}} onClick={()=>setOpenMenu(!openMenu)}></img>
                </div>
                <div className="roots-details-header" >
                    <input className={`roots-details-header-input ${!changes && 'saved'}`} 
                        placeholder="Enter Group Name" 
                        value={rgName} 
                        onChange={(e)=>{setRgName(e.target.value); setChanges(true)}}>
                    </input>
                    {changes ? <div className="button small" style={{alignSelf: 'center'}} onClick={handleSave}>Save</div> : null}
                </div>
                <div className="roots-details-upper">
                    <div className="roots-table-graphic-button adduser" onClick={addMember}>
                        <img src="./Assets/add-user.png" style={{width: '30px', marginRight: '10px'}}></img>
                        <div style={{marginTop: '5px'}}>Add members</div>
                    </div>
                    {membersList}
                </div>
                <div className="roots-details-lower">
                    <div className="roots-details-table">
                        <div className="roots-details-table-header">
                            <div>Strategic Outcomes</div>
                            <div className="roots-table-graphic-button addItem" onClick={()=>handleAddButton('outcome')}>
                                {showNewItem === 'outcome' ? <img src="./Assets/Add_primary.png" style={{width: '25px', transform: 'rotate(45deg)'}}></img> :
                                <img src="./Assets/Add_primary.png" style={{width: '25px'}}></img>}
                            </div>
                        </div>
                        <div className="standard-divider" style={{width: '80%'}}></div>
                        <div className="roots-details-list">
                            {showNewItem === 'outcome' && selectOutcome()}
                            {outcomesList}
                        </div>
                    </div>
                    <div className="roots-details-table">
                        <div className="roots-details-table-header">
                            <div>Strategic Actions</div>
                            <div className="roots-table-graphic-button addItem" onClick={()=>handleAddButton('action')}>
                                {showNewItem === 'action' ? <img src="./Assets/Add_primary.png" style={{width: '25px', transform: 'rotate(45deg)'}}></img> :
                                <img src="./Assets/Add_primary.png" style={{width: '25px'}}></img>}
                            </div>
                        </div>
                        <div className="standard-divider" style={{width: '80%'}}></div>
                        <div className="roots-details-list">
                            {showNewItem === 'action' && newItem()}
                            {actionsList}
                        </div>
                    </div>
                    
                </div>
            </div>}
        </div>
    )
}
import React, {useState} from 'react';
import './roots.css'
import MeetingPanel from '../Meetings/MeetingPanel';


export default function RootsSidePanel (props) {

    const [view, setView] = useState('default')
    const [meetingType, setMeetingType] = useState('')

    const defaultPanel = (
        <div>
            <div className="roots-sidepanel-header">Roots Groups</div>
            <div className="roots-sidepanel-section">
                <div className="roots-sidepanel-section-item" onClick={()=>{setView('meeting'); setMeetingType('rootsgroup')}}>
                    <img src="./Assets/roots-meeting.png" style={{width: "40%"}}></img>
                    <div>Roots Group Meeting</div>
                </div>
            </div>
            <div className="roots-sidepanel-section">
                <div className="roots-sidepanel-section-item" onClick={()=>{setView('meeting'); setMeetingType('connection')}}>
                    <img src="./Assets/connection-meeting.png" style={{width: "50%"}}></img>
                    <div>Connection Meeting</div>
                </div>
            </div>
        </div>
    )

    return (
        <div className="roots-sidepanel-body">
            { view === 'default' ? defaultPanel
            : view === 'meeting' ? <MeetingPanel closeMeeting={()=>setView('default')} meetingType={meetingType} userInfo={props.userInfo} />
            : null}
        </div>
    )
}
import React, {useState, useEffect, useRef} from 'react'
import { API, graphqlOperation } from 'aws-amplify';
import * as mutations from '../../graphql/mutations';
import * as queries from '../../graphql/queries';
import './users.css'


export default function Users(props){
  
  const [users, setUsers] = useState([])
  const [editItem, setEditItem] = useState([])
  const [inputText, setInputText] = useState('')

  useEffect(() => {
    fetchUsers()
  }, [props.userInfo])

  async function fetchUsers() {
    const {data} = await API.graphql(graphqlOperation(queries.listUsers, {limit: 10000, filter : {companyUserId: {eq: props.userInfo.companyUserId}}}))
    const filteredUsers = data.listUsers.items.filter(user => user.permissions !== "SuperAdmin")
    setUsers(filteredUsers)
  }


  const [showMeatballMenu, setShowMeatballMenu] = useState(true)
    const meatballMenu = (
        <div>
            <div className="meatballMenu" onClick={()=>setShowMeatballMenu(!showMeatballMenu)}></div>
            {showMeatballMenu && <div className="main-menu meatballLeft">
                <button className="button small" onClick={()=>props.selectModule('Home')}>Back</button>
            </div>}
        </div>
    )

  async function saveUser(data) {
    let updatedInfo = { userid: users[editItem[0]].userid }
    let fieldToUpdate = '';
    if (editItem[1] === "role") {
      updatedInfo = { ...updatedInfo, strategyRole: data }
      fieldToUpdate = 'strategyRole';
    } else if (editItem[1] === "type") {
      updatedInfo = { ...updatedInfo, permissions: data }
      fieldToUpdate = 'permissions';
    } else if (editItem[1] === "name") {
      data = inputText;
      updatedInfo = { ...updatedInfo, username: data }
      fieldToUpdate = 'username';
    }
  
    try {
      const { data } = await API.graphql(graphqlOperation(mutations.updateUser, { input: updatedInfo }))
      console.log('Updated User:', data)
    } catch (error) {
      console.log('Error:', error)
    }
  
    const updatedUsers = [...users];

    if (fieldToUpdate) {
      updatedUsers[editItem[0]][fieldToUpdate] = data;
    }
    
    setUsers(updatedUsers);
    setEditItem([]);
    setInputText('');
  }

  function handleSelection(data) {
    saveUser(data)
  }
    

  const pageHeader = (
    <div className="standard-floating-header">
        <div className="standard-floating-menu-container left">{meatballMenu}</div>
        <div className="standard-floating-title">User Management</div>
        <div className="standard-floating-menu-container right"></div>
    </div>
  )

  /*const deleteButton = (
    <div className="user-management-inLine-buttonContainer">
      <div className="user-management-item-button remove" onClick={deleteUser}></div>
    </div>
  )*/

  const saveButton = (
    <div className="user-management-item-button save" onClick={saveUser}></div>
  )

  const editRef = useRef()

  function handleClickOutside(event) {
    if (editRef.current && !editRef.current.contains(event.target)) {
      setEditItem([]);
    }
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  

  const userTypes = (
    <div className="user-detail-selectionBox" ref={editRef}>
      <div className="user-detail-selectionBox-option" onClick={()=>handleSelection('Admin')}>Admin</div>
      <div className="user-detail-selectionBox-option" onClick={()=>handleSelection('Standard')}>Standard</div>
    </div>
  )

  const userRoles = (
    <div className="user-detail-selectionBox" ref={editRef}>
      <div className="user-detail-selectionBox-option" onClick={()=>handleSelection('Interview Only')}>Interview Only</div>
      <div className="user-detail-selectionBox-option" onClick={()=>handleSelection('Roots Group')}>Roots Group</div>
      <div className="user-detail-selectionBox-option" onClick={()=>handleSelection('Strategy Team')}>Strategy Team</div>
    </div>
  )

  const userList = users.map((user, index) => {
    return (
      <div className={`user-management-userList-item ${editItem[0] === index && 'selected'}`} key={index}>
        {editItem[0] === index && editItem[1] === "name" ? 
          <div className='user-management-detail' ref={editRef}>
            <input className="user-management-detail-input" value={inputText} onChange={(e)=>setInputText(e.target.value)}></input>
            {saveButton}
          </div> :
          <div className='user-management-detail' onClick={()=>{setEditItem([index, 'name']); setInputText(user.username)}}>
            {user.username}
          </div>
        }
        <div className='user-management-detail email'>{user.emailid}</div>
        {editItem[0] === index && editItem[1] === "type" ? 
          <div className='user-management-detail-menu' >
            {userTypes}
          </div> :
          <div className='user-management-detail' onClick={()=>{setEditItem([index, 'type']); setInputText(user.permissions)}}>
            {user.permissions}
          </div>
        }
        {editItem[0] === index && editItem[1] === "role" ? 
          <div className='user-management-detail-menu' >
            {userRoles}
          </div> :
          <div className='user-management-detail' onClick={()=>{setEditItem([index, 'role']); setInputText(user.strategyRole)}}>
            {user.strategyRole ? user.strategyRole : "Interview Only"}
          </div>
        }
      </div>
    )
  })

  return (
    <div className="standard-main">
      {pageHeader}
      <div className="standard-box" style={{height: '85vh'}}>
        <div className='user-management-column-header-row'>
          <div className='user-management-column-heading'>Name</div>
          <div className='user-management-column-heading email'>Email</div>
          <div className='user-management-column-heading'>User Type</div>
          <div className='user-management-column-heading'>User Role</div>
        </div>
        <div className="standard-divider long" style={{width: '95%'}}></div>
        <div className="user-management-table">
          {userList}
        </div>
      </div>
    </div>
  )
}


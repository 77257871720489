import React from 'react';
import './MessageBubble.css';

const MessageBubble = ({ content }) => {
  return (
    <div className="message-bubble">
      <div className="dot"></div>
      <div className="dot"></div>
      <div className="dot"></div>
      <p>{content}</p>
    </div>
  );
};

export default MessageBubble;

import {React, useEffect, useState} from 'react'
import './home.css'



export default function Home(props) {

    const [access, setAccess] = useState({cultDev: false, stratPrep: true, RootsGrps: false, OffshootsGrps: false, PWB: false, SWB: false})
    const [hovered, setHovered] = useState('')

    console.log('Home Props:', props.userInfo)

    useEffect(() => {
        if (props.userInfo && props.userInfo.permissions === 'SuperAdmin' )
        {setAccess({cultDev: false, stratPrep: true, RootsGrps: true, OffshootsGrps: false, PWB: false, SWB: true})}
        else if (props.userInfo && props.userInfo.strategyRole === 'Strategy Team' )
        {setAccess({cultDev: false, stratPrep: true, RootsGrps: true, OffshootsGrps: false, PWB: false, SWB: true})}
        else if (props.userInfo && props.userInfo.strategyRole === 'Roots Group' )
        {setAccess({cultDev: false, stratPrep: true, RootsGrps: true, OffshootsGrps: false, PWB: false, SWB: false})}
    }, [props.userInfo])

  return (
    <div className="home">
        <div className="home-section">
            <div className="home-section-title">Company Focus</div>
            <div className="home-app">
                <div className={`home-app-tile ${!access.stratPrep && 'noAccess'}`} onMouseEnter={()=>setHovered('stratprep')} onMouseLeave={()=>setHovered('')} onClick={()=>{props.selectModule('profile'); props.selectApp('stratPrep')}}>
                    <img src='./Assets/strat-prep-icon.png' style={{width: '80px'}} alt='strategy prep'></img>
                </div>
                {!access.stratPrep && hovered === 'stratprep' ?
                <div className="home-requestAccess-link" onMouseEnter={()=>setHovered('stratprep')} onMouseLeave={()=>setHovered('')}>Request Access</div> :
                <div className="home-app-description" onClick={()=>props.selectModule('profile')}>Strategy 360</div>}
            </div>
            <div className="home-app">
                <div className={`home-app-tile ${!access.SWB && 'noAccess'}`} onMouseEnter={()=>setHovered('swb')} onMouseLeave={()=>setHovered('')} onClick={()=>{props.selectApp('SWB'); props.selectModule('')}}>
                    <img src='./Assets/swb-icon.png' style={{width: '80px', marginLeft: '7px'}} alt='swb'></img>
                </div>
                {!access.SWB && hovered === 'swb' ?
                <div className="home-requestAccess-link" onMouseEnter={()=>setHovered('swb')} onMouseLeave={()=>setHovered('')}>Request Access</div> :
                <div className="home-app-description" >Strategy Whiteboard</div>}
            </div>
            <div className="home-app">
                <div className={`home-app-tile ${!access.RootsGrps && 'noAccess'}`} onMouseEnter={()=>setHovered('roots')} onMouseLeave={()=>setHovered('')} onClick={()=>{props.selectApp('roots'); props.selectModule('')}}>
                    <img src='./Assets/roots-icon.png' style={{width: '80px'}} alt='roots'></img>
                </div>
                {!access.RootsGrps && hovered === 'roots' ?
                <div className="home-requestAccess-link" onMouseEnter={()=>setHovered('roots')} onMouseLeave={()=>setHovered('')}>Request Access</div> :
                <div className="home-app-description">Roots Groups</div>}
            </div>
        </div>
        <div className="home-section">
            <div className="home-section-title">Employee Focus</div>
            <div className="home-app">
                <div className={`home-app-tile ${!access.cultDev && 'noAccess'}`} onMouseEnter={()=>setHovered('culture')} onMouseLeave={()=>setHovered('')}>
                    <img src='./Assets/culture-icon.png' style={{width: '80px'}} alt='culture'></img>
                </div>
                {!access.cultDev && hovered === 'culture' ?
                <div className="home-requestAccess-link" onMouseEnter={()=>setHovered('culture')} onMouseLeave={()=>setHovered('')}>Request Access</div> : 
                <div className="home-app-description culture">Personal 360</div>}
            </div>
            <div className="home-app">
                <div className={`home-app-tile ${!access.PWB && 'noAccess'}`} onMouseEnter={()=>setHovered('pwb')} onMouseLeave={()=>setHovered('')}>
                    <img src='./Assets/pwb-icon.png' style={{width: '80px', marginLeft: '7px'}} alt='pwb'></img>
                </div>
                {!access.PWB && hovered === 'pwb' ?
                <div className="home-requestAccess-link" onMouseEnter={()=>setHovered('pwb')} onMouseLeave={()=>setHovered('')}>Request Access</div> :
                <div className="home-app-description">Personal Whiteboard</div>}
            </div>
            <div className="home-app">
                <div className={`home-app-tile ${!access.OffshootsGrps && 'noAccess'}`} onMouseEnter={()=>setHovered('offshoots')} onMouseLeave={()=>setHovered('')}>
                    <img src='./Assets/offshoots-icon.png' style={{width: '70px'}} alt='offshoots'></img>
                </div>
                {!access.OffshootsGrps && hovered === 'offshoots' ?
                <div className="home-requestAccess-link" onMouseEnter={()=>setHovered('offshoots')} onMouseLeave={()=>setHovered('')}>Request Access</div> :
                <div className="home-app-description">Offshoots Groups</div>}
            </div>
        </div>
    </div>
  )
}
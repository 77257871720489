/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createInterviewGroup = /* GraphQL */ `
  mutation CreateInterviewGroup(
    $input: CreateInterviewGroupInput!
    $condition: ModelInterviewGroupConditionInput
  ) {
    createInterviewGroup(input: $input, condition: $condition) {
      Groupid
      Groupname
      GPTmodel
      MaxTokens
      Temperature
      Company {
        id
        name
        access
        user {
          nextToken
        }
        profile
        industry
        InterviewGroups {
          nextToken
        }
        dataFiles {
          nextToken
        }
        examinations {
          nextToken
        }
        swb {
          nextToken
        }
        rootsGroups {
          nextToken
        }
        meetings {
          nextToken
        }
        culture
        external
        internal
        foundation
        customer
        product
        operations
        people
        stepProfile
        stepInterviews
        stepData
        stepAnalysis
        stepFocus
        createdAt
        updatedAt
      }
      NameSpace
      Template {
        tempid
        name
        GPTmodel
        MaxTokens
        Temperature
        profile
        goal
        systemcontext
        instruction
        firstMessage
        instructions {
          nextToken
        }
        createdAt
        updatedAt
      }
      Interviews {
        items {
          intid
          summary
          conclusions
          active
          process
          status
          createdAt
          updatedAt
          interviewGroupInterviewsGroupid
          userInterviewsUserid
        }
        nextToken
      }
      createdAt
      updatedAt
      companyInterviewGroupsId
      interviewGroupTemplateTempid
    }
  }
`;
export const updateInterviewGroup = /* GraphQL */ `
  mutation UpdateInterviewGroup(
    $input: UpdateInterviewGroupInput!
    $condition: ModelInterviewGroupConditionInput
  ) {
    updateInterviewGroup(input: $input, condition: $condition) {
      Groupid
      Groupname
      GPTmodel
      MaxTokens
      Temperature
      Company {
        id
        name
        access
        user {
          nextToken
        }
        profile
        industry
        InterviewGroups {
          nextToken
        }
        dataFiles {
          nextToken
        }
        examinations {
          nextToken
        }
        swb {
          nextToken
        }
        rootsGroups {
          nextToken
        }
        meetings {
          nextToken
        }
        culture
        external
        internal
        foundation
        customer
        product
        operations
        people
        stepProfile
        stepInterviews
        stepData
        stepAnalysis
        stepFocus
        createdAt
        updatedAt
      }
      NameSpace
      Template {
        tempid
        name
        GPTmodel
        MaxTokens
        Temperature
        profile
        goal
        systemcontext
        instruction
        firstMessage
        instructions {
          nextToken
        }
        createdAt
        updatedAt
      }
      Interviews {
        items {
          intid
          summary
          conclusions
          active
          process
          status
          createdAt
          updatedAt
          interviewGroupInterviewsGroupid
          userInterviewsUserid
        }
        nextToken
      }
      createdAt
      updatedAt
      companyInterviewGroupsId
      interviewGroupTemplateTempid
    }
  }
`;
export const deleteInterviewGroup = /* GraphQL */ `
  mutation DeleteInterviewGroup(
    $input: DeleteInterviewGroupInput!
    $condition: ModelInterviewGroupConditionInput
  ) {
    deleteInterviewGroup(input: $input, condition: $condition) {
      Groupid
      Groupname
      GPTmodel
      MaxTokens
      Temperature
      Company {
        id
        name
        access
        user {
          nextToken
        }
        profile
        industry
        InterviewGroups {
          nextToken
        }
        dataFiles {
          nextToken
        }
        examinations {
          nextToken
        }
        swb {
          nextToken
        }
        rootsGroups {
          nextToken
        }
        meetings {
          nextToken
        }
        culture
        external
        internal
        foundation
        customer
        product
        operations
        people
        stepProfile
        stepInterviews
        stepData
        stepAnalysis
        stepFocus
        createdAt
        updatedAt
      }
      NameSpace
      Template {
        tempid
        name
        GPTmodel
        MaxTokens
        Temperature
        profile
        goal
        systemcontext
        instruction
        firstMessage
        instructions {
          nextToken
        }
        createdAt
        updatedAt
      }
      Interviews {
        items {
          intid
          summary
          conclusions
          active
          process
          status
          createdAt
          updatedAt
          interviewGroupInterviewsGroupid
          userInterviewsUserid
        }
        nextToken
      }
      createdAt
      updatedAt
      companyInterviewGroupsId
      interviewGroupTemplateTempid
    }
  }
`;
export const createTemplate = /* GraphQL */ `
  mutation CreateTemplate(
    $input: CreateTemplateInput!
    $condition: ModelTemplateConditionInput
  ) {
    createTemplate(input: $input, condition: $condition) {
      tempid
      name
      GPTmodel
      MaxTokens
      Temperature
      profile
      goal
      systemcontext
      instruction
      firstMessage
      instructions {
        items {
          insid
          content
          createdAt
          updatedAt
          templateInstructionsTempid
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateTemplate = /* GraphQL */ `
  mutation UpdateTemplate(
    $input: UpdateTemplateInput!
    $condition: ModelTemplateConditionInput
  ) {
    updateTemplate(input: $input, condition: $condition) {
      tempid
      name
      GPTmodel
      MaxTokens
      Temperature
      profile
      goal
      systemcontext
      instruction
      firstMessage
      instructions {
        items {
          insid
          content
          createdAt
          updatedAt
          templateInstructionsTempid
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteTemplate = /* GraphQL */ `
  mutation DeleteTemplate(
    $input: DeleteTemplateInput!
    $condition: ModelTemplateConditionInput
  ) {
    deleteTemplate(input: $input, condition: $condition) {
      tempid
      name
      GPTmodel
      MaxTokens
      Temperature
      profile
      goal
      systemcontext
      instruction
      firstMessage
      instructions {
        items {
          insid
          content
          createdAt
          updatedAt
          templateInstructionsTempid
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createInstructions = /* GraphQL */ `
  mutation CreateInstructions(
    $input: CreateInstructionsInput!
    $condition: ModelInstructionsConditionInput
  ) {
    createInstructions(input: $input, condition: $condition) {
      insid
      template {
        tempid
        name
        GPTmodel
        MaxTokens
        Temperature
        profile
        goal
        systemcontext
        instruction
        firstMessage
        instructions {
          nextToken
        }
        createdAt
        updatedAt
      }
      content
      createdAt
      updatedAt
      templateInstructionsTempid
    }
  }
`;
export const updateInstructions = /* GraphQL */ `
  mutation UpdateInstructions(
    $input: UpdateInstructionsInput!
    $condition: ModelInstructionsConditionInput
  ) {
    updateInstructions(input: $input, condition: $condition) {
      insid
      template {
        tempid
        name
        GPTmodel
        MaxTokens
        Temperature
        profile
        goal
        systemcontext
        instruction
        firstMessage
        instructions {
          nextToken
        }
        createdAt
        updatedAt
      }
      content
      createdAt
      updatedAt
      templateInstructionsTempid
    }
  }
`;
export const deleteInstructions = /* GraphQL */ `
  mutation DeleteInstructions(
    $input: DeleteInstructionsInput!
    $condition: ModelInstructionsConditionInput
  ) {
    deleteInstructions(input: $input, condition: $condition) {
      insid
      template {
        tempid
        name
        GPTmodel
        MaxTokens
        Temperature
        profile
        goal
        systemcontext
        instruction
        firstMessage
        instructions {
          nextToken
        }
        createdAt
        updatedAt
      }
      content
      createdAt
      updatedAt
      templateInstructionsTempid
    }
  }
`;
export const createInterview = /* GraphQL */ `
  mutation CreateInterview(
    $input: CreateInterviewInput!
    $condition: ModelInterviewConditionInput
  ) {
    createInterview(input: $input, condition: $condition) {
      intid
      user {
        userid
        username
        emailid
        permissions
        accessStrat360
        accessSWB
        accessRG
        role
        strategyRole
        jobFunction
        company {
          id
          name
          access
          profile
          industry
          culture
          external
          internal
          foundation
          customer
          product
          operations
          people
          stepProfile
          stepInterviews
          stepData
          stepAnalysis
          stepFocus
          createdAt
          updatedAt
        }
        interviews {
          nextToken
        }
        rgmembership {
          nextToken
        }
        createdAt
        updatedAt
        companyUserId
      }
      group {
        Groupid
        Groupname
        GPTmodel
        MaxTokens
        Temperature
        Company {
          id
          name
          access
          profile
          industry
          culture
          external
          internal
          foundation
          customer
          product
          operations
          people
          stepProfile
          stepInterviews
          stepData
          stepAnalysis
          stepFocus
          createdAt
          updatedAt
        }
        NameSpace
        Template {
          tempid
          name
          GPTmodel
          MaxTokens
          Temperature
          profile
          goal
          systemcontext
          instruction
          firstMessage
          createdAt
          updatedAt
        }
        Interviews {
          nextToken
        }
        createdAt
        updatedAt
        companyInterviewGroupsId
        interviewGroupTemplateTempid
      }
      summary
      conclusions
      conversation {
        items {
          logid
          chatsummary
          createdAt
          updatedAt
          interviewConversationIntid
        }
        nextToken
      }
      active
      process
      status
      createdAt
      updatedAt
      interviewGroupInterviewsGroupid
      userInterviewsUserid
    }
  }
`;
export const updateInterview = /* GraphQL */ `
  mutation UpdateInterview(
    $input: UpdateInterviewInput!
    $condition: ModelInterviewConditionInput
  ) {
    updateInterview(input: $input, condition: $condition) {
      intid
      user {
        userid
        username
        emailid
        permissions
        accessStrat360
        accessSWB
        accessRG
        role
        strategyRole
        jobFunction
        company {
          id
          name
          access
          profile
          industry
          culture
          external
          internal
          foundation
          customer
          product
          operations
          people
          stepProfile
          stepInterviews
          stepData
          stepAnalysis
          stepFocus
          createdAt
          updatedAt
        }
        interviews {
          nextToken
        }
        rgmembership {
          nextToken
        }
        createdAt
        updatedAt
        companyUserId
      }
      group {
        Groupid
        Groupname
        GPTmodel
        MaxTokens
        Temperature
        Company {
          id
          name
          access
          profile
          industry
          culture
          external
          internal
          foundation
          customer
          product
          operations
          people
          stepProfile
          stepInterviews
          stepData
          stepAnalysis
          stepFocus
          createdAt
          updatedAt
        }
        NameSpace
        Template {
          tempid
          name
          GPTmodel
          MaxTokens
          Temperature
          profile
          goal
          systemcontext
          instruction
          firstMessage
          createdAt
          updatedAt
        }
        Interviews {
          nextToken
        }
        createdAt
        updatedAt
        companyInterviewGroupsId
        interviewGroupTemplateTempid
      }
      summary
      conclusions
      conversation {
        items {
          logid
          chatsummary
          createdAt
          updatedAt
          interviewConversationIntid
        }
        nextToken
      }
      active
      process
      status
      createdAt
      updatedAt
      interviewGroupInterviewsGroupid
      userInterviewsUserid
    }
  }
`;
export const deleteInterview = /* GraphQL */ `
  mutation DeleteInterview(
    $input: DeleteInterviewInput!
    $condition: ModelInterviewConditionInput
  ) {
    deleteInterview(input: $input, condition: $condition) {
      intid
      user {
        userid
        username
        emailid
        permissions
        accessStrat360
        accessSWB
        accessRG
        role
        strategyRole
        jobFunction
        company {
          id
          name
          access
          profile
          industry
          culture
          external
          internal
          foundation
          customer
          product
          operations
          people
          stepProfile
          stepInterviews
          stepData
          stepAnalysis
          stepFocus
          createdAt
          updatedAt
        }
        interviews {
          nextToken
        }
        rgmembership {
          nextToken
        }
        createdAt
        updatedAt
        companyUserId
      }
      group {
        Groupid
        Groupname
        GPTmodel
        MaxTokens
        Temperature
        Company {
          id
          name
          access
          profile
          industry
          culture
          external
          internal
          foundation
          customer
          product
          operations
          people
          stepProfile
          stepInterviews
          stepData
          stepAnalysis
          stepFocus
          createdAt
          updatedAt
        }
        NameSpace
        Template {
          tempid
          name
          GPTmodel
          MaxTokens
          Temperature
          profile
          goal
          systemcontext
          instruction
          firstMessage
          createdAt
          updatedAt
        }
        Interviews {
          nextToken
        }
        createdAt
        updatedAt
        companyInterviewGroupsId
        interviewGroupTemplateTempid
      }
      summary
      conclusions
      conversation {
        items {
          logid
          chatsummary
          createdAt
          updatedAt
          interviewConversationIntid
        }
        nextToken
      }
      active
      process
      status
      createdAt
      updatedAt
      interviewGroupInterviewsGroupid
      userInterviewsUserid
    }
  }
`;
export const createChatLog = /* GraphQL */ `
  mutation CreateChatLog(
    $input: CreateChatLogInput!
    $condition: ModelChatLogConditionInput
  ) {
    createChatLog(input: $input, condition: $condition) {
      logid
      interview {
        intid
        user {
          userid
          username
          emailid
          permissions
          accessStrat360
          accessSWB
          accessRG
          role
          strategyRole
          jobFunction
          createdAt
          updatedAt
          companyUserId
        }
        group {
          Groupid
          Groupname
          GPTmodel
          MaxTokens
          Temperature
          NameSpace
          createdAt
          updatedAt
          companyInterviewGroupsId
          interviewGroupTemplateTempid
        }
        summary
        conclusions
        conversation {
          nextToken
        }
        active
        process
        status
        createdAt
        updatedAt
        interviewGroupInterviewsGroupid
        userInterviewsUserid
      }
      chatsummary
      messages {
        items {
          msgid
          content
          user
          createdAt
          updatedAt
          chatLogMessagesLogid
        }
        nextToken
      }
      createdAt
      updatedAt
      interviewConversationIntid
    }
  }
`;
export const updateChatLog = /* GraphQL */ `
  mutation UpdateChatLog(
    $input: UpdateChatLogInput!
    $condition: ModelChatLogConditionInput
  ) {
    updateChatLog(input: $input, condition: $condition) {
      logid
      interview {
        intid
        user {
          userid
          username
          emailid
          permissions
          accessStrat360
          accessSWB
          accessRG
          role
          strategyRole
          jobFunction
          createdAt
          updatedAt
          companyUserId
        }
        group {
          Groupid
          Groupname
          GPTmodel
          MaxTokens
          Temperature
          NameSpace
          createdAt
          updatedAt
          companyInterviewGroupsId
          interviewGroupTemplateTempid
        }
        summary
        conclusions
        conversation {
          nextToken
        }
        active
        process
        status
        createdAt
        updatedAt
        interviewGroupInterviewsGroupid
        userInterviewsUserid
      }
      chatsummary
      messages {
        items {
          msgid
          content
          user
          createdAt
          updatedAt
          chatLogMessagesLogid
        }
        nextToken
      }
      createdAt
      updatedAt
      interviewConversationIntid
    }
  }
`;
export const deleteChatLog = /* GraphQL */ `
  mutation DeleteChatLog(
    $input: DeleteChatLogInput!
    $condition: ModelChatLogConditionInput
  ) {
    deleteChatLog(input: $input, condition: $condition) {
      logid
      interview {
        intid
        user {
          userid
          username
          emailid
          permissions
          accessStrat360
          accessSWB
          accessRG
          role
          strategyRole
          jobFunction
          createdAt
          updatedAt
          companyUserId
        }
        group {
          Groupid
          Groupname
          GPTmodel
          MaxTokens
          Temperature
          NameSpace
          createdAt
          updatedAt
          companyInterviewGroupsId
          interviewGroupTemplateTempid
        }
        summary
        conclusions
        conversation {
          nextToken
        }
        active
        process
        status
        createdAt
        updatedAt
        interviewGroupInterviewsGroupid
        userInterviewsUserid
      }
      chatsummary
      messages {
        items {
          msgid
          content
          user
          createdAt
          updatedAt
          chatLogMessagesLogid
        }
        nextToken
      }
      createdAt
      updatedAt
      interviewConversationIntid
    }
  }
`;
export const createChatmessage = /* GraphQL */ `
  mutation CreateChatmessage(
    $input: CreateChatmessageInput!
    $condition: ModelChatmessageConditionInput
  ) {
    createChatmessage(input: $input, condition: $condition) {
      msgid
      chatLog {
        logid
        interview {
          intid
          summary
          conclusions
          active
          process
          status
          createdAt
          updatedAt
          interviewGroupInterviewsGroupid
          userInterviewsUserid
        }
        chatsummary
        messages {
          nextToken
        }
        createdAt
        updatedAt
        interviewConversationIntid
      }
      content
      user
      createdAt
      updatedAt
      chatLogMessagesLogid
    }
  }
`;
export const updateChatmessage = /* GraphQL */ `
  mutation UpdateChatmessage(
    $input: UpdateChatmessageInput!
    $condition: ModelChatmessageConditionInput
  ) {
    updateChatmessage(input: $input, condition: $condition) {
      msgid
      chatLog {
        logid
        interview {
          intid
          summary
          conclusions
          active
          process
          status
          createdAt
          updatedAt
          interviewGroupInterviewsGroupid
          userInterviewsUserid
        }
        chatsummary
        messages {
          nextToken
        }
        createdAt
        updatedAt
        interviewConversationIntid
      }
      content
      user
      createdAt
      updatedAt
      chatLogMessagesLogid
    }
  }
`;
export const deleteChatmessage = /* GraphQL */ `
  mutation DeleteChatmessage(
    $input: DeleteChatmessageInput!
    $condition: ModelChatmessageConditionInput
  ) {
    deleteChatmessage(input: $input, condition: $condition) {
      msgid
      chatLog {
        logid
        interview {
          intid
          summary
          conclusions
          active
          process
          status
          createdAt
          updatedAt
          interviewGroupInterviewsGroupid
          userInterviewsUserid
        }
        chatsummary
        messages {
          nextToken
        }
        createdAt
        updatedAt
        interviewConversationIntid
      }
      content
      user
      createdAt
      updatedAt
      chatLogMessagesLogid
    }
  }
`;
export const createDataFile = /* GraphQL */ `
  mutation CreateDataFile(
    $input: CreateDataFileInput!
    $condition: ModelDataFileConditionInput
  ) {
    createDataFile(input: $input, condition: $condition) {
      id
      image
      company {
        id
        name
        access
        user {
          nextToken
        }
        profile
        industry
        InterviewGroups {
          nextToken
        }
        dataFiles {
          nextToken
        }
        examinations {
          nextToken
        }
        swb {
          nextToken
        }
        rootsGroups {
          nextToken
        }
        meetings {
          nextToken
        }
        culture
        external
        internal
        foundation
        customer
        product
        operations
        people
        stepProfile
        stepInterviews
        stepData
        stepAnalysis
        stepFocus
        createdAt
        updatedAt
      }
      description
      reference
      summary
      themes
      insights
      relevance
      conclusions
      content
      embeddings {
        items {
          vectorid
          index
          namespace
          text
          id
          createdAt
          updatedAt
          dataFileEmbeddingsId
        }
        nextToken
      }
      createdAt
      updatedAt
      companyDataFilesId
    }
  }
`;
export const updateDataFile = /* GraphQL */ `
  mutation UpdateDataFile(
    $input: UpdateDataFileInput!
    $condition: ModelDataFileConditionInput
  ) {
    updateDataFile(input: $input, condition: $condition) {
      id
      image
      company {
        id
        name
        access
        user {
          nextToken
        }
        profile
        industry
        InterviewGroups {
          nextToken
        }
        dataFiles {
          nextToken
        }
        examinations {
          nextToken
        }
        swb {
          nextToken
        }
        rootsGroups {
          nextToken
        }
        meetings {
          nextToken
        }
        culture
        external
        internal
        foundation
        customer
        product
        operations
        people
        stepProfile
        stepInterviews
        stepData
        stepAnalysis
        stepFocus
        createdAt
        updatedAt
      }
      description
      reference
      summary
      themes
      insights
      relevance
      conclusions
      content
      embeddings {
        items {
          vectorid
          index
          namespace
          text
          id
          createdAt
          updatedAt
          dataFileEmbeddingsId
        }
        nextToken
      }
      createdAt
      updatedAt
      companyDataFilesId
    }
  }
`;
export const deleteDataFile = /* GraphQL */ `
  mutation DeleteDataFile(
    $input: DeleteDataFileInput!
    $condition: ModelDataFileConditionInput
  ) {
    deleteDataFile(input: $input, condition: $condition) {
      id
      image
      company {
        id
        name
        access
        user {
          nextToken
        }
        profile
        industry
        InterviewGroups {
          nextToken
        }
        dataFiles {
          nextToken
        }
        examinations {
          nextToken
        }
        swb {
          nextToken
        }
        rootsGroups {
          nextToken
        }
        meetings {
          nextToken
        }
        culture
        external
        internal
        foundation
        customer
        product
        operations
        people
        stepProfile
        stepInterviews
        stepData
        stepAnalysis
        stepFocus
        createdAt
        updatedAt
      }
      description
      reference
      summary
      themes
      insights
      relevance
      conclusions
      content
      embeddings {
        items {
          vectorid
          index
          namespace
          text
          id
          createdAt
          updatedAt
          dataFileEmbeddingsId
        }
        nextToken
      }
      createdAt
      updatedAt
      companyDataFilesId
    }
  }
`;
export const createEmbedding = /* GraphQL */ `
  mutation CreateEmbedding(
    $input: CreateEmbeddingInput!
    $condition: ModelEmbeddingConditionInput
  ) {
    createEmbedding(input: $input, condition: $condition) {
      vectorid
      index
      namespace
      text
      id
      createdAt
      updatedAt
      dataFileEmbeddingsId
    }
  }
`;
export const updateEmbedding = /* GraphQL */ `
  mutation UpdateEmbedding(
    $input: UpdateEmbeddingInput!
    $condition: ModelEmbeddingConditionInput
  ) {
    updateEmbedding(input: $input, condition: $condition) {
      vectorid
      index
      namespace
      text
      id
      createdAt
      updatedAt
      dataFileEmbeddingsId
    }
  }
`;
export const deleteEmbedding = /* GraphQL */ `
  mutation DeleteEmbedding(
    $input: DeleteEmbeddingInput!
    $condition: ModelEmbeddingConditionInput
  ) {
    deleteEmbedding(input: $input, condition: $condition) {
      vectorid
      index
      namespace
      text
      id
      createdAt
      updatedAt
      dataFileEmbeddingsId
    }
  }
`;
export const createCompany = /* GraphQL */ `
  mutation CreateCompany(
    $input: CreateCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    createCompany(input: $input, condition: $condition) {
      id
      name
      access
      user {
        items {
          userid
          username
          emailid
          permissions
          accessStrat360
          accessSWB
          accessRG
          role
          strategyRole
          jobFunction
          createdAt
          updatedAt
          companyUserId
        }
        nextToken
      }
      profile
      industry
      InterviewGroups {
        items {
          Groupid
          Groupname
          GPTmodel
          MaxTokens
          Temperature
          NameSpace
          createdAt
          updatedAt
          companyInterviewGroupsId
          interviewGroupTemplateTempid
        }
        nextToken
      }
      dataFiles {
        items {
          id
          image
          description
          reference
          summary
          themes
          insights
          relevance
          conclusions
          content
          createdAt
          updatedAt
          companyDataFilesId
        }
        nextToken
      }
      examinations {
        items {
          id
          name
          information
          result
          createdAt
          updatedAt
          companyExaminationsId
        }
        nextToken
      }
      swb {
        items {
          id
          name
          createdAt
          updatedAt
          companySwbId
        }
        nextToken
      }
      rootsGroups {
        items {
          id
          name
          createdAt
          updatedAt
          companyRootsGroupsId
          rootsGroupSwbId
        }
        nextToken
      }
      meetings {
        items {
          id
          name
          type
          meetingDate
          notes
          createdAt
          updatedAt
          companyMeetingsId
        }
        nextToken
      }
      culture
      external
      internal
      foundation
      customer
      product
      operations
      people
      stepProfile
      stepInterviews
      stepData
      stepAnalysis
      stepFocus
      createdAt
      updatedAt
    }
  }
`;
export const updateCompany = /* GraphQL */ `
  mutation UpdateCompany(
    $input: UpdateCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    updateCompany(input: $input, condition: $condition) {
      id
      name
      access
      user {
        items {
          userid
          username
          emailid
          permissions
          accessStrat360
          accessSWB
          accessRG
          role
          strategyRole
          jobFunction
          createdAt
          updatedAt
          companyUserId
        }
        nextToken
      }
      profile
      industry
      InterviewGroups {
        items {
          Groupid
          Groupname
          GPTmodel
          MaxTokens
          Temperature
          NameSpace
          createdAt
          updatedAt
          companyInterviewGroupsId
          interviewGroupTemplateTempid
        }
        nextToken
      }
      dataFiles {
        items {
          id
          image
          description
          reference
          summary
          themes
          insights
          relevance
          conclusions
          content
          createdAt
          updatedAt
          companyDataFilesId
        }
        nextToken
      }
      examinations {
        items {
          id
          name
          information
          result
          createdAt
          updatedAt
          companyExaminationsId
        }
        nextToken
      }
      swb {
        items {
          id
          name
          createdAt
          updatedAt
          companySwbId
        }
        nextToken
      }
      rootsGroups {
        items {
          id
          name
          createdAt
          updatedAt
          companyRootsGroupsId
          rootsGroupSwbId
        }
        nextToken
      }
      meetings {
        items {
          id
          name
          type
          meetingDate
          notes
          createdAt
          updatedAt
          companyMeetingsId
        }
        nextToken
      }
      culture
      external
      internal
      foundation
      customer
      product
      operations
      people
      stepProfile
      stepInterviews
      stepData
      stepAnalysis
      stepFocus
      createdAt
      updatedAt
    }
  }
`;
export const deleteCompany = /* GraphQL */ `
  mutation DeleteCompany(
    $input: DeleteCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    deleteCompany(input: $input, condition: $condition) {
      id
      name
      access
      user {
        items {
          userid
          username
          emailid
          permissions
          accessStrat360
          accessSWB
          accessRG
          role
          strategyRole
          jobFunction
          createdAt
          updatedAt
          companyUserId
        }
        nextToken
      }
      profile
      industry
      InterviewGroups {
        items {
          Groupid
          Groupname
          GPTmodel
          MaxTokens
          Temperature
          NameSpace
          createdAt
          updatedAt
          companyInterviewGroupsId
          interviewGroupTemplateTempid
        }
        nextToken
      }
      dataFiles {
        items {
          id
          image
          description
          reference
          summary
          themes
          insights
          relevance
          conclusions
          content
          createdAt
          updatedAt
          companyDataFilesId
        }
        nextToken
      }
      examinations {
        items {
          id
          name
          information
          result
          createdAt
          updatedAt
          companyExaminationsId
        }
        nextToken
      }
      swb {
        items {
          id
          name
          createdAt
          updatedAt
          companySwbId
        }
        nextToken
      }
      rootsGroups {
        items {
          id
          name
          createdAt
          updatedAt
          companyRootsGroupsId
          rootsGroupSwbId
        }
        nextToken
      }
      meetings {
        items {
          id
          name
          type
          meetingDate
          notes
          createdAt
          updatedAt
          companyMeetingsId
        }
        nextToken
      }
      culture
      external
      internal
      foundation
      customer
      product
      operations
      people
      stepProfile
      stepInterviews
      stepData
      stepAnalysis
      stepFocus
      createdAt
      updatedAt
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      userid
      username
      emailid
      permissions
      accessStrat360
      accessSWB
      accessRG
      role
      strategyRole
      jobFunction
      company {
        id
        name
        access
        user {
          nextToken
        }
        profile
        industry
        InterviewGroups {
          nextToken
        }
        dataFiles {
          nextToken
        }
        examinations {
          nextToken
        }
        swb {
          nextToken
        }
        rootsGroups {
          nextToken
        }
        meetings {
          nextToken
        }
        culture
        external
        internal
        foundation
        customer
        product
        operations
        people
        stepProfile
        stepInterviews
        stepData
        stepAnalysis
        stepFocus
        createdAt
        updatedAt
      }
      interviews {
        items {
          intid
          summary
          conclusions
          active
          process
          status
          createdAt
          updatedAt
          interviewGroupInterviewsGroupid
          userInterviewsUserid
        }
        nextToken
      }
      rgmembership {
        items {
          id
          rgId
          userId
          name
          createdAt
          updatedAt
          userRgmembershipUserid
          rootsGroupMembersId
        }
        nextToken
      }
      createdAt
      updatedAt
      companyUserId
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      userid
      username
      emailid
      permissions
      accessStrat360
      accessSWB
      accessRG
      role
      strategyRole
      jobFunction
      company {
        id
        name
        access
        user {
          nextToken
        }
        profile
        industry
        InterviewGroups {
          nextToken
        }
        dataFiles {
          nextToken
        }
        examinations {
          nextToken
        }
        swb {
          nextToken
        }
        rootsGroups {
          nextToken
        }
        meetings {
          nextToken
        }
        culture
        external
        internal
        foundation
        customer
        product
        operations
        people
        stepProfile
        stepInterviews
        stepData
        stepAnalysis
        stepFocus
        createdAt
        updatedAt
      }
      interviews {
        items {
          intid
          summary
          conclusions
          active
          process
          status
          createdAt
          updatedAt
          interviewGroupInterviewsGroupid
          userInterviewsUserid
        }
        nextToken
      }
      rgmembership {
        items {
          id
          rgId
          userId
          name
          createdAt
          updatedAt
          userRgmembershipUserid
          rootsGroupMembersId
        }
        nextToken
      }
      createdAt
      updatedAt
      companyUserId
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      userid
      username
      emailid
      permissions
      accessStrat360
      accessSWB
      accessRG
      role
      strategyRole
      jobFunction
      company {
        id
        name
        access
        user {
          nextToken
        }
        profile
        industry
        InterviewGroups {
          nextToken
        }
        dataFiles {
          nextToken
        }
        examinations {
          nextToken
        }
        swb {
          nextToken
        }
        rootsGroups {
          nextToken
        }
        meetings {
          nextToken
        }
        culture
        external
        internal
        foundation
        customer
        product
        operations
        people
        stepProfile
        stepInterviews
        stepData
        stepAnalysis
        stepFocus
        createdAt
        updatedAt
      }
      interviews {
        items {
          intid
          summary
          conclusions
          active
          process
          status
          createdAt
          updatedAt
          interviewGroupInterviewsGroupid
          userInterviewsUserid
        }
        nextToken
      }
      rgmembership {
        items {
          id
          rgId
          userId
          name
          createdAt
          updatedAt
          userRgmembershipUserid
          rootsGroupMembersId
        }
        nextToken
      }
      createdAt
      updatedAt
      companyUserId
    }
  }
`;
export const createExamination = /* GraphQL */ `
  mutation CreateExamination(
    $input: CreateExaminationInput!
    $condition: ModelExaminationConditionInput
  ) {
    createExamination(input: $input, condition: $condition) {
      id
      company {
        id
        name
        access
        user {
          nextToken
        }
        profile
        industry
        InterviewGroups {
          nextToken
        }
        dataFiles {
          nextToken
        }
        examinations {
          nextToken
        }
        swb {
          nextToken
        }
        rootsGroups {
          nextToken
        }
        meetings {
          nextToken
        }
        culture
        external
        internal
        foundation
        customer
        product
        operations
        people
        stepProfile
        stepInterviews
        stepData
        stepAnalysis
        stepFocus
        createdAt
        updatedAt
      }
      name
      information
      result
      createdAt
      updatedAt
      companyExaminationsId
    }
  }
`;
export const updateExamination = /* GraphQL */ `
  mutation UpdateExamination(
    $input: UpdateExaminationInput!
    $condition: ModelExaminationConditionInput
  ) {
    updateExamination(input: $input, condition: $condition) {
      id
      company {
        id
        name
        access
        user {
          nextToken
        }
        profile
        industry
        InterviewGroups {
          nextToken
        }
        dataFiles {
          nextToken
        }
        examinations {
          nextToken
        }
        swb {
          nextToken
        }
        rootsGroups {
          nextToken
        }
        meetings {
          nextToken
        }
        culture
        external
        internal
        foundation
        customer
        product
        operations
        people
        stepProfile
        stepInterviews
        stepData
        stepAnalysis
        stepFocus
        createdAt
        updatedAt
      }
      name
      information
      result
      createdAt
      updatedAt
      companyExaminationsId
    }
  }
`;
export const deleteExamination = /* GraphQL */ `
  mutation DeleteExamination(
    $input: DeleteExaminationInput!
    $condition: ModelExaminationConditionInput
  ) {
    deleteExamination(input: $input, condition: $condition) {
      id
      company {
        id
        name
        access
        user {
          nextToken
        }
        profile
        industry
        InterviewGroups {
          nextToken
        }
        dataFiles {
          nextToken
        }
        examinations {
          nextToken
        }
        swb {
          nextToken
        }
        rootsGroups {
          nextToken
        }
        meetings {
          nextToken
        }
        culture
        external
        internal
        foundation
        customer
        product
        operations
        people
        stepProfile
        stepInterviews
        stepData
        stepAnalysis
        stepFocus
        createdAt
        updatedAt
      }
      name
      information
      result
      createdAt
      updatedAt
      companyExaminationsId
    }
  }
`;
export const createSwb = /* GraphQL */ `
  mutation CreateSwb(
    $input: CreateSwbInput!
    $condition: ModelSwbConditionInput
  ) {
    createSwb(input: $input, condition: $condition) {
      id
      name
      company {
        id
        name
        access
        user {
          nextToken
        }
        profile
        industry
        InterviewGroups {
          nextToken
        }
        dataFiles {
          nextToken
        }
        examinations {
          nextToken
        }
        swb {
          nextToken
        }
        rootsGroups {
          nextToken
        }
        meetings {
          nextToken
        }
        culture
        external
        internal
        foundation
        customer
        product
        operations
        people
        stepProfile
        stepInterviews
        stepData
        stepAnalysis
        stepFocus
        createdAt
        updatedAt
      }
      blocks {
        items {
          id
          section
          order
          name
          color
          content
          summary
          contentinstruction
          summaryinstruction
          createdAt
          updatedAt
          swbBlocksId
        }
        nextToken
      }
      blockItems {
        items {
          id
          name
          type
          status
          description
          order
          createdAt
          updatedAt
          swbBlockItemsId
          blockBlockItemsId
          blockElementItemsId
        }
        nextToken
      }
      createdAt
      updatedAt
      companySwbId
    }
  }
`;
export const updateSwb = /* GraphQL */ `
  mutation UpdateSwb(
    $input: UpdateSwbInput!
    $condition: ModelSwbConditionInput
  ) {
    updateSwb(input: $input, condition: $condition) {
      id
      name
      company {
        id
        name
        access
        user {
          nextToken
        }
        profile
        industry
        InterviewGroups {
          nextToken
        }
        dataFiles {
          nextToken
        }
        examinations {
          nextToken
        }
        swb {
          nextToken
        }
        rootsGroups {
          nextToken
        }
        meetings {
          nextToken
        }
        culture
        external
        internal
        foundation
        customer
        product
        operations
        people
        stepProfile
        stepInterviews
        stepData
        stepAnalysis
        stepFocus
        createdAt
        updatedAt
      }
      blocks {
        items {
          id
          section
          order
          name
          color
          content
          summary
          contentinstruction
          summaryinstruction
          createdAt
          updatedAt
          swbBlocksId
        }
        nextToken
      }
      blockItems {
        items {
          id
          name
          type
          status
          description
          order
          createdAt
          updatedAt
          swbBlockItemsId
          blockBlockItemsId
          blockElementItemsId
        }
        nextToken
      }
      createdAt
      updatedAt
      companySwbId
    }
  }
`;
export const deleteSwb = /* GraphQL */ `
  mutation DeleteSwb(
    $input: DeleteSwbInput!
    $condition: ModelSwbConditionInput
  ) {
    deleteSwb(input: $input, condition: $condition) {
      id
      name
      company {
        id
        name
        access
        user {
          nextToken
        }
        profile
        industry
        InterviewGroups {
          nextToken
        }
        dataFiles {
          nextToken
        }
        examinations {
          nextToken
        }
        swb {
          nextToken
        }
        rootsGroups {
          nextToken
        }
        meetings {
          nextToken
        }
        culture
        external
        internal
        foundation
        customer
        product
        operations
        people
        stepProfile
        stepInterviews
        stepData
        stepAnalysis
        stepFocus
        createdAt
        updatedAt
      }
      blocks {
        items {
          id
          section
          order
          name
          color
          content
          summary
          contentinstruction
          summaryinstruction
          createdAt
          updatedAt
          swbBlocksId
        }
        nextToken
      }
      blockItems {
        items {
          id
          name
          type
          status
          description
          order
          createdAt
          updatedAt
          swbBlockItemsId
          blockBlockItemsId
          blockElementItemsId
        }
        nextToken
      }
      createdAt
      updatedAt
      companySwbId
    }
  }
`;
export const createBlock = /* GraphQL */ `
  mutation CreateBlock(
    $input: CreateBlockInput!
    $condition: ModelBlockConditionInput
  ) {
    createBlock(input: $input, condition: $condition) {
      id
      swb {
        id
        name
        company {
          id
          name
          access
          profile
          industry
          culture
          external
          internal
          foundation
          customer
          product
          operations
          people
          stepProfile
          stepInterviews
          stepData
          stepAnalysis
          stepFocus
          createdAt
          updatedAt
        }
        blocks {
          nextToken
        }
        blockItems {
          nextToken
        }
        createdAt
        updatedAt
        companySwbId
      }
      blockElements {
        items {
          id
          name
          order
          type
          createdAt
          updatedAt
          blockBlockElementsId
        }
        nextToken
      }
      blockItems {
        items {
          id
          name
          type
          status
          description
          order
          createdAt
          updatedAt
          swbBlockItemsId
          blockBlockItemsId
          blockElementItemsId
        }
        nextToken
      }
      section
      order
      name
      color
      content
      summary
      contentinstruction
      summaryinstruction
      createdAt
      updatedAt
      swbBlocksId
    }
  }
`;
export const updateBlock = /* GraphQL */ `
  mutation UpdateBlock(
    $input: UpdateBlockInput!
    $condition: ModelBlockConditionInput
  ) {
    updateBlock(input: $input, condition: $condition) {
      id
      swb {
        id
        name
        company {
          id
          name
          access
          profile
          industry
          culture
          external
          internal
          foundation
          customer
          product
          operations
          people
          stepProfile
          stepInterviews
          stepData
          stepAnalysis
          stepFocus
          createdAt
          updatedAt
        }
        blocks {
          nextToken
        }
        blockItems {
          nextToken
        }
        createdAt
        updatedAt
        companySwbId
      }
      blockElements {
        items {
          id
          name
          order
          type
          createdAt
          updatedAt
          blockBlockElementsId
        }
        nextToken
      }
      blockItems {
        items {
          id
          name
          type
          status
          description
          order
          createdAt
          updatedAt
          swbBlockItemsId
          blockBlockItemsId
          blockElementItemsId
        }
        nextToken
      }
      section
      order
      name
      color
      content
      summary
      contentinstruction
      summaryinstruction
      createdAt
      updatedAt
      swbBlocksId
    }
  }
`;
export const deleteBlock = /* GraphQL */ `
  mutation DeleteBlock(
    $input: DeleteBlockInput!
    $condition: ModelBlockConditionInput
  ) {
    deleteBlock(input: $input, condition: $condition) {
      id
      swb {
        id
        name
        company {
          id
          name
          access
          profile
          industry
          culture
          external
          internal
          foundation
          customer
          product
          operations
          people
          stepProfile
          stepInterviews
          stepData
          stepAnalysis
          stepFocus
          createdAt
          updatedAt
        }
        blocks {
          nextToken
        }
        blockItems {
          nextToken
        }
        createdAt
        updatedAt
        companySwbId
      }
      blockElements {
        items {
          id
          name
          order
          type
          createdAt
          updatedAt
          blockBlockElementsId
        }
        nextToken
      }
      blockItems {
        items {
          id
          name
          type
          status
          description
          order
          createdAt
          updatedAt
          swbBlockItemsId
          blockBlockItemsId
          blockElementItemsId
        }
        nextToken
      }
      section
      order
      name
      color
      content
      summary
      contentinstruction
      summaryinstruction
      createdAt
      updatedAt
      swbBlocksId
    }
  }
`;
export const createBlockElement = /* GraphQL */ `
  mutation CreateBlockElement(
    $input: CreateBlockElementInput!
    $condition: ModelBlockElementConditionInput
  ) {
    createBlockElement(input: $input, condition: $condition) {
      id
      block {
        id
        swb {
          id
          name
          createdAt
          updatedAt
          companySwbId
        }
        blockElements {
          nextToken
        }
        blockItems {
          nextToken
        }
        section
        order
        name
        color
        content
        summary
        contentinstruction
        summaryinstruction
        createdAt
        updatedAt
        swbBlocksId
      }
      name
      order
      type
      items {
        items {
          id
          name
          type
          status
          description
          order
          createdAt
          updatedAt
          swbBlockItemsId
          blockBlockItemsId
          blockElementItemsId
        }
        nextToken
      }
      createdAt
      updatedAt
      blockBlockElementsId
    }
  }
`;
export const updateBlockElement = /* GraphQL */ `
  mutation UpdateBlockElement(
    $input: UpdateBlockElementInput!
    $condition: ModelBlockElementConditionInput
  ) {
    updateBlockElement(input: $input, condition: $condition) {
      id
      block {
        id
        swb {
          id
          name
          createdAt
          updatedAt
          companySwbId
        }
        blockElements {
          nextToken
        }
        blockItems {
          nextToken
        }
        section
        order
        name
        color
        content
        summary
        contentinstruction
        summaryinstruction
        createdAt
        updatedAt
        swbBlocksId
      }
      name
      order
      type
      items {
        items {
          id
          name
          type
          status
          description
          order
          createdAt
          updatedAt
          swbBlockItemsId
          blockBlockItemsId
          blockElementItemsId
        }
        nextToken
      }
      createdAt
      updatedAt
      blockBlockElementsId
    }
  }
`;
export const deleteBlockElement = /* GraphQL */ `
  mutation DeleteBlockElement(
    $input: DeleteBlockElementInput!
    $condition: ModelBlockElementConditionInput
  ) {
    deleteBlockElement(input: $input, condition: $condition) {
      id
      block {
        id
        swb {
          id
          name
          createdAt
          updatedAt
          companySwbId
        }
        blockElements {
          nextToken
        }
        blockItems {
          nextToken
        }
        section
        order
        name
        color
        content
        summary
        contentinstruction
        summaryinstruction
        createdAt
        updatedAt
        swbBlocksId
      }
      name
      order
      type
      items {
        items {
          id
          name
          type
          status
          description
          order
          createdAt
          updatedAt
          swbBlockItemsId
          blockBlockItemsId
          blockElementItemsId
        }
        nextToken
      }
      createdAt
      updatedAt
      blockBlockElementsId
    }
  }
`;
export const createBlockItem = /* GraphQL */ `
  mutation CreateBlockItem(
    $input: CreateBlockItemInput!
    $condition: ModelBlockItemConditionInput
  ) {
    createBlockItem(input: $input, condition: $condition) {
      id
      blockElement {
        id
        block {
          id
          section
          order
          name
          color
          content
          summary
          contentinstruction
          summaryinstruction
          createdAt
          updatedAt
          swbBlocksId
        }
        name
        order
        type
        items {
          nextToken
        }
        createdAt
        updatedAt
        blockBlockElementsId
      }
      block {
        id
        swb {
          id
          name
          createdAt
          updatedAt
          companySwbId
        }
        blockElements {
          nextToken
        }
        blockItems {
          nextToken
        }
        section
        order
        name
        color
        content
        summary
        contentinstruction
        summaryinstruction
        createdAt
        updatedAt
        swbBlocksId
      }
      swb {
        id
        name
        company {
          id
          name
          access
          profile
          industry
          culture
          external
          internal
          foundation
          customer
          product
          operations
          people
          stepProfile
          stepInterviews
          stepData
          stepAnalysis
          stepFocus
          createdAt
          updatedAt
        }
        blocks {
          nextToken
        }
        blockItems {
          nextToken
        }
        createdAt
        updatedAt
        companySwbId
      }
      name
      type
      status
      description
      order
      createdAt
      updatedAt
      swbBlockItemsId
      blockBlockItemsId
      blockElementItemsId
    }
  }
`;
export const updateBlockItem = /* GraphQL */ `
  mutation UpdateBlockItem(
    $input: UpdateBlockItemInput!
    $condition: ModelBlockItemConditionInput
  ) {
    updateBlockItem(input: $input, condition: $condition) {
      id
      blockElement {
        id
        block {
          id
          section
          order
          name
          color
          content
          summary
          contentinstruction
          summaryinstruction
          createdAt
          updatedAt
          swbBlocksId
        }
        name
        order
        type
        items {
          nextToken
        }
        createdAt
        updatedAt
        blockBlockElementsId
      }
      block {
        id
        swb {
          id
          name
          createdAt
          updatedAt
          companySwbId
        }
        blockElements {
          nextToken
        }
        blockItems {
          nextToken
        }
        section
        order
        name
        color
        content
        summary
        contentinstruction
        summaryinstruction
        createdAt
        updatedAt
        swbBlocksId
      }
      swb {
        id
        name
        company {
          id
          name
          access
          profile
          industry
          culture
          external
          internal
          foundation
          customer
          product
          operations
          people
          stepProfile
          stepInterviews
          stepData
          stepAnalysis
          stepFocus
          createdAt
          updatedAt
        }
        blocks {
          nextToken
        }
        blockItems {
          nextToken
        }
        createdAt
        updatedAt
        companySwbId
      }
      name
      type
      status
      description
      order
      createdAt
      updatedAt
      swbBlockItemsId
      blockBlockItemsId
      blockElementItemsId
    }
  }
`;
export const deleteBlockItem = /* GraphQL */ `
  mutation DeleteBlockItem(
    $input: DeleteBlockItemInput!
    $condition: ModelBlockItemConditionInput
  ) {
    deleteBlockItem(input: $input, condition: $condition) {
      id
      blockElement {
        id
        block {
          id
          section
          order
          name
          color
          content
          summary
          contentinstruction
          summaryinstruction
          createdAt
          updatedAt
          swbBlocksId
        }
        name
        order
        type
        items {
          nextToken
        }
        createdAt
        updatedAt
        blockBlockElementsId
      }
      block {
        id
        swb {
          id
          name
          createdAt
          updatedAt
          companySwbId
        }
        blockElements {
          nextToken
        }
        blockItems {
          nextToken
        }
        section
        order
        name
        color
        content
        summary
        contentinstruction
        summaryinstruction
        createdAt
        updatedAt
        swbBlocksId
      }
      swb {
        id
        name
        company {
          id
          name
          access
          profile
          industry
          culture
          external
          internal
          foundation
          customer
          product
          operations
          people
          stepProfile
          stepInterviews
          stepData
          stepAnalysis
          stepFocus
          createdAt
          updatedAt
        }
        blocks {
          nextToken
        }
        blockItems {
          nextToken
        }
        createdAt
        updatedAt
        companySwbId
      }
      name
      type
      status
      description
      order
      createdAt
      updatedAt
      swbBlockItemsId
      blockBlockItemsId
      blockElementItemsId
    }
  }
`;
export const createRootsGroup = /* GraphQL */ `
  mutation CreateRootsGroup(
    $input: CreateRootsGroupInput!
    $condition: ModelRootsGroupConditionInput
  ) {
    createRootsGroup(input: $input, condition: $condition) {
      id
      name
      actions {
        items {
          id
          name
          status
          createdAt
          updatedAt
          rootsGroupActionsId
          actionOwnerUserid
          actionCoordinatorUserid
        }
        nextToken
      }
      outcomes {
        items {
          id
          name
          blockItemId
          dueDate
          metric
          metricType
          numericalTarget
          numericalProgress
          qaulityTarget
          qualityProgress
          createdAt
          updatedAt
          rootsGroupOutcomesId
        }
        nextToken
      }
      swb {
        id
        name
        company {
          id
          name
          access
          profile
          industry
          culture
          external
          internal
          foundation
          customer
          product
          operations
          people
          stepProfile
          stepInterviews
          stepData
          stepAnalysis
          stepFocus
          createdAt
          updatedAt
        }
        blocks {
          nextToken
        }
        blockItems {
          nextToken
        }
        createdAt
        updatedAt
        companySwbId
      }
      company {
        id
        name
        access
        user {
          nextToken
        }
        profile
        industry
        InterviewGroups {
          nextToken
        }
        dataFiles {
          nextToken
        }
        examinations {
          nextToken
        }
        swb {
          nextToken
        }
        rootsGroups {
          nextToken
        }
        meetings {
          nextToken
        }
        culture
        external
        internal
        foundation
        customer
        product
        operations
        people
        stepProfile
        stepInterviews
        stepData
        stepAnalysis
        stepFocus
        createdAt
        updatedAt
      }
      members {
        items {
          id
          rgId
          userId
          name
          createdAt
          updatedAt
          userRgmembershipUserid
          rootsGroupMembersId
        }
        nextToken
      }
      createdAt
      updatedAt
      companyRootsGroupsId
      rootsGroupSwbId
    }
  }
`;
export const updateRootsGroup = /* GraphQL */ `
  mutation UpdateRootsGroup(
    $input: UpdateRootsGroupInput!
    $condition: ModelRootsGroupConditionInput
  ) {
    updateRootsGroup(input: $input, condition: $condition) {
      id
      name
      actions {
        items {
          id
          name
          status
          createdAt
          updatedAt
          rootsGroupActionsId
          actionOwnerUserid
          actionCoordinatorUserid
        }
        nextToken
      }
      outcomes {
        items {
          id
          name
          blockItemId
          dueDate
          metric
          metricType
          numericalTarget
          numericalProgress
          qaulityTarget
          qualityProgress
          createdAt
          updatedAt
          rootsGroupOutcomesId
        }
        nextToken
      }
      swb {
        id
        name
        company {
          id
          name
          access
          profile
          industry
          culture
          external
          internal
          foundation
          customer
          product
          operations
          people
          stepProfile
          stepInterviews
          stepData
          stepAnalysis
          stepFocus
          createdAt
          updatedAt
        }
        blocks {
          nextToken
        }
        blockItems {
          nextToken
        }
        createdAt
        updatedAt
        companySwbId
      }
      company {
        id
        name
        access
        user {
          nextToken
        }
        profile
        industry
        InterviewGroups {
          nextToken
        }
        dataFiles {
          nextToken
        }
        examinations {
          nextToken
        }
        swb {
          nextToken
        }
        rootsGroups {
          nextToken
        }
        meetings {
          nextToken
        }
        culture
        external
        internal
        foundation
        customer
        product
        operations
        people
        stepProfile
        stepInterviews
        stepData
        stepAnalysis
        stepFocus
        createdAt
        updatedAt
      }
      members {
        items {
          id
          rgId
          userId
          name
          createdAt
          updatedAt
          userRgmembershipUserid
          rootsGroupMembersId
        }
        nextToken
      }
      createdAt
      updatedAt
      companyRootsGroupsId
      rootsGroupSwbId
    }
  }
`;
export const deleteRootsGroup = /* GraphQL */ `
  mutation DeleteRootsGroup(
    $input: DeleteRootsGroupInput!
    $condition: ModelRootsGroupConditionInput
  ) {
    deleteRootsGroup(input: $input, condition: $condition) {
      id
      name
      actions {
        items {
          id
          name
          status
          createdAt
          updatedAt
          rootsGroupActionsId
          actionOwnerUserid
          actionCoordinatorUserid
        }
        nextToken
      }
      outcomes {
        items {
          id
          name
          blockItemId
          dueDate
          metric
          metricType
          numericalTarget
          numericalProgress
          qaulityTarget
          qualityProgress
          createdAt
          updatedAt
          rootsGroupOutcomesId
        }
        nextToken
      }
      swb {
        id
        name
        company {
          id
          name
          access
          profile
          industry
          culture
          external
          internal
          foundation
          customer
          product
          operations
          people
          stepProfile
          stepInterviews
          stepData
          stepAnalysis
          stepFocus
          createdAt
          updatedAt
        }
        blocks {
          nextToken
        }
        blockItems {
          nextToken
        }
        createdAt
        updatedAt
        companySwbId
      }
      company {
        id
        name
        access
        user {
          nextToken
        }
        profile
        industry
        InterviewGroups {
          nextToken
        }
        dataFiles {
          nextToken
        }
        examinations {
          nextToken
        }
        swb {
          nextToken
        }
        rootsGroups {
          nextToken
        }
        meetings {
          nextToken
        }
        culture
        external
        internal
        foundation
        customer
        product
        operations
        people
        stepProfile
        stepInterviews
        stepData
        stepAnalysis
        stepFocus
        createdAt
        updatedAt
      }
      members {
        items {
          id
          rgId
          userId
          name
          createdAt
          updatedAt
          userRgmembershipUserid
          rootsGroupMembersId
        }
        nextToken
      }
      createdAt
      updatedAt
      companyRootsGroupsId
      rootsGroupSwbId
    }
  }
`;
export const createRgmember = /* GraphQL */ `
  mutation CreateRgmember(
    $input: CreateRgmemberInput!
    $condition: ModelRgmemberConditionInput
  ) {
    createRgmember(input: $input, condition: $condition) {
      id
      rgId
      userId
      group {
        id
        name
        actions {
          nextToken
        }
        outcomes {
          nextToken
        }
        swb {
          id
          name
          createdAt
          updatedAt
          companySwbId
        }
        company {
          id
          name
          access
          profile
          industry
          culture
          external
          internal
          foundation
          customer
          product
          operations
          people
          stepProfile
          stepInterviews
          stepData
          stepAnalysis
          stepFocus
          createdAt
          updatedAt
        }
        members {
          nextToken
        }
        createdAt
        updatedAt
        companyRootsGroupsId
        rootsGroupSwbId
      }
      user {
        userid
        username
        emailid
        permissions
        accessStrat360
        accessSWB
        accessRG
        role
        strategyRole
        jobFunction
        company {
          id
          name
          access
          profile
          industry
          culture
          external
          internal
          foundation
          customer
          product
          operations
          people
          stepProfile
          stepInterviews
          stepData
          stepAnalysis
          stepFocus
          createdAt
          updatedAt
        }
        interviews {
          nextToken
        }
        rgmembership {
          nextToken
        }
        createdAt
        updatedAt
        companyUserId
      }
      name
      createdAt
      updatedAt
      userRgmembershipUserid
      rootsGroupMembersId
    }
  }
`;
export const updateRgmember = /* GraphQL */ `
  mutation UpdateRgmember(
    $input: UpdateRgmemberInput!
    $condition: ModelRgmemberConditionInput
  ) {
    updateRgmember(input: $input, condition: $condition) {
      id
      rgId
      userId
      group {
        id
        name
        actions {
          nextToken
        }
        outcomes {
          nextToken
        }
        swb {
          id
          name
          createdAt
          updatedAt
          companySwbId
        }
        company {
          id
          name
          access
          profile
          industry
          culture
          external
          internal
          foundation
          customer
          product
          operations
          people
          stepProfile
          stepInterviews
          stepData
          stepAnalysis
          stepFocus
          createdAt
          updatedAt
        }
        members {
          nextToken
        }
        createdAt
        updatedAt
        companyRootsGroupsId
        rootsGroupSwbId
      }
      user {
        userid
        username
        emailid
        permissions
        accessStrat360
        accessSWB
        accessRG
        role
        strategyRole
        jobFunction
        company {
          id
          name
          access
          profile
          industry
          culture
          external
          internal
          foundation
          customer
          product
          operations
          people
          stepProfile
          stepInterviews
          stepData
          stepAnalysis
          stepFocus
          createdAt
          updatedAt
        }
        interviews {
          nextToken
        }
        rgmembership {
          nextToken
        }
        createdAt
        updatedAt
        companyUserId
      }
      name
      createdAt
      updatedAt
      userRgmembershipUserid
      rootsGroupMembersId
    }
  }
`;
export const deleteRgmember = /* GraphQL */ `
  mutation DeleteRgmember(
    $input: DeleteRgmemberInput!
    $condition: ModelRgmemberConditionInput
  ) {
    deleteRgmember(input: $input, condition: $condition) {
      id
      rgId
      userId
      group {
        id
        name
        actions {
          nextToken
        }
        outcomes {
          nextToken
        }
        swb {
          id
          name
          createdAt
          updatedAt
          companySwbId
        }
        company {
          id
          name
          access
          profile
          industry
          culture
          external
          internal
          foundation
          customer
          product
          operations
          people
          stepProfile
          stepInterviews
          stepData
          stepAnalysis
          stepFocus
          createdAt
          updatedAt
        }
        members {
          nextToken
        }
        createdAt
        updatedAt
        companyRootsGroupsId
        rootsGroupSwbId
      }
      user {
        userid
        username
        emailid
        permissions
        accessStrat360
        accessSWB
        accessRG
        role
        strategyRole
        jobFunction
        company {
          id
          name
          access
          profile
          industry
          culture
          external
          internal
          foundation
          customer
          product
          operations
          people
          stepProfile
          stepInterviews
          stepData
          stepAnalysis
          stepFocus
          createdAt
          updatedAt
        }
        interviews {
          nextToken
        }
        rgmembership {
          nextToken
        }
        createdAt
        updatedAt
        companyUserId
      }
      name
      createdAt
      updatedAt
      userRgmembershipUserid
      rootsGroupMembersId
    }
  }
`;
export const createOutcome = /* GraphQL */ `
  mutation CreateOutcome(
    $input: CreateOutcomeInput!
    $condition: ModelOutcomeConditionInput
  ) {
    createOutcome(input: $input, condition: $condition) {
      id
      name
      rootsGroup {
        id
        name
        actions {
          nextToken
        }
        outcomes {
          nextToken
        }
        swb {
          id
          name
          createdAt
          updatedAt
          companySwbId
        }
        company {
          id
          name
          access
          profile
          industry
          culture
          external
          internal
          foundation
          customer
          product
          operations
          people
          stepProfile
          stepInterviews
          stepData
          stepAnalysis
          stepFocus
          createdAt
          updatedAt
        }
        members {
          nextToken
        }
        createdAt
        updatedAt
        companyRootsGroupsId
        rootsGroupSwbId
      }
      blockItemId
      dueDate
      metric
      metricType
      numericalTarget
      numericalProgress
      qaulityTarget
      qualityProgress
      createdAt
      updatedAt
      rootsGroupOutcomesId
    }
  }
`;
export const updateOutcome = /* GraphQL */ `
  mutation UpdateOutcome(
    $input: UpdateOutcomeInput!
    $condition: ModelOutcomeConditionInput
  ) {
    updateOutcome(input: $input, condition: $condition) {
      id
      name
      rootsGroup {
        id
        name
        actions {
          nextToken
        }
        outcomes {
          nextToken
        }
        swb {
          id
          name
          createdAt
          updatedAt
          companySwbId
        }
        company {
          id
          name
          access
          profile
          industry
          culture
          external
          internal
          foundation
          customer
          product
          operations
          people
          stepProfile
          stepInterviews
          stepData
          stepAnalysis
          stepFocus
          createdAt
          updatedAt
        }
        members {
          nextToken
        }
        createdAt
        updatedAt
        companyRootsGroupsId
        rootsGroupSwbId
      }
      blockItemId
      dueDate
      metric
      metricType
      numericalTarget
      numericalProgress
      qaulityTarget
      qualityProgress
      createdAt
      updatedAt
      rootsGroupOutcomesId
    }
  }
`;
export const deleteOutcome = /* GraphQL */ `
  mutation DeleteOutcome(
    $input: DeleteOutcomeInput!
    $condition: ModelOutcomeConditionInput
  ) {
    deleteOutcome(input: $input, condition: $condition) {
      id
      name
      rootsGroup {
        id
        name
        actions {
          nextToken
        }
        outcomes {
          nextToken
        }
        swb {
          id
          name
          createdAt
          updatedAt
          companySwbId
        }
        company {
          id
          name
          access
          profile
          industry
          culture
          external
          internal
          foundation
          customer
          product
          operations
          people
          stepProfile
          stepInterviews
          stepData
          stepAnalysis
          stepFocus
          createdAt
          updatedAt
        }
        members {
          nextToken
        }
        createdAt
        updatedAt
        companyRootsGroupsId
        rootsGroupSwbId
      }
      blockItemId
      dueDate
      metric
      metricType
      numericalTarget
      numericalProgress
      qaulityTarget
      qualityProgress
      createdAt
      updatedAt
      rootsGroupOutcomesId
    }
  }
`;
export const createAction = /* GraphQL */ `
  mutation CreateAction(
    $input: CreateActionInput!
    $condition: ModelActionConditionInput
  ) {
    createAction(input: $input, condition: $condition) {
      id
      name
      rootsGroup {
        id
        name
        actions {
          nextToken
        }
        outcomes {
          nextToken
        }
        swb {
          id
          name
          createdAt
          updatedAt
          companySwbId
        }
        company {
          id
          name
          access
          profile
          industry
          culture
          external
          internal
          foundation
          customer
          product
          operations
          people
          stepProfile
          stepInterviews
          stepData
          stepAnalysis
          stepFocus
          createdAt
          updatedAt
        }
        members {
          nextToken
        }
        createdAt
        updatedAt
        companyRootsGroupsId
        rootsGroupSwbId
      }
      owner {
        userid
        username
        emailid
        permissions
        accessStrat360
        accessSWB
        accessRG
        role
        strategyRole
        jobFunction
        company {
          id
          name
          access
          profile
          industry
          culture
          external
          internal
          foundation
          customer
          product
          operations
          people
          stepProfile
          stepInterviews
          stepData
          stepAnalysis
          stepFocus
          createdAt
          updatedAt
        }
        interviews {
          nextToken
        }
        rgmembership {
          nextToken
        }
        createdAt
        updatedAt
        companyUserId
      }
      coordinator {
        userid
        username
        emailid
        permissions
        accessStrat360
        accessSWB
        accessRG
        role
        strategyRole
        jobFunction
        company {
          id
          name
          access
          profile
          industry
          culture
          external
          internal
          foundation
          customer
          product
          operations
          people
          stepProfile
          stepInterviews
          stepData
          stepAnalysis
          stepFocus
          createdAt
          updatedAt
        }
        interviews {
          nextToken
        }
        rgmembership {
          nextToken
        }
        createdAt
        updatedAt
        companyUserId
      }
      status
      createdAt
      updatedAt
      rootsGroupActionsId
      actionOwnerUserid
      actionCoordinatorUserid
    }
  }
`;
export const updateAction = /* GraphQL */ `
  mutation UpdateAction(
    $input: UpdateActionInput!
    $condition: ModelActionConditionInput
  ) {
    updateAction(input: $input, condition: $condition) {
      id
      name
      rootsGroup {
        id
        name
        actions {
          nextToken
        }
        outcomes {
          nextToken
        }
        swb {
          id
          name
          createdAt
          updatedAt
          companySwbId
        }
        company {
          id
          name
          access
          profile
          industry
          culture
          external
          internal
          foundation
          customer
          product
          operations
          people
          stepProfile
          stepInterviews
          stepData
          stepAnalysis
          stepFocus
          createdAt
          updatedAt
        }
        members {
          nextToken
        }
        createdAt
        updatedAt
        companyRootsGroupsId
        rootsGroupSwbId
      }
      owner {
        userid
        username
        emailid
        permissions
        accessStrat360
        accessSWB
        accessRG
        role
        strategyRole
        jobFunction
        company {
          id
          name
          access
          profile
          industry
          culture
          external
          internal
          foundation
          customer
          product
          operations
          people
          stepProfile
          stepInterviews
          stepData
          stepAnalysis
          stepFocus
          createdAt
          updatedAt
        }
        interviews {
          nextToken
        }
        rgmembership {
          nextToken
        }
        createdAt
        updatedAt
        companyUserId
      }
      coordinator {
        userid
        username
        emailid
        permissions
        accessStrat360
        accessSWB
        accessRG
        role
        strategyRole
        jobFunction
        company {
          id
          name
          access
          profile
          industry
          culture
          external
          internal
          foundation
          customer
          product
          operations
          people
          stepProfile
          stepInterviews
          stepData
          stepAnalysis
          stepFocus
          createdAt
          updatedAt
        }
        interviews {
          nextToken
        }
        rgmembership {
          nextToken
        }
        createdAt
        updatedAt
        companyUserId
      }
      status
      createdAt
      updatedAt
      rootsGroupActionsId
      actionOwnerUserid
      actionCoordinatorUserid
    }
  }
`;
export const deleteAction = /* GraphQL */ `
  mutation DeleteAction(
    $input: DeleteActionInput!
    $condition: ModelActionConditionInput
  ) {
    deleteAction(input: $input, condition: $condition) {
      id
      name
      rootsGroup {
        id
        name
        actions {
          nextToken
        }
        outcomes {
          nextToken
        }
        swb {
          id
          name
          createdAt
          updatedAt
          companySwbId
        }
        company {
          id
          name
          access
          profile
          industry
          culture
          external
          internal
          foundation
          customer
          product
          operations
          people
          stepProfile
          stepInterviews
          stepData
          stepAnalysis
          stepFocus
          createdAt
          updatedAt
        }
        members {
          nextToken
        }
        createdAt
        updatedAt
        companyRootsGroupsId
        rootsGroupSwbId
      }
      owner {
        userid
        username
        emailid
        permissions
        accessStrat360
        accessSWB
        accessRG
        role
        strategyRole
        jobFunction
        company {
          id
          name
          access
          profile
          industry
          culture
          external
          internal
          foundation
          customer
          product
          operations
          people
          stepProfile
          stepInterviews
          stepData
          stepAnalysis
          stepFocus
          createdAt
          updatedAt
        }
        interviews {
          nextToken
        }
        rgmembership {
          nextToken
        }
        createdAt
        updatedAt
        companyUserId
      }
      coordinator {
        userid
        username
        emailid
        permissions
        accessStrat360
        accessSWB
        accessRG
        role
        strategyRole
        jobFunction
        company {
          id
          name
          access
          profile
          industry
          culture
          external
          internal
          foundation
          customer
          product
          operations
          people
          stepProfile
          stepInterviews
          stepData
          stepAnalysis
          stepFocus
          createdAt
          updatedAt
        }
        interviews {
          nextToken
        }
        rgmembership {
          nextToken
        }
        createdAt
        updatedAt
        companyUserId
      }
      status
      createdAt
      updatedAt
      rootsGroupActionsId
      actionOwnerUserid
      actionCoordinatorUserid
    }
  }
`;
export const createPrompt = /* GraphQL */ `
  mutation CreatePrompt(
    $input: CreatePromptInput!
    $condition: ModelPromptConditionInput
  ) {
    createPrompt(input: $input, condition: $condition) {
      id
      interviewSummary
      intsummarycult
      intsummarysa
      intsummaryfound
      intsummaryfocus
      cultureShort
      cultureFull
      externalFull
      externalShort
      internalFull
      internalShort
      foundationShort
      foundationFull
      customerFull
      customerShort
      productFull
      productShort
      operationsFull
      operationsShort
      peopleFull
      peopleShort
      createdAt
      updatedAt
    }
  }
`;
export const updatePrompt = /* GraphQL */ `
  mutation UpdatePrompt(
    $input: UpdatePromptInput!
    $condition: ModelPromptConditionInput
  ) {
    updatePrompt(input: $input, condition: $condition) {
      id
      interviewSummary
      intsummarycult
      intsummarysa
      intsummaryfound
      intsummaryfocus
      cultureShort
      cultureFull
      externalFull
      externalShort
      internalFull
      internalShort
      foundationShort
      foundationFull
      customerFull
      customerShort
      productFull
      productShort
      operationsFull
      operationsShort
      peopleFull
      peopleShort
      createdAt
      updatedAt
    }
  }
`;
export const deletePrompt = /* GraphQL */ `
  mutation DeletePrompt(
    $input: DeletePromptInput!
    $condition: ModelPromptConditionInput
  ) {
    deletePrompt(input: $input, condition: $condition) {
      id
      interviewSummary
      intsummarycult
      intsummarysa
      intsummaryfound
      intsummaryfocus
      cultureShort
      cultureFull
      externalFull
      externalShort
      internalFull
      internalShort
      foundationShort
      foundationFull
      customerFull
      customerShort
      productFull
      productShort
      operationsFull
      operationsShort
      peopleFull
      peopleShort
      createdAt
      updatedAt
    }
  }
`;
export const createMeeting = /* GraphQL */ `
  mutation CreateMeeting(
    $input: CreateMeetingInput!
    $condition: ModelMeetingConditionInput
  ) {
    createMeeting(input: $input, condition: $condition) {
      id
      name
      type
      company {
        id
        name
        access
        user {
          nextToken
        }
        profile
        industry
        InterviewGroups {
          nextToken
        }
        dataFiles {
          nextToken
        }
        examinations {
          nextToken
        }
        swb {
          nextToken
        }
        rootsGroups {
          nextToken
        }
        meetings {
          nextToken
        }
        culture
        external
        internal
        foundation
        customer
        product
        operations
        people
        stepProfile
        stepInterviews
        stepData
        stepAnalysis
        stepFocus
        createdAt
        updatedAt
      }
      meetingDate
      notes
      agenda {
        items {
          id
          name
          description
          status
          createdAt
          updatedAt
          meetingAgendaId
        }
        nextToken
      }
      createdAt
      updatedAt
      companyMeetingsId
    }
  }
`;
export const updateMeeting = /* GraphQL */ `
  mutation UpdateMeeting(
    $input: UpdateMeetingInput!
    $condition: ModelMeetingConditionInput
  ) {
    updateMeeting(input: $input, condition: $condition) {
      id
      name
      type
      company {
        id
        name
        access
        user {
          nextToken
        }
        profile
        industry
        InterviewGroups {
          nextToken
        }
        dataFiles {
          nextToken
        }
        examinations {
          nextToken
        }
        swb {
          nextToken
        }
        rootsGroups {
          nextToken
        }
        meetings {
          nextToken
        }
        culture
        external
        internal
        foundation
        customer
        product
        operations
        people
        stepProfile
        stepInterviews
        stepData
        stepAnalysis
        stepFocus
        createdAt
        updatedAt
      }
      meetingDate
      notes
      agenda {
        items {
          id
          name
          description
          status
          createdAt
          updatedAt
          meetingAgendaId
        }
        nextToken
      }
      createdAt
      updatedAt
      companyMeetingsId
    }
  }
`;
export const deleteMeeting = /* GraphQL */ `
  mutation DeleteMeeting(
    $input: DeleteMeetingInput!
    $condition: ModelMeetingConditionInput
  ) {
    deleteMeeting(input: $input, condition: $condition) {
      id
      name
      type
      company {
        id
        name
        access
        user {
          nextToken
        }
        profile
        industry
        InterviewGroups {
          nextToken
        }
        dataFiles {
          nextToken
        }
        examinations {
          nextToken
        }
        swb {
          nextToken
        }
        rootsGroups {
          nextToken
        }
        meetings {
          nextToken
        }
        culture
        external
        internal
        foundation
        customer
        product
        operations
        people
        stepProfile
        stepInterviews
        stepData
        stepAnalysis
        stepFocus
        createdAt
        updatedAt
      }
      meetingDate
      notes
      agenda {
        items {
          id
          name
          description
          status
          createdAt
          updatedAt
          meetingAgendaId
        }
        nextToken
      }
      createdAt
      updatedAt
      companyMeetingsId
    }
  }
`;
export const createAgendaItem = /* GraphQL */ `
  mutation CreateAgendaItem(
    $input: CreateAgendaItemInput!
    $condition: ModelAgendaItemConditionInput
  ) {
    createAgendaItem(input: $input, condition: $condition) {
      id
      name
      description
      status
      meeting {
        id
        name
        type
        company {
          id
          name
          access
          profile
          industry
          culture
          external
          internal
          foundation
          customer
          product
          operations
          people
          stepProfile
          stepInterviews
          stepData
          stepAnalysis
          stepFocus
          createdAt
          updatedAt
        }
        meetingDate
        notes
        agenda {
          nextToken
        }
        createdAt
        updatedAt
        companyMeetingsId
      }
      createdAt
      updatedAt
      meetingAgendaId
    }
  }
`;
export const updateAgendaItem = /* GraphQL */ `
  mutation UpdateAgendaItem(
    $input: UpdateAgendaItemInput!
    $condition: ModelAgendaItemConditionInput
  ) {
    updateAgendaItem(input: $input, condition: $condition) {
      id
      name
      description
      status
      meeting {
        id
        name
        type
        company {
          id
          name
          access
          profile
          industry
          culture
          external
          internal
          foundation
          customer
          product
          operations
          people
          stepProfile
          stepInterviews
          stepData
          stepAnalysis
          stepFocus
          createdAt
          updatedAt
        }
        meetingDate
        notes
        agenda {
          nextToken
        }
        createdAt
        updatedAt
        companyMeetingsId
      }
      createdAt
      updatedAt
      meetingAgendaId
    }
  }
`;
export const deleteAgendaItem = /* GraphQL */ `
  mutation DeleteAgendaItem(
    $input: DeleteAgendaItemInput!
    $condition: ModelAgendaItemConditionInput
  ) {
    deleteAgendaItem(input: $input, condition: $condition) {
      id
      name
      description
      status
      meeting {
        id
        name
        type
        company {
          id
          name
          access
          profile
          industry
          culture
          external
          internal
          foundation
          customer
          product
          operations
          people
          stepProfile
          stepInterviews
          stepData
          stepAnalysis
          stepFocus
          createdAt
          updatedAt
        }
        meetingDate
        notes
        agenda {
          nextToken
        }
        createdAt
        updatedAt
        companyMeetingsId
      }
      createdAt
      updatedAt
      meetingAgendaId
    }
  }
`;

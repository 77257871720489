import {React, useState, useEffect} from 'react';
import './swb.css'
import {API, graphqlOperation} from 'aws-amplify';
import * as queries from '../../graphql/queries';
import * as mutations from '../../graphql/mutations';
import Block from './Block';


export default function SWB(props) {

    const [swbInfo, setSwbInfo] = useState()
    const [refresh, setRefresh] = useState(false)
    const [blocks, setBlocks] = useState([])
    const [view, setView] = useState('whiteboard')
    const [selectedBlock, setSelectedBlock] = useState()
    const [blockItems, setBlockItems] = useState([])
    const [blockContentObjects, setBlockContentObjects] = useState([])

    useEffect(() => {
        fetchSWB()
    }, [props.userInfo, view])

    useEffect(() => {
        (async () => {
            const transformed = await transformArray(blockItems);
            setBlockContentObjects(transformed);
            console.log('transformed:', transformed)
        })();
    }, [blockItems])

    async function fetchSWB() {
        const companyId = props.userInfo.companyUserId
        const {data} = await API.graphql(graphqlOperation(queries.getSwb, {id: companyId+'-swb'}))
        if (data.getSwb) {
            setSwbInfo(data.getSwb)
            setBlockItems(data.getSwb.blockItems.items)
            console.log("SWB Info Set:", data.getSwb)
            const order = ['Culture', 'External', 'Internal', 'Foundation', 'Customer', 'Product', 'Operations', 'People'];
    
            const sortedBlocks = data.getSwb.blocks.items.sort((a, b) => {
                return order.indexOf(a.name) - order.indexOf(b.name);
            });
            setBlocks(sortedBlocks)
            console.log('sorted blocks:', sortedBlocks)
        } else {
            const id = companyId+'-swb'
            await API.graphql(graphqlOperation(mutations.createSwb, {input: {id: id, companySwbId: companyId, name: 'standard'}}))
            const blockDetails = [
                {name: "Culture", color: "blue"},
                {name: "External", color: "red"},
                {name: "Internal", color: "red"},
                {name: "Foundation", color: "blue"},
                {name: "Customer", color: "green"},
                {name: "Product", color: "green"},
                {name: "Operations", color: "green"},
                {name: "People", color: "green"},
              ]
            try {
                const createPromises = blockDetails.map(async (block) => {
                    const blockId = id + '-' + block.name;
                    const input = { id: blockId, swbBlocksId: id, name: block.name, color: block.color };
                    return API.graphql(graphqlOperation(mutations.createBlock, { input: input }));
                });
                await Promise.all(createPromises);
            } catch (error) {
                console.error("An error occurred while creating blocks:", error);
            }
            setRefresh(!refresh)
        }
    }

    function selectBlock(block) {
        setSelectedBlock(block)
        setView('block')
    }

    function back() {
        setView('whiteboard')
    }

    async function transformArray(blockItems) {
        const result = [];
    
        function findBlock(blockId) {
            return result.find(block => block.blockBlockItemsId === blockId);
        }
    
        function findElement(block, elementId) {
            return block.elements.find(element => element.blockElementItemsId === elementId);
        }
    
        for (let item of blockItems) {
            let block = findBlock(item.blockBlockItemsId);
            if (!block) {
                block = {
                    blockBlockItemsId: item.blockBlockItemsId,
                    elements: []
                };
                result.push(block);
            }
    
            let element = findElement(block, item.blockElementItemsId);
            if (!element) {
                try {
                    const { data } = await API.graphql(graphqlOperation(queries.getBlockElement, { id: item.blockElementItemsId }));
                    element = { 
                        ...data.getBlockElement, 
                        blockElementItemsId: item.blockElementItemsId, // ensure this ID is added to element 
                        elementItems: [] 
                    };
                    block.elements.push(element);
                } catch (error) {
                    console.error("Error fetching element:", error);
                }
            }
            if (!element.elementItems.some(e => e.id === item.id)) {  // Check if the item id already exists in the elementItems array
                element.elementItems.push({
                    id: item.id,
                    name: item.name,
                    description: item.description,
                    type: item.type,
                    status: item.status,
                    order: item.order
                });
            }
        }
    
        return result;
    }


    const sortedBlockContentObjects = blocks.map(block => {
        const matchedBlock = blockContentObjects.find(b => b.blockBlockItemsId === block.id);
        return matchedBlock ? matchedBlock : { blockBlockItemsId: block.id };
    })

    console.log('sortedBlockContentObjects:', sortedBlockContentObjects)

    const blockContent = sortedBlockContentObjects.map((block, blockIndex) => (
        <div className="swb-smallblock-content" key={blockIndex}>
            {block.elements && block.elements.length > 0 ? (
                block.elements
                    .sort((a, b) => a.order - b.order) // sort elements based on their order
                    .map((element, elementIndex) => (
                        <div className="swb-smallblock-element" key={elementIndex}>
                            <div className="swb-smallblock-element-name">{element.name}</div>
                            {element.elementItems && element.elementItems.length > 0 ? element.elementItems
                            .sort((a, b) => a.order - b.order) // sort element items based on their order
                            .map((item, itemIndex) => (
                                <div className={`swb-smallblock-element-item ${element.type === 'list' && "bullet"}`} key={itemIndex}>
                                    {item.name}
                                </div>
                            )) : null}
                        </div>
                    ))
            ) : null}
        </div>
    ))

    return (
        <div className="strategy-whiteboard">
            <div className='standard-whiteboard-magnet tl'></div>
            <div className='standard-whiteboard-magnet tr'></div>
            <div className='standard-whiteboard-magnet br'></div>
            <div className='standard-whiteboard-magnet bl'></div>
            {view === 'block' ? <Block block={selectedBlock} back={back} companyInfo={props.companyInfo} /> : <div className='strategy-whiteboard-main'>
                <div className="strategy-whiteboard-header" >Strategic Analysis</div>
                {blocks && <div className="strategy-whiteboard-section">
                    <div className="whiteboard-block" >
                        <div className="whiteboard-block-header blue">Culture</div>
                        <div className="whiteboard-block-content" onClick={()=>selectBlock(blocks[0])}>
                            {blockContent.length > 0 && sortedBlockContentObjects[0].elements ? 
                                <div className="swb-smallblock-container">{blockContent[0]}</div> : 
                                <img src="./Assets/Icons-SWB-Culture-Icon-Blue.png" 
                                    style={{width: "60%"}}>
                                </img>}
                        </div>
                    </div>
                    <div className="whiteboard-block">
                        <div className={"whiteboard-block-header red"}>External</div>
                        <div className="whiteboard-block-content" onClick={()=>selectBlock(blocks[1])}>
                            {blockContent.length > 0 && sortedBlockContentObjects[1].elements ? 
                                <div className="swb-smallblock-container">{blockContent[1]}</div> : 
                                <img src="./Assets/Icons-SWB-External-Icon-Blue.png" 
                                    style={{width: "50%", marginTop: '6%'}}>
                                </img>}
                        </div>
                    </div>
                    <div className="whiteboard-block">
                        <div className="whiteboard-block-header red">Internal</div>
                        <div className="whiteboard-block-content" onClick={()=>selectBlock(blocks[2])}>
                            {blockContent.length > 0 && sortedBlockContentObjects[2].elements ? 
                                <div className="swb-smallblock-container">{blockContent[2]}</div> : 
                                <img src="./Assets/Icons-SWB-Internal-Icon-Blue.png" 
                                    style={{width: "50%", marginTop: '6%'}}>
                                </img>}
                        </div>
                    </div>
                    <div className="whiteboard-block" >
                        <div className="whiteboard-block-header blue">Foundation</div>
                        <div className="whiteboard-block-content" onClick={()=>selectBlock(blocks[3])}>
                            {blockContent.length > 0 && sortedBlockContentObjects[3].elements ? 
                                <div className="swb-smallblock-container">{blockContent[3]}</div> : 
                                <img src="./Assets/Icons-SWB-Foundation-Icon-Blue.png" 
                                    style={{width: "60%", marginTop: '6%'}}>
                                </img>}
                        </div>
                    </div>
                </div>}
                <div className="strategy-whiteboard-header" >Strategic Focus and Outcomes</div>
                {blocks && <div className="strategy-whiteboard-section">
                    <div className="whiteboard-block" >
                        <div className="whiteboard-block-header">Customer</div>
                        <div className="whiteboard-block-content" onClick={()=>selectBlock(blocks[4])}>
                            {blockContent.length > 0 && sortedBlockContentObjects[4].elements ? 
                                <div className="swb-smallblock-container">{blockContent[4]}</div> : 
                                <img src="./Assets/Icons-SWB-Customer-Icon-Blue.png" 
                                    style={{width: "50%"}}>
                                </img>}
                        </div>
                    </div>
                    <div className="whiteboard-block" >
                        <div className="whiteboard-block-header">Product</div>
                        <div className="whiteboard-block-content" onClick={()=>selectBlock(blocks[5])}>
                            {blockContent.length > 0 && sortedBlockContentObjects[5].elements ? 
                                <div className="swb-smallblock-container">{blockContent[5]}</div> : 
                                <img src="./Assets/Icons-SWB-Product-Icon-Blue.png" 
                                    style={{width: "50%", marginTop: '4%'}}>
                                </img>}
                        </div>
                    </div>
                    <div className="whiteboard-block" >
                        <div className="whiteboard-block-header">Operations</div>
                        <div className="whiteboard-block-content" onClick={()=>selectBlock(blocks[6])}>
                            {blockContent.length > 0 && sortedBlockContentObjects[6].elements ? 
                                <div className="swb-smallblock-container">{blockContent[6]}</div> : 
                                <img src="./Assets/Icons-SWB-Operations-Icon-Blue.png" 
                                    style={{width: "60%"}}>
                                </img>}
                        </div>
                    </div>
                    <div className="whiteboard-block">
                        <div className="whiteboard-block-header">People</div>
                        <div className="whiteboard-block-content" onClick={()=>selectBlock(blocks[7])}>
                            {blockContent.length > 0 && sortedBlockContentObjects[7].elements ? 
                                <div className="swb-smallblock-container">{blockContent[7]}</div> : 
                                <img src="./Assets/Icons-SWB-People-Icon-Blue.png" 
                                    style={{width: "50%", marginTop: '5%'}}>
                                </img>}
                        </div>
                    </div>
                </div>}
            </div>}
        </div>
    )
}
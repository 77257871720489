import React, { useEffect } from 'react'
import { useState } from 'react'
import './profile.css'
import {API, graphqlOperation} from 'aws-amplify';
import * as queries from '../../../graphql/queries';

export default function Profile(props) {
    const [showGuide, setShowGuide] = useState(false)
    const [showMenu, setShowMenu] = useState(false)
    const [companyProfile, setCompanyProfile] = useState('')
    const [configured, setConfigured] = useState(false)

    useEffect(() => {
        fetchInfo()
    }, [])

    async function fetchInfo() {
        if (props.companyInfo) {
            const {data} = await API.graphql(graphqlOperation(queries.getCompany, {id: props.companyInfo.id}))
            setCompanyProfile(data.getCompany.profile)
            console.log("Company Profile:", data.getCompany.profile)
            if (data.getCompany.InterviewGroups.items.length > 0) {
                setConfigured(true)
            }
        } else return
    }

    const instructionsText = [
        'Click the marker icon on the main page to complete your company profile description.',
        'You will be guided through each Focus Lens by the Virtual Consultant.',
        'Follow the prompts and enter your responses in the text area.',
        'Click "Save" when you have completed all four Focus Lenses.',
        'This information will be submitted and used to supplement the strategy interviews.',
    ];

    const instructionsTextTwo = [
        'Copy and share the user access code with employees you want to be interviewed for strategic analysis and development.',
        'Admin access will provide the user with complete view and edit privileges througout this app.',
        'Standard access will provide the user with interview access only.'
    ];
    
    const guideInstructions = (
        <div className='standard-guide-instructions-box'>
            <div className='standard-guide-instructions-title'>Welcome to the Blendification AI Consultant. Completing your company profile is the first step to developing your Strategy Whiteboard.</div>
            <div className='standard-divider long' style={{margin: 'auto', marginTop: '15px', marginBottom: '15px'}}></div>
            <div className='standard-guide-instructions-header'>Provide a description of your company</div>
            {instructionsText.map((text, index) => (
                <div key={index} className='standard-guide-instructions-lineitem'>
                    <div className='standard-guide-instructions-bullet'></div>
                    <div className='standard-guide-instructions-text'>{text}</div>
                </div>
            ))}
            <div style={{height: "30px"}}></div>
            <div className='standard-guide-instructions-header'>Invite users to join your company profile</div>
            {instructionsTextTwo.map((text, index) => (
                <div key={index} className='standard-guide-instructions-lineitem'>
                    <div className='standard-guide-instructions-bullet'></div>
                    <div className='standard-guide-instructions-text'>{text}</div>
                </div>
            ))}
        </div>
    )

    const menu = (
        <div>
            <div className='standard-menu-spacer'></div>
            <div className='standard-menu-item' onClick={()=>setShowGuide(true)}>Instruction Guide</div>
            <div className='standard-menu-item' onClick={()=>props.selectModule('users')}>Manage Users</div>
            <div className='standard-menu-item' onClick={()=>{props.nextsteps(); setShowMenu(false)}} >Next Steps</div>
            <div className='standard-menu-item' onClick={()=>window.open('https://blendification.com/ai-strategy-consultant-question-landing-page/', '_blank')}>Contact</div>
        </div>
    )

    const textDescription = ("A company description helps our Virtual Consultant generate a more meaningful strategy. Click the marker icon and you'll be guided through this step.")
    const textDescriptionTwo = ("Configure the type and quantify of strategy interviews for your company employees to take.")
    const textDescriptionThree = ("Your company description is complete. Click the edit marker button if you want to modify it.")
    const textDescriptionFour = ("Click to take your own strategy interview.")
    const textDescriptionFive = ("Click for the Strategy Interviews admin dashboard to see all completed interviews.")


    return (
        <div className="standard-main">
            {showGuide && <div className='popup-mask standard' >
                <div className='popup' style={{justifyContent: 'space-between', width: '80%'}}>
                    {guideInstructions}
                    <div>
                        <button className="button small" onClick={() => setShowGuide(false)}>Close</button>
                    </div>
                </div>
            </div>
            } 
            <div className="standard-box header">
            <div className="standard-button-container"></div>
                <div className="standard-box-title">Define and Manage Company Profile</div>
                <div className="standard-menu-container">
                    <div className='standard-menu-button' onClick={() => setShowMenu(!showMenu)}>
                        <div className='standard-dropdown-menu-title'>Actions</div>
                        <div className={`standard-dropdown-menu-graphic ${showMenu && 'rotated'}`}></div>
                    </div>
                    {showMenu && <div className='standard-dropdown-menu-box'>
                        {menu}
                    </div>}
                </div>
            </div>
            <div className="standard-box">
                    <div className='standard-whiteboard-magnet tl'></div>
                    <div className='standard-whiteboard-magnet tr'></div>
                    <div className='standard-whiteboard-magnet br'></div>
                    <div className='standard-whiteboard-magnet bl'></div>
                    <div className="profile-header">
                        <div style={{fontSize: '75px', fontFamily: 'patrick hand'}}>{props.companyInfo.name}</div>
                    </div>
                    <div style={{display: 'flex', flexDirectoin: 'row', height: '70%', width: '100%'}}>
                        {companyProfile === '' ? <div className="profile-section">
                            <div className="profile-section-graphic">
                                <img src="./Assets/stepOne-icon.png" style={{width: '150px'}}></img>
                            </div>
                            <div style={{height: '', display: 'flex', justifyContent:'center', alignItems:'center', paddingTop: '50px'}}>
                                <div>Step 1: Describe Your Company</div>
                            </div>
                            
                            <div className='profile-section-text'>{textDescription}</div>
                            <div className="button icon" onClick={()=>props.selectModule('companydescription')}>
                                <img src="./Assets/edit-marker.png" style={{width: '50px'}}></img>
                            </div>
                        </div> :
                        <div className="profile-section">
                            <div className="profile-section-graphic">
                                <img src="./Assets/done-icon-blue.png" style={{width: '150px'}}></img>
                            </div>
                            <div style={{height: '', display: 'flex', justifyContent:'center', alignItems:'center', paddingTop: '50px'}}>
                                <div>Company Description Complete!</div>
                            </div>
                            
                            <div className='profile-section-text'>{textDescriptionThree}</div>
                            <div className="button icon" onClick={()=>props.selectModule('companydescription')}>
                                <img src="./Assets/edit-marker.png" style={{width: '50px'}}></img>
                            </div>
                        </div>}
                        {!configured ? <div className="profile-section">
                            <div className="profile-section-graphic">
                                <img src="./Assets/stepTwo-icon.png" style={{width: '150px'}}></img>
                            </div>
                            <div style={{height: '', display: 'flex', justifyContent:'center', alignItems:'center', paddingTop: '50px'}}>
                                <div>Step 2: Configure Your Strategy Interviews</div>
                            </div>
                            
                            <div className='profile-section-text'>{textDescriptionTwo}</div>
                            <div className="button icon" onClick={()=>props.selectModule('interviews')}>
                                <img src="./Assets/edit-marker.png" style={{width: '50px'}}></img>
                            </div>
                        </div> :
                        <div className="profile-section">
                            <div style={{height: '', display: 'flex', justifyContent:'center', alignItems:'center', paddingTop: '50px'}}>
                                <div>Take Your Strategy Interview!</div>
                            </div>
                            <div className='profile-section-text'>{textDescriptionFour}</div>
                            <div className="button graphic" onClick={()=>props.selectModule('interview')}>
                                <img src="./Assets/interview.png" style={{width: '150px'}}></img>
                            </div>
                            <div style={{height: '', display: 'flex', justifyContent:'center', alignItems:'center', paddingTop: '50px'}}>
                                <div>Or, view completed Strategy Interviews.</div>
                            </div>
                            <div className='profile-section-text'>{textDescriptionFive}</div>
                            <div className="button graphic" onClick={()=>props.selectModule('interviews')}>
                                <img src="./Assets/admin-icon.png" style={{width: '150px'}}></img>
                            </div>
                        </div>}
                    </div>
            </div>
        </div>
    )
}
import React from 'react';
import {useState, useEffect} from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import * as queries from '../../../graphql/queries';
import * as mutations from '../../../graphql/mutations';
import './profiledescription.css'


export default function CompanyDescription(props){

    const [view, setView] = useState('')
    const [showMenu, setShowMenu] = useState(false)
    const [profile, setProfile] = useState(['', '', '', ''])
    const [waiting, setWaiting] = useState(false)
    const [beginMessage, setBeginMessage] = useState('')
    const [changes, setChanges] = useState(false)
    const [profileDescription, setProfileDescription] = useState('')

    useEffect( () => {
        fetchInfo()
    }, [props.userInfo])

    useEffect( () => {
        if (profile[0] !== '' && profile[1] !== '' && profile[2] !== '' && profile[3] !== '') {
            setChanges(true)
        }
    }, [profile])

    async function fetchInfo() {
        if (props.userInfo) {
            const {data} = await API.graphql(graphqlOperation(queries.getCompany, {id: props.userInfo.company.id}))
            setProfileDescription(data.getCompany.profile)
            console.log('Profile Description:', data.getCompany.profile)
            if (data.getCompany.profile !== '') {
                setView('Completed')
            } else {
                setView('Begin')
                beginMessageSequence()
            }
        } else return
    }

    async function saveChanges() {
        const newProfile = profile.join(' ')
        try {
            await API.graphql(graphqlOperation(mutations.updateCompany, {input: {id: props.userInfo.companyUserId, profile: newProfile}}))
            setChanges(false)
            setView('Completed')
            setProfileDescription(newProfile)
            console.log('Profile Description Saved')
        } catch (error) {
            console.log('Error:', error)
        }
    }

    async function updateProfile() {
        try {
            await API.graphql(graphqlOperation(mutations.updateCompany, {input: {id: props.userInfo.companyUserId, profile: profileDescription}}))
            setChanges(false)
            console.log('Profile Description Updated')
        } catch (error) {
            console.log('Error:', error)
        }
    }

    function next() {
        if (view === 'Customer') {
            setView('Product')
        } else if (view === 'Product') {
            setView('Operations')
        } else if (view === 'Operations') {
            setView('People')
        } else if (view === 'People') {
            setView('Customer')
        }
    }

    function back() {
        if (view === 'Customer') {
            setView('People')
        } else if (view === 'Product') {
            setView('Customer')
        } else if (view === 'Operations') {
            setView('Product')
        } else if (view === 'People') {
            setView('Operations')
        }
    }


    const menu = (
        <div>
            <div className='standard-menu-spacer'></div>
            <div className='standard-menu-item' onClick={()=>window.open('https://blendification.com/ai-strategy-consultant-question-landing-page/', '_blank')}>Contact</div>
        </div>
    )

    const messageWaiting = (
          <div className="cd-message-waiting">
            <div className="dot" style={{backgroundColor: 'white'}}></div>
            <div className="dot" style={{backgroundColor: 'white'}}></div>
            <div className="dot" style={{backgroundColor: 'white'}}></div>
          </div>
        )

    const handleTextAreaChange = (e, num) => {
        if (num === 4) {setProfileDescription(e.target.value); setChanges(true)}
        else {
            const updatedProfile = [...profile];  
            updatedProfile[num] = e.target.value;   
            setProfile(updatedProfile)
        }
    }

    const message = {
        customer: 'Tell me about your customer. Who are they? How big is your market and how much share do you have?',
        product: 'What is your product or service? What are its features and benefits? How is it priced?',
        operations: 'Tell me about your operations? What is your financial situation? What are your systems and departments?',
        people: 'Tell me about your people. How many employees are there? What is your organization structure like?'
    }

    function beginMessageSequence() {
        setWaiting(true)
        setTimeout(() => {setWaiting(false);
            setBeginMessage("Hi, I am your virutual consultant. I will ask you some questions to help me learn about your company." )
        }, 500)
        setTimeout(() => setWaiting(true), 4000)
        setTimeout(() => {setWaiting(false);
            setBeginMessage("I look through different contextual lenses, which help me get a complete picture of the business and organization." )
        }, 4500)
        setTimeout(() => setWaiting(true), 8000)
        setTimeout(() => {setWaiting(false);
            setBeginMessage("These are the Customer, Product, Operations, and People Focus Lenses. Let's begin first with Customer." )
        }, 8500)
        setTimeout(() => setWaiting(true), 12000)
        setTimeout(() => setView('Customer'), 12500)
        setTimeout(() => setWaiting(false), 12500)
    }


    /*function skip() {
        setSkipSequence(true)
        setView('Customer');
        setWaiting(false); 
    }*/

    return(
        <div className="standard-main">
            <div className="standard-box header">
                <div className="standard-button-container">
                    <button className="button light small" onClick={()=>props.selectModule('profile')}>Back</button>
                    {view === 'Completed' ? <button className="button light small" disabled={!changes} onClick={updateProfile}>Update</button>
                    : <button className="button light small" disabled={!changes} onClick={saveChanges}>Save</button>}
                </div>
                <div className="standard-box-title">Company Description</div>
                <div className="standard-menu-container">
                    <div className='standard-menu-button' onClick={() => setShowMenu(!showMenu)}>
                        <div className='standard-dropdown-menu-title'>Actions</div>
                        <div className={`standard-dropdown-menu-graphic ${showMenu && 'rotated'}`}></div>
                    </div>
                    {showMenu && <div className='standard-dropdown-menu-box'>
                        {menu}
                    </div>}
                </div>
            </div>
            <div className="standard-box">
                <div className='standard-whiteboard-magnet tl'></div>
                <div className='standard-whiteboard-magnet tr'></div>
                <div className='standard-whiteboard-magnet br'></div>
                <div className='standard-whiteboard-magnet bl'></div>
                {view !== 'Begin' && view !== 'Completed' ? <div className="cd-marker-navigation-container">
                    <div className="cd-marker-holder"></div>
                    <div className="cd-marker-holder-back"></div>
                    <div className="cd-marker-label">{view} Lens</div>
                    <div className="cd-marker-navigation-option">
                        <div className={`cd-marker ${view === 'Customer' && 'active'}`} onClick={() => setView('Customer')}></div>
                    </div>
                    <div className="cd-marker-navigation-option">
                        <div className={`cd-marker ${view === 'Product' && 'active'}`} onClick={() => setView('Product')}></div>
                    </div>
                    <div className="cd-marker-navigation-option">
                        <div className={`cd-marker ${view === 'Operations' && 'active'}`} onClick={() => setView('Operations')}></div>
                    </div>
                    <div className="cd-marker-navigation-option">
                        <div className={`cd-marker ${view === 'People' && 'active'}`} onClick={() => setView('People')}></div>
                    </div>
                </div> : null}
                <div style={{height: '10%'}}></div>
                <div className="cd-body">
                    <div className="cd-body-display">
                        <div className="cd-display-graphic avatarx"></div>
                        {waiting ? <div className="cd-display-graphic messagex">
                            {messageWaiting}
                        </div>
                        : view === 'Completed' ? <div className="cd-display-graphic messagel">
                            <div className="cd-message">Your company profile description is below. You can modify it and click update to save your changes.</div>
                        </div>
                        : changes ? <div className="cd-display-graphic messagel">
                            <div className="cd-message">You've gone through all the Focus Lenses. If you are statisfied with your responses, click 'save' above. Or you can go back and edit if you want to make changes.</div>
                        </div>
                        : view === 'Begin' ? <div className="cd-display-graphic messagel">
                            <div className="cd-message">{beginMessage}</div>
                        </div> 
                        : view === 'Customer' ? <div className="cd-display-graphic messagel">
                            <div className="cd-message">{message.customer}</div>
                        </div>
                        : view === 'Product' ? <div className="cd-display-graphic messagel">
                            <div className="cd-message">{message.product}</div>
                        </div>
                        : view === 'Operations' ? <div className="cd-display-graphic messagel">
                            <div className="cd-message">{message.operations}</div>
                        </div>
                        : view === 'People' ? <div className="cd-display-graphic messagel">
                            <div className="cd-message">{message.people}</div>
                        </div>
                        : null
                        }
                    </div>
                    {view !== 'Begin' && view !== 'Completed' ? <div className="cd-body-input">
                        <div className="cd-input-button-container">
                            <div className="button small" onClick={back}>Prev</div>
                            <div className="button small" onClick={next}>Next</div>
                        </div>
                        {view === 'Customer' ? <textarea className="cd-input-box" placeholder='Type your response here then click Next to move to the next Focus Lens.' value={profile[0]} onChange={(e)=>handleTextAreaChange(e, 0)}></textarea>
                        : view === 'Product' ? <textarea className="cd-input-box" placeholder='Type your response here then click Next to move to the next Focus Lens.' value={profile[1]} onChange={(e)=>handleTextAreaChange(e, 1)}></textarea>
                        : view === 'Operations' ? <textarea className="cd-input-box" placeholder='Type your response here then click Next to move to the next Focus Lens.' value={profile[2]} onChange={(e)=>handleTextAreaChange(e, 2)}></textarea>
                        : view === 'People' ? <textarea className="cd-input-box" placeholder='Type your response here then click Next to move to the next Focus Lens.' value={profile[3]} onChange={(e)=>handleTextAreaChange(e, 3)}></textarea> 
                        : null}
                    </div> : null}
                    {view === 'Completed' ? <div className="cd-body-input full">
                        <textarea className="cd-input-box" placeholder='Type your response here then click Save to save your changes.' value={profileDescription} onChange={(e)=>handleTextAreaChange(e, 4)}></textarea>
                    </div> : null}
                </div>
            </div>
        </div>
    )
}
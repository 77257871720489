import {React, useState, useEffect, useRef} from 'react';
import {API, graphqlOperation} from 'aws-amplify';
import * as queries from '../../graphql/queries';
import * as mutations from '../../graphql/mutations';
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';



export default function Block (props) {

    const [blockInfo, setBlockInfo] = useState()
    const [elementList, setElementList] = useState([])
    const [itemsList, setItemsList] = useState([])
    const [refresh, setRefresh] = useState(false)
    const initialFieldInput = new Array(elementList.length).fill('');
    const [fieldInput, setFieldInput] = useState(initialFieldInput);
    const [inputValue, setInputValue] = useState('')
    const [clickedItemIndex, setClickedItemIndex] = useState(null)
    const [clickedElement, setClickedElement] = useState(null)
    const [originalInputs, setOriginalInputs] = useState([])
    const [showMenu, setShowMenu] = useState(false);
    const [view, setView] = useState('default')
    const [content360, setContent360] = useState('')

    useEffect(() => {
        function handleClickOutside(event) {
          if (!event.target.dataset.clickable) {
            setClickedItemIndex(null);
            setClickedElement(null);
            setInputValue('');
            console.log('original inputs:', originalInputs)
            if (originalInputs.length > 0) {
                setFieldInput(originalInputs);
            }
          }
        }
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
      }, [originalInputs]);
    

    useEffect(() => {
        fetchBlock()
    }, [refresh])

    async function fetchBlock() {
        const {data} = await API.graphql(graphqlOperation(queries.getBlock, {id: props.block.id}))
        let block = data.getBlock;
        let items = block.blockItems.items;
        const statementItems = items.filter(item => item.type === 'statement');
        console.log('statement items:', statementItems);
        setBlockInfo(block)
        setItemsList(items)
        console.log('items:', items);
        console.log('block:', block);
        if (!block.blockElements || block.blockElements.items.length === 0) {
            let subElements;
            if (block.name === 'Culture') {
                subElements = [['Cause', 'statement'], ['Intention', 'statement'], ['Behaviors', 'list']];
            } else if (block.name === 'External') {
                subElements = [['Impacts', 'list'], ['Inspiration', 'list']];
            } else if (block.name === 'Internal') {
                subElements = [['Strengths', 'list'], ['Weaknesses', 'list']];
            } else if (block.name === 'Foundation') {
                subElements = [['Foundational Strategy', 'statement'], ['Unifying Objective', 'statement'], ['Core Competency', 'statement']];
            } else if (block.name === 'Customer') {
                subElements = [['Customer Focus', 'statement'], ['Strategic Outcomes', 'list']];
            } else if (block.name === 'Product') {
                subElements = [['Product Focus', 'statement'], ['Strategic Outcomes', 'list']];
            } else if (block.name === 'Operations') {
                subElements = [['Operations Focus', 'statement'], ['Strategic Outcomes', 'list']];
            } else if (block.name === 'People') {
                subElements = [['People Focus', 'statement'], ['Strategic Outcomes', 'list']];
            }
            createElements(subElements);
            setRefresh(!refresh);
        } else if (block.blockElements) {
            const sortedElements = block.blockElements.items.sort((a, b) => {
                return a.order - b.order;
            });
            setElementList(sortedElements);
            const inputs = sortedElements.map(element => {
                const matchingItem = statementItems.find(item => item.blockElementItemsId === element.id);
                return matchingItem ? matchingItem.name : '';
              })
            console.log('inputs:', inputs)
            setOriginalInputs(inputs)
            setFieldInput(inputs)
        }
        return block;
    }
    
    async function createElements(elements) {
        const createPromises = elements.map(async (element, index) => {
            const elementId = props.block.swbBlocksId + '-' + props.block.name + '-' + element[0];
            const input = {
                id: elementId,
                blockBlockElementsId: props.block.id,
                name: element[0],
                type: element[1], 
                order: index
            };
            return API.graphql(graphqlOperation(mutations.createBlockElement, {input: input}));
        });
        const newElements = await Promise.all(createPromises);
        console.log('Created Elements:', newElements);
    }

    async function updateStatementItem(elementId, i) {
        const id = itemsList.find(item => item.blockElementItemsId === elementId).id;
        if (fieldInput[i] === '') {
            await API.graphql(graphqlOperation(mutations.deleteBlockItem, {input: {id: id}}));
        } else {
            await API.graphql(graphqlOperation(mutations.updateBlockItem, {input: {id: id, name: fieldInput[i]}}))
        }
        setRefresh(!refresh)
    }

    async function createBlockItem(e, blockElement, i) {
        const order = itemsList.filter(item => item.blockElementItemsId === blockElement.id).length
        if (e.type === "click" || e.key === 'Enter') {
            e.target.blur()
            if (blockElement.type === 'statement' && itemsList.some(item => item.blockElementItemsId === blockElement.id)) {
                updateStatementItem(blockElement.id, i)
            } else {
                const input = {
                    swbBlockItemsId: blockInfo.swb.id,
                    blockBlockItemsId: blockInfo.id,
                    blockElementItemsId: blockElement.id,
                    name: fieldInput[i],
                    type: blockElement.type,
                    status: 'active',
                    order: order
                }
                if (blockElement.name === 'Strategic Outcomes') {
                    input.type = 'outcome'
                }
                await API.graphql(graphqlOperation(mutations.createBlockItem, {input: input}));
                console.log('Created Item:', input);
                setItemsList([...itemsList, input]);

                if (blockElement.type !== 'statement') {
                    let newInput = [...fieldInput];
                    newInput[i] = '';
                    setFieldInput(newInput);
                }
            }
            setRefresh(!refresh)
        }
    }

    function handleChange(e, i) {
        let newInput = [...fieldInput]
        newInput[i] = e.target.value
        setFieldInput(newInput)
    }

   

    async function deleteItem(id) {
        await API.graphql(graphqlOperation(mutations.deleteBlockItem, {input: {id: id}}));
        setInputValue('')
        setRefresh(!refresh)
    }

    async function saveItem(id) {
        const input = {
            id: id,
            name: inputValue
        }
        await API.graphql(graphqlOperation(mutations.updateBlockItem, {input: input}));
        setInputValue('')
        setRefresh(!refresh)
    }

    function view360() {
        if (blockInfo.name === "Culture") {
            setContent360(props.companyInfo.culture)
        } else if (blockInfo.name === "External") {
            setContent360(props.companyInfo.external)
        } else if (blockInfo.name === "Internal") {
            setContent360(props.companyInfo.internal)
        } else if (blockInfo.name === "Foundation") {
            setContent360(props.companyInfo.foundation)
        } else if (blockInfo.name === "Customer") {
            setContent360(props.companyInfo.customer)
        } else if (blockInfo.name === "Product") {
            setContent360(props.companyInfo.product)
        } else if (blockInfo.name === "Operations") {
            setContent360(props.companyInfo.operations)
        } else if (blockInfo.name === "People") {
            setContent360(props.companyInfo.people)
        }

        setView('360')
    }

    function handleItemClick(item, itemIndex, index) {
        setClickedItemIndex(itemIndex)
        setInputValue(item.name)
        setClickedElement(index)
        console.log('clicked item:', item)
    }

    /*const handleOnDragEnd = (result) => {
        if (!result.destination) return;
    
        const items = Array.from(itemsList);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
    
        // Update the order of each item
        const updatedItems = items.map((item, index) => ({ ...item, order: index }));
        // Update your state with the new items list
        setItemsList(updatedItems);
    };

    const blockElements = elementList.map((blockElement, index) => {
        const existingStatementItem = itemsList.find(item => item.blockElementItemsId === blockElement.id);
        return (
            <div className="large-swb-block-element" key={blockElement.id}>
                <div className="block-element-header">{blockElement.name}</div>
                {
                    blockElement.type === 'list' &&
                    <DragDropContext onDragEnd={handleOnDragEnd}>
                        <Droppable droppableId={`droppable-${blockElement.id}`}>
                            {(provided) => (
                                <div {...provided.droppableProps} ref={provided.innerRef}>
                                    {
                                        itemsList
                                        .filter(item => item.blockElementItemsId === blockElement.id)
                                        .sort((a, b) => a.order - b.order)
                                        .map((blockItem, itemIndex) => (
                                            <Draggable key={blockItem.id} draggableId={blockItem.id.toString()} index={itemIndex}>
                                                {(provided) => (
                                                    <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                        {
                                                            clickedItemIndex === itemIndex && clickedElement === index ? 
                                                            <div className="block-element-item-container" key={blockItem.id}>
                                                                <input className="block-element-item" value={inputValue} onChange={(e)=>setInputValue(e.target.value)} data-clickable="true"></input>
                                                                <div className="block-element-item-edit-buttons">
                                                                    <div className="element-item-button" onClick={()=>{saveItem(blockItem.id); setClickedItemIndex(null)}} data-clickable="true">save</div>
                                                                    <div className="element-item-button delete" onClick={()=>{deleteItem(blockItem.id); setClickedItemIndex(null)}} data-clickable="true">delete</div>
                                                                </div>
                                                            </div>:
                                                            <div className="block-element-item bullet saved" key={blockItem.id} onClick={() => handleItemClick(blockItem, itemIndex, index)}>
                                                                {blockItem.name !== '' ? blockItem.name : 'Click to name this item'}
                                                            </div>
                                                        }
                                                    </div>
                                                )}
                                            </Draggable>
                                        ))
                                    }
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                }
                <div className="block-element-item-container" >
                    {   
                        blockElement.type === 'statement' && existingStatementItem && existingStatementItem.name !== '' && clickedElement !== index ?
                        <div className="block-element-item saved" 
                            data-clickable="true"
                            onClick={() => setClickedElement(index)}
                        >
                            {existingStatementItem.name}
                        </div> :
                        blockElement.type === 'statement' && existingStatementItem && clickedElement === index ?
                        <input className="block-element-item" 
                            data-clickable="true"
                            placeholder={`Define your ${blockElement.name}`}
                            value={fieldInput[index]}
                            onChange={(e) => {handleChange(e, index)}}
                            onKeyDown={(e)=>createBlockItem(e, blockElement, index)}
                            onClick={() => setClickedElement(index)}
                        >
                        </input> :
                        blockElement.type === 'statement' && existingStatementItem ?
                        <input className="block-element-item" 
                            data-clickable="true"
                            placeholder={`Define your ${blockElement.name}`}
                            value={fieldInput[index]}
                            onChange={(e) => {handleChange(e, index)}}
                            onKeyDown={(e)=>createBlockItem(e, blockElement, index)}
                            onClick={() => setClickedElement(index)}
                        >
                        </input> :
                        <input 
                            className="block-element-item" 
                            data-clickable="true"
                            placeholder={`Add ${blockElement.name}`} 
                            value={fieldInput[index]}
                            onChange={(e) => {handleChange(e, index)}}
                            onKeyDown={(e)=>createBlockItem(e, blockElement, index)}
                            onClick={() => {setClickedElement(index); setClickedItemIndex(null)}}>
                        </input>
                    }
                    {clickedElement === index && clickedItemIndex === null &&
                        <div className="element-item-button" onClick={(e)=>{createBlockItem(e, blockElement, index); setClickedElement(null)}} data-clickable="true">Save</div>
                    }
                </div>
            </div>
        );
    });*/


    const blockElements = elementList.map((blockElement, index) => {
        const existingStatementItem = itemsList.find(item => item.blockElementItemsId === blockElement.id);
        return (
            <div className="large-swb-block-element" key={blockElement.id}>
                <div className="block-element-header">{blockElement.name}</div>
                {
                    blockElement.type === 'list' &&
                    itemsList
                    .filter(item => item.blockElementItemsId === blockElement.id)
                    .sort((a, b) => { return a.order - b.order })
                    .map((blockItem, itemIndex) => (
                       clickedItemIndex === itemIndex && clickedElement === index ? 
                        <div className="block-element-item-container" key={blockItem.id}>
                            <input className="block-element-item" value={inputValue} onChange={(e)=>setInputValue(e.target.value)} data-clickable="true"></input>
                            <div className="block-element-item-edit-buttons">
                                <div className="element-item-button" onClick={()=>{saveItem(blockItem.id); setClickedItemIndex(null); setClickedElement(null)}} data-clickable="true">save</div>
                                <div className="element-item-button delete" onClick={()=>{deleteItem(blockItem.id); setClickedItemIndex(null); setClickedElement(null)}} data-clickable="true">delete</div>
                            </div>
                        </div>:
                        <div className="block-element-item bullet saved" key={blockItem.id} onClick={() => handleItemClick(blockItem, itemIndex, index)}>
                            {blockItem.name !== '' ? blockItem.name : 'Click to name this item'}
                        </div>
                    ))
                }
                <div className="block-element-item-container" >
                    {   
                        blockElement.type === 'statement' && existingStatementItem && existingStatementItem.name !== '' && clickedElement !== index ?
                        <div className="block-element-item saved" 
                            data-clickable="true"
                            onClick={() => setClickedElement(index)}
                        >
                            {existingStatementItem.name}
                        </div> :
                        blockElement.type === 'statement' && existingStatementItem && clickedElement === index ?
                        <input className="block-element-item" 
                            data-clickable="true"
                            placeholder={`Define your ${blockElement.name}`}
                            value={fieldInput[index]}
                            onChange={(e) => {handleChange(e, index)}}
                            onKeyDown={(e)=>createBlockItem(e, blockElement, index)}
                            onClick={() => setClickedElement(index)}
                        >
                        </input> :
                        blockElement.type === 'statement' && existingStatementItem ?
                        <input className="block-element-item" 
                            data-clickable="true"
                            placeholder={`Define your ${blockElement.name}`}
                            value={fieldInput[index]}
                            onChange={(e) => {handleChange(e, index)}}
                            onKeyDown={(e)=>createBlockItem(e, blockElement, index)}
                            onClick={() => setClickedElement(index)}
                        >
                        </input> :
                        clickedItemIndex === null &&
                        <input 
                            className="block-element-item" 
                            data-clickable="true"
                            placeholder={`Add ${blockElement.name}`} 
                            value={fieldInput[index]}
                            onChange={(e) => {handleChange(e, index)}}
                            onKeyDown={(e)=>createBlockItem(e, blockElement, index)}
                            onClick={() => {setClickedElement(index); setClickedItemIndex(null)}}>
                        </input>
                    }
                    {clickedElement === index && clickedItemIndex === null &&
                        <div className="element-item-button" onClick={(e)=>{createBlockItem(e, blockElement, index); setClickedElement(null)}} data-clickable="true">Save</div>
                    }
                </div>
            </div>
        );
    });

    const menu = (
        <div className="skewerMenu" onClick={()=>setShowMenu(!showMenu)}>
            {showMenu && <div className="swb-block-menu-box">
                {view === 'default' && <div className="swb-block-menu-item" onClick={view360}>View Strategy 360</div>}
                {view === '360' && <div className="swb-block-menu-item" onClick={()=>setView('default')}>Back to Block</div>}
                <div className="swb-block-menu-item">Auto-Synthesize Block</div>
                <div className="swb-block-menu-item">Watch Video</div>
            </div>}
        </div>
    )

    const blockHeader = (
        <div className="large-swb-block-header">
            <div className="swb-block-back-arrow" onClick={props.back}></div>
            {blockInfo && <div className={`swb-block-title ${blockInfo.color}`}>{blockInfo.name}</div>}
            <div className="swb-block-menu-container">{menu}</div>
        </div>)

    return (
        <div className='strategy-whiteboard-main'>  
            {blockHeader}
            <div className="large-swb-block-elements-container">
                {view === "default" ? blockElements : view === "360" && <div className="large-swb-block-text">{content360}</div>}
            </div>
        </div>
    )
}
import React from 'react';
import './standard.css'
import './nextsteps.css'


export default function NextSteps(props){

    const swbLink = (<div className="button text" style={{fontSize: '15px', fontWeight: 'bold', color: '#1CA287'}} onClick={()=>window.open('https://blendification.com/software_workshops/strategy-whiteboard/', '_blank')}>Strategy Whiteboard Web App</div>)
    const workshopLink = (<div className="button text" style={{fontSize: '15px', fontWeight: 'bold', color: '#1CA287'}} onClick={()=>window.open('https://blendification.com/software_workshops/workshops-consulting/', '_blank')}>Professionally-led Workshop</div>)

    const subheader = "The interviews yield rich insights, thereby establishing a robust foundation for building and executing an agile strategic platform. The question now is, how do the interviews and AI-generated strategy become the basis for ongoing, distributed strategy implementation connecting culture, strategy, and execution?  Here are the recommended steps to blending your company and maximizing potential."
    const text = [
        ` to independently steer your leadership team through strategy meetings, culminating in a robust Strategy Whiteboard primed for successful implementation.`,
        ` where a consultant guides your leadership team in crafting a well-defined strategic path. This premium service not only ensures that a Strategy Whiteboard is ready for execution but also includes access to our Strategy Whiteboard software.`,
        "Within the Strategy Whiteboard, we establish cross-functional teams operating at multiple levels to foster successful implementation and enhance employee engagement through a structured framework designed for optimal strategic execution. This continous process is seamlessly done within the Strategy Whiteboard software and set up in the Strategy Whiteboard workshops."
    ]
    

    return (
        <div className="popup-mask">
            <div className="popup-whiteboard">
                <div className='standard-whiteboard-magnet tl'></div>
                <div className='standard-whiteboard-magnet tr'></div>
                <div className='standard-whiteboard-magnet br'></div>
                <div className='standard-whiteboard-magnet bl'></div>
                <div className="nextsteps-main">
                    <div className="nextsteps-header">NEXT STEPS</div>
                    <div className="nextsteps-header-sub">{subheader}</div>
                    <div className="standard-divider long" style={{marginTop: '10px'}}></div>
                    <div className="nextsteps-body">
                        <div style={{width: ''}}>
                            <div className="nextsteps-sub-heading">1. Final Strategy Whiteboard</div>
                            <div className="nextsteps-sub-container">
                                <div className="nextsteps-section">
                                    <div className="nextsteps-section-leftside">
                                        Leverage the {swbLink}{text[0]}
                                    </div>
                                </div>
                                <div className="nextsteps-section">
                                    <div className="nextsteps-section-leftside">
                                        Alternatively, opt for a {workshopLink}{text[1]}
                                    </div>
                                </div>
                            </div>
                            <div className="nextsteps-sub-heading">2. Strategic Execution</div>
                            <div className="nextsteps-sub-container">
                            <div className="nextsteps-section">
                                    <div className="nextsteps-section-leftside">
                                        {text[2]}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', alignItems: 'center'}}>
                            <div className="nextsteps-section-rightside">
                                <img src="./Assets/SWB-laptop.png" style={{height: '100px'}}alt="laptop" />
                            </div>
                            <div className="nextsteps-section-rightside">
                                <img src="./Assets/workshop.png" style={{height: '100px'}}alt="workshop" />
                            </div>
                            <div className="nextsteps-section-rightside">
                                <img src="./Assets/meetings.png" style={{height: '150px'}}alt="meetings" />
                            </div>
                        </div>
                    </div>
                    <div className="buttons-container">
                            <button className="button small" onClick={props.toggleNextSteps}>Close</button>
                    </div>
                </div>
                
            </div>
        </div>
    )
}
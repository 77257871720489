import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { API, graphqlOperation } from 'aws-amplify';
import { listTemplates } from '../../graphql/queries';

export default function LoadTemplate(props) {
  const [templates, setTemplates] = useState([]);
  const [selectedOption, setSelectedOption] = useState('');


  useEffect(() => {
    async function fetchData() {
      const { data } = await API.graphql(graphqlOperation(listTemplates));
      setTemplates(data.listTemplates.items);
    }
    fetchData();
  }, [props.updated]);

  useEffect(() => {
    console.log('selectedOption', selectedOption)
  }, [selectedOption])

  
  function handleOptionSelect(option) {
    setSelectedOption(option);
    props.setTemplate(option.value)
  }


  return (
    <div>
        <Select
          value={selectedOption}
          onChange={handleOptionSelect}
          options={[
            ...templates.map((template) => ({ value: template.tempid, label: template.name })),
            { value: 'new', label: 'Create New Template' }
          ]}
          placeholder="Select a Template"
          className="my-select"
        />
    </div>
  );
}

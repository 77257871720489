import React, {useState, useEffect} from 'react'
import './Settings.css'
import { API, graphqlOperation } from 'aws-amplify';
import * as mutations from '../../graphql/mutations';
import * as queries from '../../graphql/queries';

export default function CompanyTable(props){
  const [companies, setCompanies] = useState([])
  const [users, setUsers] = useState([])
  const [groups, setGroups] = useState([])
  const [companyFilter, setCompanyFilter] = useState('')
  const [clicked, setClicked] = useState([])
  const [companyId, setCompanyId] = useState('')
  const [userId, setUserId] = useState('')
  const [selectedUser, setSelectedUser] = useState({username: ''})
  const [selectedUserInterviews, setSelectedUserInterviews] = useState([])


  useEffect (() => {
    let userFilter = {permissions: {ne: "SuperAdmin"}}
    if (companyId !== '' && companyId !== null) {
      userFilter.companyUserId = {eq: companyId}
    }
    const getData = async () => {
      const {data} = await API.graphql(graphqlOperation(queries.listCompanies))
      const groups = await API.graphql(graphqlOperation(queries.listInterviewGroups, {filter: {companyInterviewGroupsId: {eq: companyId}}}))
      const users = await API.graphql(graphqlOperation(queries.listUsers, {filter: userFilter}))
      console.log('groups: ', groups.data.listInterviewGroups.items)
      setCompanies(data.listCompanies.items)
      setUsers(users.data.listUsers.items)
      setGroups(groups.data.listInterviewGroups.items)
    }
    getData()
  }, [companyFilter, clicked])

  useEffect (() => {
    if (userId !== '') {
      fetchUser(userId)
    }
  }, [userId, clicked])

  async function fetchUser(id) {
    try {
      const { data } = await API.graphql(graphqlOperation(queries.getUser, { userid: id }));
      const userInterviews = await Promise.all(
        data.getUser.interviews.items.map(async (interview) => {
          const interviewData = await API.graphql(graphqlOperation(queries.getInterview, { intid: interview.intid }));
          return interviewData.data.getInterview;
        })
      );
      console.log('user interviews: ', userInterviews);
      setSelectedUser(data.getUser);
      setSelectedUserInterviews(userInterviews);
    } catch (error) {
      console.error("An error occurred while fetching the user: ", error);
    }
  }

  function applyFilter(list, id) {
    if (list === "company") {
      setCompanyFilter({filter: {companyUserId: {eq: id}}});
      setClicked({company: id, user: ''});
      setCompanyId(id)
      setUserId('')
      setSelectedUser({username: ''})
      setSelectedUserInterviews([])
    } else if (list === "user") {
      setClicked({company: companyId, user: id});
      setUserId(id)
      console.log('clicked user: ', id)
    } else if (list === "interview") {
      setClicked({company: companyId, user: userId, interview: id});
    }
  }

  const companyList = companies.map((company) => {
    return <div 
    className={"settings-list-item button" + (clicked.company === company.id ? " selected" : "") }
    key={company.id}
    onClick={() => applyFilter("company", company.id)}
    >
      <div className="settings-list-item-detail">{company.name}</div>
      <div className="settings-list-item-detail">{company.id}</div>
      <div className="settings-list-item-detail">{company.industry}</div>
    </div>
  })

  const userList = users.map((user) => {
    return <div 
    className={"settings-list-item button" + (clicked.user === user.userid ? " selected" : "") } 
    key={user.userid}
    onClick={() => applyFilter("user", user.userid)}
    >
      <div className="settings-list-item-detail">{user.username}</div>
      <div className="settings-list-item-detail">{user.emailid}</div>
      <div className="settings-list-item-detail">{user.permissions}</div>
      <div className="settings-list-item-detail">{user.role}</div>
    </div>
  })

  const interviewList = selectedUserInterviews.map((interview) => {
    return <div 
    className={"settings-list-item button" + (clicked.interview === interview.intid ? " selected" : "") } 
    key={interview.intid}
    onClick={() => applyFilter("interview", interview.intid)}
    >
      <div className="settings-list-item-detail">{interview.group.Template.name}</div>
      <div className="settings-list-item-detail">{interview.intid}</div>
      <div className="settings-list-item-detail delete">
        {clicked.interview === interview.intid ? <div className="button small light" onClick={()=>{deleteInterview(interview); setClicked({company: companyId, user: userId, interview: ''})}}>Delete</div> : null}
      </div>
    </div>
  })

  async function handleDelete(type){
    if (type === 'company') {
      const confirmed = window.confirm("Confirm you want to delete the company and all related items including users and interviews.")
      if (confirmed) {
        users.forEach((user) => {
          deleteUserData(user.userid)
          deleteCognitoUser(user.userid)
        })
        groups.forEach(async (group) => {
          await API.graphql(graphqlOperation(mutations.deleteInterviewGroup, {input: {Groupid: group.Groupid}}))
        })
        await API.graphql(graphqlOperation(mutations.deleteCompany, {input: {id: companyId}}))
      }
    } else if (type === 'user') {
      const confirmed = window.confirm("Confirm you want to delete the user, interview, chatlog and all messages.", userId)
      if (confirmed) {
        deleteUserData(userId)
        deleteCognitoUser(userId)
      }
    }
    setClicked([])
    setUserId('')
    setSelectedUser({username: ''})
    setSelectedUserInterviews([])
  }

  async function deleteUserData(id) {
    const interviews = await API.graphql(graphqlOperation(queries.listInterviews, {filter: {userInterviewsUserid: {eq: id}}}))
    const interviewsToDelete = interviews.data.listInterviews.items
    console.log('interviews to delete: ', interviewsToDelete)
    if (interviewsToDelete.length > 0) {interviewsToDelete.forEach(async (interview) => {
      deleteInterview(interview)
    })}
    await API.graphql(graphqlOperation(mutations.deleteUser, {input: {userid: id}}))
  }

  async function deleteInterview(interview) {
    const chatlogs = await API.graphql(graphqlOperation(queries.listChatLogs, {filter: {interviewConversationIntid: {eq: interview.intid}}}))
    if (chatlogs.data.listChatLogs.items[0]) {
      const logToDelete = chatlogs.data.listChatLogs.items[0]
      console.log('chatlog deleted: ', logToDelete)
      const chatlog = await API.graphql(graphqlOperation(queries.getChatLog, {logid: logToDelete.logid}))
      const messages = chatlog.data.getChatLog.messages.items
      console.log('messages deleted: ', messages)
      await API.graphql(graphqlOperation(mutations.deleteChatLog, {input: {logid: logToDelete.logid}}))
      messages.forEach((message) => {
        API.graphql(graphqlOperation(mutations.deleteChatmessage, {input: {msgid: message.msgid}}))
      })
    }
    await API.graphql(graphqlOperation(mutations.deleteInterview, {input: {intid: interview.intid}}))
    console.log('interview deleted: ', interview.intid)
  }


  async function handleEnter () {
    const {data} = await API.graphql(graphqlOperation(mutations.updateUser, {input: {userid: props.userInfo.userid, companyUserId: companyId}}))
    window.location.reload()
  }

  async function deleteCognitoUser(id) {
    try {
      const url = 'https://2uzjzfregh.execute-api.us-west-2.amazonaws.com/dev/manageCognito-dev';
      const myInit = {
        method: 'POST',
        body: JSON.stringify({ username: id }),
        headers: {'Content-Type': 'application/json'},
      };
  
      const response = await fetch(url, myInit);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      const result = await response.json();
      console.log('User deleted successfully:', result);
    } catch (error) {
      console.error('Error deleting user:', error);
    }
  }

  return (
      <div className="settings-body">
          <div className="settings-tables-container">
            <div className="settings-table">
              <div className="settings-column-header">
                <div className="button small light" style={{position: 'absolute', left: '0', width: '150px'}} onClick={handleEnter}>Enter Company</div>
                <div>Companies: {companyList.length}</div>
                <div className="button small light" style={{position: 'absolute', right: '0', width: '150px'}} onClick={()=>handleDelete('company')}>Delete Company</div>
              </div>
              <div className="settings-list">
                <div 
                  className={"settings-list-item button" + (clicked.company === '' ? " selected" : "") }
                  key='all'
                  onClick={() => applyFilter("company", '')}
                >
                  <div className="settings-list-item-detail">All</div>
                  <div className="settings-list-item-detail"></div>
                  <div className="settings-list-item-detail"></div>
                </div>
                {companyList}
              </div>
            </div>
            <div className="settings-table">
              <div className="settings-column-header">
              {userId && <div className="button small light" style={{position: 'absolute', left: '0', width: '100px'}} onClick={()=>setSelectedUser({username: '', interviews: []})}>Back</div>}
                {userId && <div className="button small light" style={{position: 'absolute', right: '0', width: '150px'}} onClick={()=>handleDelete('user')}>Delete User</div>}
                {selectedUser.username === '' ? <div>Users: {userList.length}</div> : null}
                {selectedUser.username !== '' ? <div>{selectedUser.username}'s Interviews</div> : null}
              </div>
              <div className="settings-list">{selectedUser.username !== '' ? interviewList : userList}</div>
            </div>
          </div>         
      </div>
  )
}


import React, {useState, useEffect} from 'react'
import './navigation.css'
import {API, graphqlOperation} from 'aws-amplify';
import * as queries from '../../graphql/queries';


export default function Navigation(props){
    const [selected, setSelected] = useState('')
    const [completedSteps, setCompletedSteps] = useState({})

    useEffect (() => {
        setSelected(props.module)
    } , [props.module])

    useEffect (() => {
        if (props.userInfo.companyUserId) {
            fetchCompany()
        }
    }, [props.userInfo])

    async function fetchCompany () {
        const {data} = await API.graphql(graphqlOperation(queries.getCompany, {id: props.userInfo.companyUserId}))
        const completedArray = {
            profile: data.getCompany.stepProfile,
            interviews: data.getCompany.stepInterviews,
            data: data.getCompany.stepData,
            analysis: data.getCompany.stepAnalysis,
            focus: data.getCompany.stepFocus
        }
        setCompletedSteps(completedArray)
    }

    function handleClick (e) {
        if (e.target.name === 'profile') {
            props.selectModule('profile')
        } else if (e.target.name === 'interviews') {
            props.selectModule('interviews')
        } else if (e.target.name === 'data') {
            props.selectModule('data')
        } else if (e.target.name === 'analysis') {
            props.selectModule('analysis')
            props.selectView('dashboard')
        } else if (e.target.name === 'whiteboard') {
            props.selectModule('whiteboard')
        }
    }

    return(
        <div style={{height: '100%'}}>
            <div style={{color: 'white', fontSize: '30px'}}>Strategy 360</div>
            <div className="side-menu-button-container" >
                <div className="side-menu-nav-option">
                    <button className={`nav-option-icon ${selected==='profile' && 'selected'}`} name="profile" onClick={handleClick}></button>
                    <div className="nav-option-info">Company Profile</div>
                </div>
                <div className="side-menu-nav-option">
                    <button className={`nav-option-icon ${selected==='interviews' && 'selected'}`} name="interviews" onClick={handleClick}></button>
                    <div className="nav-option-info">Strategy Interviews</div>
                </div>
                <div className="side-menu-nav-option">
                    <button className={`nav-option-icon ${selected==='analysis' && 'selected'}`} name="analysis" onClick={handleClick}></button>
                    <div className="nav-option-info">Interview Examiner</div>
                </div>
                <div className="side-menu-nav-option">
                    <button className={`nav-option-icon ${selected==='whiteboard' && 'selected'}`} name="whiteboard" onClick={handleClick}></button>
                    <div className="nav-option-info">Strategy Whiteboard</div>
                </div>
            </div>    
        </div>
        
    )}
import React, {useState, useEffect} from 'react'
import { API, graphqlOperation } from 'aws-amplify';
import * as queries from '../../../graphql/queries';
import * as mutations from '../../../graphql/mutations';
import ReactPlayer from 'react-player/lazy'


export default function Interview(props){
   const [userInfo, setUserInfo] = useState({});
   const [groupId, setGroupId] = useState('');
   const [interviewStarted, setInterviewStarted] = useState(false);
   const [interviewEnded, setInterviewEnded] = useState(false);
   const [showGroupMenu, setShowGroupMenu] = useState(false);
   const [menuList, setMenuList] = useState([]);
   const [selection, setSelection] = useState('Select Interview')
   const [introMessage, setIntroMessage] = useState('')

   useEffect(() => {
    if (props.userInfo) {
        console.log('props.userInfo:', props.userInfo)
        setUserInfo(props.userInfo)
        //console.log("userInfo:", userInfo)
    }
   } , [props.userInfo])

    useEffect(() => {
        fetchInfo();
    }, [props.userInfo.company])

    async function fetchInfo() {
        if (props.userInfo.company) {
            const groups = await API.graphql(graphqlOperation(queries.listInterviewGroups, {filter: {companyInterviewGroupsId: {eq: props.userInfo.company.id}}}));
            console.log('Interview Group List:', groups.data.listInterviewGroups.items)
            const groupList = groups.data.listInterviewGroups.items
            const groupsWithSteps = groupList.map((group) => {
                let step;
                switch (group.Template.tempid) {
                    case 'culture':
                        step = 1;
                        break;
                    case 'strategicanalysis':
                        step = 2;
                        break;
                    case 'foundation':
                        step = 3;
                        break;
                    case 'focus':
                        step = 4;
                        break;
                    default:
                        step = null;
                }
                return { step, group };
            });
        
            groupsWithSteps.sort((a, b) => a.step - b.step)
            console.log('Group List with Steps:', groupsWithSteps)
            const groupMenuItems = groupsWithSteps.map(({ step, group }) => {
                return  <div className="interview-menu-item" key={step} onClick={()=>handleSelection(group)}>
                            {group.Template.name}
                        </div>
            })
            setMenuList(groupMenuItems)
            if (groupMenuItems.length > 0) {
                setIntroMessage('Select the interview topic from the menu below to begin your interview.')
            } else if (props.userInfo.permissions === 'Admin') {
                setIntroMessage('You have not created any interview groups yet. Return to the admin dashboard and set up your interview groups on the Employee Interviews page.')
            } else {
                setIntroMessage('Your company has not created any interview groups yet. Please contact your company administrator to create a new interview group.')
            }
        }
    }

    function handleSelection(group) {
        setGroupId(group.Groupid)
        setShowGroupMenu(false)
        setSelection(group.Template.name)
        beginInterview(group)
    }

   async function beginInterview(group) {
    if (!group.Groupid){
        alert("Error loading interview.")
        return
    } else {
        const intid = `${props.userInfo.userid}-${group.Groupid}`;
        const logid = `${intid}-log-1`;
        const msgid = `${intid}-msg-1`;
        const {data} = await API.graphql(graphqlOperation(queries.getInterview, {intid: intid}))
        if (data.getInterview){
            setInterviewStarted(true)
            console.log("Found existing interview:", data.getInterview)
            props.handleInterviewStart(group.Groupid)
        } else {
            const newInterviewInfo = {
            interviewGroupInterviewsGroupid: group.Groupid,
            intid: intid,
            userInterviewsUserid: props.userInfo.userid,
            active: true,
            process: true,
            }
            console.log('userInfo:', userInfo)
            console.log('newInterviewInfo:', newInterviewInfo)
            const template = await API.graphql(graphqlOperation(queries.getTemplate, {tempid: group.Template.tempid}))
            const templateMessage = template.data.getTemplate.firstMessage
            console.log("Template message:", templateMessage)
            const newChatLogInfo = {interviewConversationIntid: intid, logid: logid}
            const firstMessageInput = {chatLogMessagesLogid: logid, msgid: msgid, user: "assistant", content: templateMessage}
            try {
                const newinterview = await API.graphql(graphqlOperation(mutations.createInterview, { input: newInterviewInfo }))
                const newChatLog = await API.graphql(graphqlOperation(mutations.createChatLog, { input: newChatLogInfo }))
                const newMessage = await API.graphql(graphqlOperation(mutations.createChatmessage, { input: firstMessageInput }))
                setInterviewStarted(true)
                props.handleInterviewStart(group.Groupid)
                console.log("Created new interview:", newinterview.data.createInterview, newChatLog.data.createChatLog, newMessage.data.createChatmessage)
            } catch (error) { console.log("Error creating new interview:", error) }
        }
    }
  }

    function handleEnd () {
        endInterview();
        setInterviewEnded(true);
    }

    async function endInterview() {
        const intid = `${props.userInfo.userid}-${groupId}`
        let interview
        try {
            const {data} = await API.graphql(graphqlOperation(mutations.updateInterview, {input: {intid: intid, active: false}}))
            console.log("Interview ended.", data.updateInterview)
            interview = data.updateInterview
        } catch (error) {console.log("Error ending interview:", error)}
        const {data} = await API.graphql(graphqlOperation(queries.listChatLogs, {limit: 10000, filter: {interviewConversationIntid: {eq: intid}}}))
        const logid = data.listChatLogs.items[0].logid
        let messages = await API.graphql(graphqlOperation(queries.getChatLog, {logid: logid}))
        messages = messages.data.getChatLog.messages.items
        const sortedMessages = messages.sort((a, b) => {
            return new Date(a.updatedAt) - new Date(b.updatedAt);
          })
        const cleanedMessages = sortedMessages.map(message => ({user: message.user, message: message.content}))
        const content = cleanedMessages.map(message => {
            let userLabel = '';
            if (message.user === 'assistant') {
                userLabel = 'interviewer';
            } else if (message.user === 'user') {
                userLabel = 'interviewee';
            } else {
                userLabel = message.user; // default case, in case there are other values for message.user
            }
            return `${userLabel}: ${message.message}`;
        }).join('\n\n')
        const promptInfo = await API.graphql(graphqlOperation(queries.getPrompt, {id: 'default'}));
        let instruction = promptInfo.data.getPrompt.interviewSummary
        const topic = interview.group.interviewGroupTemplateTempid
        if (topic === 'culture') {
            instruction = promptInfo.data.getPrompt.intsummarycult
        } else if (topic === 'strategicanalysis') {
            instruction = promptInfo.data.getPrompt.intsummarysa
        } else if (topic === 'foundation') {
            instruction = promptInfo.data.getPrompt.intsummaryfound
        } else if (topic === 'focus') {
            instruction = promptInfo.data.getPrompt.intsummaryfocus
        }
        console.log("instruction:", instruction)
        try { await fetch("https://2uzjzfregh.execute-api.us-west-2.amazonaws.com/dev/dataprocessor", {
            method: "POST",
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify({
                instruction: instruction,
                content: content,
                id: intid,
                profile: props.userInfo.company.profile,
                }),
            })
        } catch (error) {console.log("Error sending data to dataprocessor:", error)}
    }

    const video = 
        <div>
            <ReactPlayer
                url="https://vimeo.com/826521854/1cd308ffb5?share=copy"
                controls={true}
            />
        </div>

    function refreshPage() {
        window.location.reload(false);
    }


    return(
        <div className="module">
            {interviewEnded && 
                <div className='popup-mask'>
                    <div className='popup medium' style={{height: '60vh'}}>
                        <div style={{margin: '20px'}}>Thank you for completing your interview! Your input will be processed by our AI consultant and used in your company's strategic analysis. Watch the video below to learn more.</div>
                        {video}
                        <button className='button small' onClick={() => {setInterviewEnded(false); refreshPage()}}>Exit</button>
                    </div>
                </div>
            }
            {!interviewStarted && <div className="module-description">
                <p>Welcome, {props.userInfo.username}, to your Interview portal. {introMessage}</p>
            </div>}
            {interviewStarted && <div className="module-description">
                <p>Begin or continue your interview by sending a message in the chat area. You can exit and return to resume anytime. Click the Finish button below when you are done with each interview.</p>
            </div>}
            <div className='module-ui'>
                <div className="interview-selection-box" onClick={()=>setShowGroupMenu(!showGroupMenu)}>
                    <div className="interview-menu-item">
                        <div className='interview-selection-header'>{selection}</div>
                        <div className={`interview-selection-arrow ${showGroupMenu ? 'rotated' : ''}`}></div>
                    </div>
                    {showGroupMenu && menuList}
                </div>
            </div>
            <div className="module-lowerbody">
            </div>
            {interviewStarted && <div className="button light small-text" onClick={handleEnd}>Finish Interview</div>}
        </div>
    )}
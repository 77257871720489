import React, {useState, useEffect} from 'react';
import './strategydemo.css'




export default function StrategyDemo (props) {

    const [showOne, setShowOne] = useState(false)
    const [companyInfo, setCompanyInfo] = useState({
        culture: null,
        external: null,
        internal: null,
        foundation: null,
        customer: null,
        product: null,
        operations: null,
        people: null
    })

    const wait = 1500
    const step = 1000

    useEffect(() => {
        setShowOne(true)
        const timers = [
            setTimeout(() => setCompanyInfo(prev => ({...prev, culture: 'Company Cause, Intention, and Behavior'})), wait),
            setTimeout(() => setCompanyInfo(prev => ({...prev, external: 'List of External Impacts', internal: 'List of Internal Strengths and Weaknesses'})), step + wait),
            setTimeout(() => setCompanyInfo(prev => ({...prev, foundation: 'The foundational strategy and Unifying Objective'})), 2*step + wait),
            setTimeout(() => setCompanyInfo(prev => ({...prev, customer: 'How the company will focus its customer strategy.', people: 'How the company will impact its people.', product: 'How the product will meet customer objectives.', operations: 'How operations will support the company objectives.'})), 3*step + wait),
            setTimeout(() => props.next(), 6*step + wait)
        ]
        return () => timers.forEach(timer => clearTimeout(timer))
    }, [])

    return (
        <div className={`demo-strategy ${showOne && 'show'}`}>
            <div className='standard-whiteboard-magnet tl'></div>
            <div className='standard-whiteboard-magnet tr'></div>
            <div className='standard-whiteboard-magnet br'></div>
            <div className='standard-whiteboard-magnet bl'></div>
            <div className='demo-strategy-whiteboardtitle'>Strategy Whiteboard</div>
            <div className="whiteboard-section half">
                    <div className="whiteboard-block">
                        <div className="whiteboard-block-header">Culture</div>
                        <div className="whiteboard-block-content">
                            {companyInfo.culture !== null ? 
                                <div>{companyInfo.culture}</div> : 
                                <img src="./Assets/Icons-SWB-Culture-Icon-Blue.png" 
                                    style={{width: "60%"}}>
                                </img>}
                        </div>
                    </div>
                    <div className="whiteboard-block" >
                        <div className="whiteboard-block-header red">External</div>
                        <div className="whiteboard-block-content">
                            {companyInfo.external !== null ? 
                                <div>{companyInfo.external}</div> : 
                                <img src="./Assets/Icons-SWB-External-Icon-Blue.png" 
                                    style={{width: "50%", marginTop: '6%'}}>
                                </img>}
                        </div>
                    </div>
                    <div className="whiteboard-block" >
                        <div className="whiteboard-block-header red">Internal</div>
                        <div className="whiteboard-block-content">
                            {companyInfo.internal !== null ? 
                                <div>{companyInfo.internal}</div> : 
                                <img src="./Assets/Icons-SWB-Internal-Icon-Blue.png" 
                                    style={{width: "50%", marginTop: '6%'}}>
                                </img>}
                        </div>
                    </div>
                    <div className="whiteboard-block" >
                        <div className="whiteboard-block-header">Foundation</div>
                        <div className="whiteboard-block-content">
                            {companyInfo.foundation !== null ? 
                                <div>{companyInfo.foundation}</div> : 
                                <img src="./Assets/Icons-SWB-Foundation-Icon-Blue.png" 
                                    style={{width: "60%", marginTop: '6%'}}>
                                </img>}
                        </div>
                    </div>
                </div>
                <div className="whiteboard-section half">
                    <div className="whiteboard-block" >
                        <div className="whiteboard-block-header">Customer</div>
                        <div className="whiteboard-block-content">
                            {companyInfo.customer !== null ? 
                                <div>{companyInfo.customer}</div> : 
                                <img src="./Assets/Icons-SWB-Customer-Icon-Blue.png" 
                                    style={{width: "50%"}}>
                                </img>}
                        </div>
                    </div>
                    <div className="whiteboard-block" >
                        <div className="whiteboard-block-header">Product</div>
                        <div className="whiteboard-block-content">
                            {companyInfo.product !== null ? 
                                <div>{companyInfo.product}</div> : 
                                <img src="./Assets/Icons-SWB-Product-Icon-Blue.png" 
                                    style={{width: "50%", marginTop: '4%'}}>
                                </img>}
                        </div>
                    </div>
                    <div className="whiteboard-block" >
                        <div className="whiteboard-block-header">Operations</div>
                        <div className="whiteboard-block-content">
                            {companyInfo.operations !== null ? 
                                <div>{companyInfo.operations}</div> : 
                                <img src="./Assets/Icons-SWB-Operations-Icon-Blue.png" 
                                    style={{width: "60%"}}>
                                </img>}
                        </div>
                    </div>
                    <div className="whiteboard-block" >
                        <div className="whiteboard-block-header">People</div>
                        <div className="whiteboard-block-content">
                            {companyInfo.people !== null ? 
                                <div>{companyInfo.people}</div> : 
                                <img src="./Assets/Icons-SWB-People-Icon-Blue.png" 
                                    style={{width: "50%", marginTop: '5%'}}>
                                </img>}
                        </div>
                    </div>
                </div>
        </div>
    )
}
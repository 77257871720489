import React from 'react'
import {useState, useEffect} from 'react'
import './interviewadmin.css'
import { API, graphqlOperation } from 'aws-amplify';
import * as queries from '../../../graphql/queries';
import * as mutations from '../../../graphql/mutations';



export default function InterviewAdmin(props) {
    const [interviews, setInterviews] = useState([]);
    const [view, setView] = useState('groups');
    const [tab, setTab] = useState('');
    const [conversation, setConversation] = useState('');
    const [changes, setChanges] = useState(false);
    const [selectedInterviews, setSelectedInterviews] = useState([])
    const [fullTab, setFullTab] = useState('');
    const [summaryTab, setSummaryTab] = useState('');
    const [summary, setSummary] = useState('');
    const [interview, setInterview] = useState({user: {username: ''}});
    const [interviewMessages, setInterviewMessages] = useState([]);
    const [showMenu, setShowMenu] = useState(false)
    const [showGuide, setShowGuide] = useState(false)
    const [interviewGroups, setInterviewGroups] = useState([])
    const [showMessage, setShowMessage] = useState(false)
    const [message, setMessage] = useState('')
    const [render, setRender] = useState(false)
    const [showExamine, setShowExamine] = useState(false)
    const [examinationName, setExaminationName] = useState('')

    useEffect( () => {
        async function fetchInfo() {
            if (props.userInfo.company) {
                const groups = await API.graphql(graphqlOperation(queries.listInterviewGroups, {filter: {companyInterviewGroupsId: {eq: props.userInfo.company.id}}}));
                console.log('Interview Group List:', groups.data.listInterviewGroups.items)
                setInterviewGroups(groups.data.listInterviewGroups.items)
            }
        }
        fetchInfo();
        setSelectedInterviews([])
        setSummary('')
        setInterview({user: {username: ''}})
    }, [props.userInfo, view, render]);

    useEffect(() => {
        if (selectedInterviews.filter(interview => interview.checked === true).length > 0) {
            setChanges(true)
        } else { setChanges(false) }
    }, [selectedInterviews])
  
    function handleCreateGroupClick(a) {
        if (a === 'single') {
            createGroup('comprehensive', 'Comprehensive Interview')
        } else if (a === 'topical') {
            createGroup('culture', 'Culture Interview')
            createGroup('strategicanalysis', 'Strategic Analysis Interview')
            createGroup('foundation', 'Strategic Foundation Interview')
            createGroup('focus', 'Strategic Focus Interview')
        }
        setInterviews([])
        setRender(true)
        setView('confirmation')
        setTimeout(() => {
            setView('groups')
        }, 3000)
    }

    async function createGroup(template, name) {
        const companyid = props.userInfo.company.id
        let groupid = Math.floor(100000 + Math.random() * 900000)
        try {
            await API.graphql(graphqlOperation(mutations.createInterviewGroup, {input: 
                { 
                    Groupid: groupid,
                    Groupname: name, 
                    companyInterviewGroupsId: companyid, 
                    interviewGroupTemplateTempid: template
                }
            }))
        } catch (error) { console.log("Error creating new interview group: ", error)}
    }

    const groupsWithSteps = interviewGroups.map((group) => {
        let step;
        switch (group.Template.tempid) {
            case 'culture':
                step = 1;
                break;
            case 'strategicanalysis':
                step = 2;
                break;
            case 'foundation':
                step = 3;
                break;
            case 'focus':
                step = 4;
                break;
            default:
                step = 0;
        }
        return { step, group };
    });

    groupsWithSteps.sort((a, b) => a.step - b.step)

    const groupList = groupsWithSteps.map(({ step, group }) => {
        return  <div className={'interviews-group-card ' + group.Template.tempid} key={step} onClick={() => handleClickGroup(group.Groupid)}>
                    <div className={"interviews-group-card-name " + group.Template.tempid}>{group.Template.name}</div>
                    <div className="interviews-group-card-key">
                        <div>Click to see interviews</div>
                    </div>
                </div>
    })

    const groupSetUp = (
        <div style={{display: 'flex', justifyContent: 'space-evenly', width: '100%', height: '100%', margin: '50px'}}>
            <div style={{width: '50%', height: '100%'}}>
                <div style={{width: '100%', color: '#093C5D'}}>Single-Comprehensive Strategy Interview</div>
                <div className="interviews-options-card" onClick={()=>handleCreateGroupClick('single')}>
                    <div className="interviews-options-card-text">
                        Select this option if you are limited on time and want all topics to be covered in a single interview.
                    </div>
                </div>
            </div>
            <div style={{width: '50%', height: '100%'}}>
                <div style={{width: '100%', color: '#1CA287'}}>Four Strategic Sub-topical Interviews</div>
                <div className="interviews-options-card b" onClick={()=>handleCreateGroupClick('topical')}>
                    <div className="interviews-options-card-text">
                        <div>Select this option if you want to conduct more in-depth interviews on each of the four strategic topics:</div>
                        <div style={{height: '10px'}}></div>
                        <div style={{textAlign: 'left'}}>1. Culture</div>
                        <div style={{textAlign: 'left'}}>2. Strategic Analysis</div>
                        <div style={{textAlign: 'left'}}>3. Strategic Foundation</div>
                        <div style={{textAlign: 'left'}}>4. Strategic Focus</div>
                    </div>
                </div>
            </div>
        </div>
    )

    const interviewItems = interviews.map((interview, index) => {
        if (!interview.user) {return}
        return  <div className="interview-item" key={index}>
                    <div className="tooltip-container" onClick={() => handleClickInterview(interview.intid)}></div>
                    <div className="interview-item-name select" >
                    <input 
                        type="checkbox" 
                        id={`checkbox-${index}`}
                        className="interview-item-checkbox" 
                        checked={getCheckedStatus(interview.intid)} 
                        onChange={(event) => handleCheckbox(interview.intid, event, interview.summary)}
                    />
                    <label htmlFor={`checkbox-${index}`}></label>
                    </div>
                    <div className="interview-item-name status item" onClick={() => handleClickInterview(interview.intid)}>{interview.active === false ? 'Completed' : 'Started'}</div>
                    <div className="interview-item-name item"  onClick={() => handleClickInterview(interview.intid)}>{interview.user.username}</div>
                    <div className="interview-item-name item"  onClick={() => handleClickInterview(interview.intid)}>{interview.user.role}</div>
                </div>
    })

    const content = () => {
        if (interviewItems.length === 0) {
           return <div className='interviews-body-text'>
            Interviews will be listed here once they are started. You can monitor interview progress by checking status or viewing the interview directly. Once all interviews have been completed, then proceed to the Strategy Whiteboard to build your strategy using AI.
            </div>
        } else { return interviewItems }
    }

    function handleCheckbox(intid, event, summary) {
        const isChecked = event.target.checked;
        setSelectedInterviews((prevState) => {
            const existingEntryIndex = prevState.findIndex((item) => item.intid === intid);
            if (existingEntryIndex >= 0) {
                const updatedState = [...prevState];
                updatedState[existingEntryIndex] = { ...updatedState[existingEntryIndex], checked: isChecked, summary };
                console.log('Updated State:', updatedState)
                return updatedState;
            } else {
                return [...prevState, { intid, checked: isChecked, summary }];
            }
        });
        setChanges(true);
    }

    function getCheckedStatus(intid) {
        const selectedInterview = selectedInterviews.find((interview) => interview.intid === intid);
        return selectedInterview ? selectedInterview.checked : false;
    }

    function handleSelectAll(e) {
        const isChecked = e.target.checked;
        const checkboxes = document.querySelectorAll('.interview-item-checkbox');
        checkboxes.forEach((checkbox) => (checkbox.checked = isChecked));
        const updatedSelectedInterviews = interviews.map((interview) => {
            return { intid: interview.intid, checked: isChecked, summary: interview.summary };
        });
        setSelectedInterviews(updatedSelectedInterviews);
        console.log('Selected Interviews:', updatedSelectedInterviews)
        setChanges(true);
    }

    function examine() {
        setShowExamine(true)
    }

    async function startExamination() {
        console.log('examination beginning...')
        const interviewsToExamine = selectedInterviews
            .filter(item => item.checked && 'summary' in item)
            .map(item => item.summary)
            .join('\n\n');
        let examination
        try {
            const {data} = await API.graphql(graphqlOperation(mutations.createExamination, {
                input: {companyExaminationsId: props.userInfo.company.id, name: examinationName, information: interviewsToExamine}
            }))
            examination = data.createExamination
            console.log('New Examination Created:', data.createExamination)
        } catch (error) {
            console.log('Error:', error)
        }
        
        const body = JSON.stringify({
            content: interviewsToExamine,
            examinationId: examination.id,
        })
        console.log('Examination Body:', body)
        try { 
            const response = await fetch("https://kebp5do3u7.execute-api.us-west-2.amazonaws.com/Prod/interviewExaminer", {
            method: "POST",
            headers: {"Content-Type": "application/json"},
            body: body,
            })
            const data = await response.json()
            console.log('Data Processing Response:', data)
        } catch (error) {
            console.log("Error sending data to dataprocessor:", error)
            return
        }
        props.selectModule('analysis');
        props.selectView('examination')
        props.info(examination)
    }

    async function handleRemoveInterviews() {
        const interviewsToRemove = selectedInterviews
        const removeInterviewPromises = interviewsToRemove.map((interview) => 
            API.graphql(graphqlOperation(mutations.updateInterview, {input: {intid: interview.intid, process: false}}))
        )
        try {
            await Promise.all(removeInterviewPromises)
            console.log('Interviews removed:', removeInterviewPromises)
            const remainingInterviews = interviews.filter((interview) => {
                return !interviewsToRemove.some((selected) => selected.intid === interview.intid)
            })
            setChanges(false)
            setSelectedInterviews([])
            setInterviews(remainingInterviews)
        } catch (error) {
            console.log(error)
        }
    }

    function confirmDelete() {
        setShowMessage(true)
        setMessage('Are you sure you want to delete the selected interviews?')
    }

    function handleDelete() {
        const interviewsToDelete = selectedInterviews.filter((interview) => interview.checked === true)
        console.log('Interviews to delete:', interviewsToDelete)
        const deleteInterviewPromises = interviewsToDelete.map((interview) => 
            API.graphql(graphqlOperation(mutations.deleteInterview, {input: {intid: interview.intid}}))
        )
        Promise.all(deleteInterviewPromises)
        console.log('Interviews deleted:', deleteInterviewPromises)
        setChanges(false)
        setSelectedInterviews([])
    }

    async function handleClickInterview(id) {
        setView('details')
        handleSummaryClick()
        const {data} = await API.graphql(graphqlOperation(queries.getInterview, {intid: id}))
        const response = await API.graphql(graphqlOperation(queries.getChatLog, {logid: data.getInterview.conversation.items[0].logid}))
        const interview = data.getInterview
        const username = interview.user.username
        const messages = (response.data.getChatLog.messages.items)
        const sortedMessages = messages.sort((a, b) => {
            return new Date(a.updatedAt) - new Date(b.updatedAt);
          })
        const chatLog = sortedMessages.map((message) => {
            let user = message.user;
            let style = '';
            if (message.user === 'user') {
                user = username;
                style = 'interview-message user'
            } else if (message.user === 'assistant') {
                user = 'AI Consultant';
                style = 'interview-message'
            }
            return <div 
            className={style}
            key={message.msgid}
            >
                {user + ": " + message.content}
            </div>
        })
        const interviewSummary = <div className="interview-summary">{data.getInterview.summary}</div>
        setSummary(interviewSummary)
        setConversation(chatLog)
        setInterview(interview)
        setInterviewMessages(sortedMessages)
    }

    async function handleClickGroup(id) {
        setView('list')
        const group = await API.graphql(graphqlOperation(queries.listInterviews, {
            limit: 10000,
            filter: {
                interviewGroupInterviewsGroupid: {eq: id},
                process: {eq: true}
            }
        }))
        console.log('Interviews:', group.data.listInterviews.items)
        setInterviews(group.data.listInterviews.items)
    }

    function handleFullClick() {
        setTab('full')
        setFullTab('active')
        setSummaryTab('')
    }

    function handleSummaryClick() {
        setTab('summary')
        setFullTab('')
        setSummaryTab('active')
    }


    async function summarizeInterview() {
        setView('processing')
        const { data } = await API.graphql(graphqlOperation(queries.getPrompt, {id: 'default'}));
        let instruction = data.getPrompt.interviewSummary
        const topic = interview.group.interviewGroupTemplateTempid
        if (topic === 'culture') {
            instruction = data.getPrompt.intsummarycult
        } else if (topic === 'strategicanalysis') {
            instruction = data.getPrompt.intsummarysa
        } else if (topic === 'foundation') {
            instruction = data.getPrompt.intsummaryfound
        } else if (topic === 'focus') {
            instruction = data.getPrompt.intsummaryfocus
        }
        await API.graphql(graphqlOperation(mutations.updateInterview, {input: {intid: interview.intid, active: false}}))
        const cleanedMessages = interviewMessages.map(message => ({user: message.user, message: message.content}))
        const content = cleanedMessages.map(message => {
            let userLabel = '';
            if (message.user === 'assistant') {
                userLabel = 'interviewer';
            } else if (message.user === 'user') {
                userLabel = 'interviewee';
            } else {
                userLabel = message.user; // default case, in case there are other values for message.user
            }
            return `${userLabel}: ${message.message}`;
        }).join('\n\n');
        try { await fetch("https://2uzjzfregh.execute-api.us-west-2.amazonaws.com/dev/dataprocessor", {
            method: "POST",
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify({
                instruction: instruction,
                content: content,
                id: interview.intid,
                profile: props.userInfo.company.profile,
                }),
            })
        } catch (error) {console.log("Error sending data to dataprocessor:", error)}
        await API.graphql(graphqlOperation(mutations.updateInterview, {input: {intid: interview.intid, summary: null}}))
        polling()
    }

    async function polling () {
        const {data} = await API.graphql(graphqlOperation(queries.getInterview, {intid: interview.intid}))
        console.log('polling: ', data.getInterview)
        if (data.getInterview.summary) {
            handleClickInterview(interview.intid)
        } else {
            setTimeout(polling, 5000)
        }
    }


    const instructionsText = [
        'If you limited on time and want to simply cover the basics, then a single interview may be the best option',
        'The comprehensive interview will cover all strategic topics in order to output a complete Strategy Whiteboard',
        'The depth of each topic will be limited and may not provide the level of detail you need to make strategic decisions',
        'This is a great entry point to get started with the AI Consultant and can be followed up with more in-depth interviews on specific topics'
    ];

    const instructionsTextTwo = [
        'Culture: Explores the company culture and interpersonal dynamics of the organization.',
        'Strategic Analysis: Evaluates External impacts and Internal strengths/weaknesses which influence strategic decsions.',
        'Strategic Foundation: Establishes the foundational strategy and sets a direction by defining a unifying objective.',
        'Strategic Focus: Finds the areas of focus through the contextual lenses of Customer, Product, Operations, and People.',
    ];

    const interviewButton = (
        <button className="button small" style={{fontSize: '20px', width: '150px'}}onClick={()=>props.selectModule('interview')}>My Interview</button>
    )

    const interviewGroupMessage = () => {
        if (interviewGroups.length === 1) {
            return (<div>Click on a Interview Group card to see all the interviews within that group that have been completed or are in progress. Click {interviewButton} to take your own interview.</div>)
        } else if (interviewGroups.length > 1) {
            return (<div>Click on a Interview Group card to see all the interviews within that group that have been completed or are in progress. Click {interviewButton} to take your own interview.</div>)
        } else {
            return ('Choose an interview option below to begin. You can choose a single, comprehensive interview or four in-depth topical interviews. This choice will apply to all users in your organization and cannot be changed.')
        }
    }
    
    const guideInstructions = (
        <div className='standard-guide-instructions-box'>
            <div className='standard-guide-instructions-title'>Strategic interviews provide the primary input for developing your Strategy Whiteboard. It is important that those interviewed provide thorough and thoughtful responses.</div>
            <div className='standard-divider long' style={{margin: 'auto', marginTop: '15px', marginBottom: '15px'}}></div>
            <div className='standard-guide-instructions-header'>Single, Comprehensive Strategic Interview</div>
            {instructionsText.map((text, index) => (
                <div key={index} className='standard-guide-instructions-lineitem'>
                    <div className='standard-guide-instructions-bullet'></div>
                    <div className='standard-guide-instructions-text'>{text}</div>
                </div>
            ))}
            <div className='standard-guide-instructions-header'>Four Strategic Sub-topic Interviews</div>
            {instructionsTextTwo.map((text, index) => (
                <div key={index} className='standard-guide-instructions-lineitem'>
                    <div className='standard-guide-instructions-bullet'></div>
                    <div className='standard-guide-instructions-text'>{text}</div>
                </div>
            ))}
        </div>
    )

    const menu = (
        <div>
            <div className='standard-menu-spacer'></div>
            {view === 'list' && <div className={`standard-menu-item ${!changes && 'disabled'}`} onClick={()=>{if (changes) {examine(); setShowMenu(false)}}}>Examine Interviews</div>}
            {view === 'list' && <div className={`standard-menu-item ${!changes && 'disabled'}`} onClick={()=>{if (changes) {confirmDelete(); setShowMenu(false)}}}>Delete Interviews</div>}
            {view === 'details' && <div className='standard-menu-item' onClick={()=>{summarizeInterview(); setShowMenu(false)}}>Summarize Again</div>}
            <div className='standard-menu-item' onClick={()=>{setShowGuide(true); setShowMenu(false)}}>Instruction Guide</div>
        </div>
    )

    const backButton = () => {
        if (view === 'list'){
            return (<button className="button light small" onClick={() => setView('groups')}>Back</button>)
        }   else if (view === 'details') {
            return (<button className="button light small" onClick={() => setView('list')}>Back</button>)
        }   else {
            return null
        }
    }
    
    const detailedHeader = () => {
        if (tab === 'summary' && interview.user.username !== '') {
            return (': Interview Summary')
        } else if (tab === 'full' && interview.user.username !== '') {
            return (': Full Interview Log')
        } else {
            return ('Loading Interview...')
        }}


    return (
        <div className="standard-main">
            {showMessage && <div className='popup-mask standard'>
                <div className='popup small' style={{justifyContent: 'space-around'}}>
                    <div>{message}</div>
                    <div>
                        <button className="button small" onClick={()=>{handleRemoveInterviews(); setShowMessage(false)}}>Yes</button>
                        <button className="button small" onClick={() => setShowMessage(false)}>No</button>
                    </div>
                </div>
            </div>
            }
            {showExamine && <div className='popup-mask standard'>
                <div className='popup small' style={{justifyContent: 'space-around'}}>
                    <div>Create a name for this Examination</div>
                    <input value={examinationName} onChange={(e)=>setExaminationName(e.target.value)}></input>
                    <div>
                        <button className="button small" onClick={()=>{startExamination(); setShowExamine(false)}}>Examine</button>
                        <button className="button small" onClick={() => setShowExamine(false)}>Cancel</button>
                    </div>
                </div>
            </div>
            }  
            {showGuide && <div className='popup-mask standard' >
                <div className='popup' style={{justifyContent: 'space-between', width: '80%'}}>
                    {guideInstructions}
                    <div>
                        <button className="button small" onClick={() => setShowGuide(false)}>Close</button>
                    </div>
                </div>
            </div>
            }
            <div className="standard-box header">
                <div className="standard-button-container">
                    {backButton()}
                    {view === 'groups' && <button className="button light small" style={{width: '150px'}} onClick={()=>props.selectModule('interview')}>My Interview</button>}
                    {view === 'details' &&
                        <div className="interviews-tabs-container">
                            <div className={`interviews-tab ${fullTab && 'active'}`} onClick={handleFullClick}>Full</div>
                            <div className={`interviews-tab ${summaryTab && 'active'}`} onClick={handleSummaryClick}>Summary</div>
                        </div>
                    }
                </div>
                <div className="standard-box-title">Strategy Interviews Dashboard</div>
                <div className="standard-menu-container">
                    <div className='standard-menu-button' onClick={() => setShowMenu(!showMenu)}>
                        <div className='standard-dropdown-menu-title'>Actions</div>
                        <div className={`standard-dropdown-menu-graphic ${showMenu && 'rotated'}`}></div>
                    </div>
                    {showMenu && <div className='standard-dropdown-menu-box'>
                        {menu}
                    </div>}
                </div>
            </div>
            <div className="standard-box">
                    <div className='standard-whiteboard-magnet tl'></div>
                    <div className='standard-whiteboard-magnet tr'></div>
                    <div className='standard-whiteboard-magnet br'></div>
                    <div className='standard-whiteboard-magnet bl'></div>
                {view === 'groups' ? <div className="interviews-main">
                    <div className="interviews-section" style={{justifyContent: 'flex-start'}}>
                        <div style={{display: 'flex', width: '30%', height: '100%', justifyContent: 'center'}}><img src="./Assets/InterviewGroup-icon.png" style={{}}></img></div>
                        <div style={{width: '70%', height: '100%', display: 'flex', alignItems: 'center', paddingRight: '20px'}}>{interviewGroupMessage()}</div>
                    </div>
                    <div className="interviews-section">
                        {view === 'groups' && groupList.length > 0 ? groupList : groupSetUp }
                    </div>
                </div> : null}
                {view === 'confirmation' && <div className="interviews-main">
                    <div style={{display: 'flex', height: '100%', justifyContent: 'center', alignItems: 'center'}}>
                        Thank you for your selection. Your interviews are being intialized.
                    </div>
                </div>}
                {view === 'list' && <div className="interviews-body-header">
                    <div className="interview-item-name select" style={{height: '70%'}}>
                        <input 
                            type="checkbox" 
                            id="select-all-checkbox"  /* Assign unique id for the checkbox */
                            className="interview-item-checkbox" 
                            onChange={(e) => handleSelectAll(e)}
                        />
                        <label htmlFor="select-all-checkbox"></label>
                    </div>
                    <div className="interview-item-name status">Status</div>
                    <div className="interview-item-name">Name</div>
                    <div className="interview-item-name">Role</div>
                </div>}
                {view === 'details' && <div className="interviews-body-header" style={{width: '90%'}}>
                    <div className='interview-item-name' style={{justifyContent: 'center', fontSize: '50px'}}>
                        {`${interview.user.username}${detailedHeader()}`}
                    </div>
                </div>}
                {view === 'list' || view === 'details' ? <div className='standard-divider long'></div> : null}
                {view === 'list' && <div className="interviews-list">
                    {content()}
                </div>}
                {view === 'details' && <div className="interviews-list summary">
                    {tab === 'full' ? conversation : tab === 'summary' ? summary : null}
                </div>}
                {view === 'processing' && <div className='standard-graphic-animation processing'></div>}
                {view === 'processing' && <div className="build-whiteboard-message">A new Interview Summary is being generated by the AI Consultant. This may take a few minutes.</div>}
            </div>
        </div>
    )
}
import React, {useState, useEffect} from 'react'
import './access.css'
import { API, graphqlOperation } from 'aws-amplify'; 
import {Auth} from 'aws-amplify';
import * as queries from '../../graphql/queries'; //imports all queries
import * as mutations from '../../graphql/mutations'; //imports all mutations
import logo from './full-logo_gradient.png'
import Demo from '../animations/Demo'
import { InlineWidget } from 'react-calendly'


export default function Access(props){
    const [validKey, setValidKey] = useState(false)
    const [companyId, setCompanyId] = useState('')
    const [inputCode, setInputCode] = useState('')
    const [password, setPassword] = useState('');
    const [username, setUsername] = useState('');
    const [view, setView] = useState('signIn')
    const [companyName, setCompanyName] = useState('')
    const [industry, setIndustry] = useState('')
    const [permissions, setPermissions] = useState('')
    const [userRole, setUserRole] = useState('')
    const [userName, setUserName] = useState('')
    const [userEmail, setUserEmail] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [verification, setVerification] = useState('')
    const [error, setError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [selected, setSelected] = useState('join')
    const [showCalendly, setShowCalendly] = useState(false);

    useEffect (() => {
        
    }, [])

    useEffect(() => {
        async function fetchCompanyList() {
            try {
                const {data} = await API.graphql(graphqlOperation(queries.listCompanies))
                let companyList = data.listCompanies.items
                let companyIds = companyList.map(company => company.id)
                let valid = companyIds.some(id => id === companyId)
                setValidKey(valid)
                if (valid) {
                    const company = companyList.find(company => company.id === companyId)
                    console.log('Company:', company)
                }
            } catch (error) {
                console.log('Error fetching Interview Groups:', error);
            }
        }
        fetchCompanyList();
    }, [companyId]);

    async function createCognitoUser() {
        try {
            const newUser = await Auth.signUp({
                username: userEmail,
                password: newPassword,
                attributes: {
                    email: userEmail
                }
            })
            console.log("Created new cognito user:", newUser)
            setView('verify')
            return newUser.userSub
        } catch (error) {
            console.log('Error creating cognito user:', error);
            setError(true)
            setErrorMessage(error.message)
            return null
        }
    }

    async function verifyCognitoUser() {
        try {
            const verifiedUser = await Auth.confirmSignUp(userEmail, verification)
            console.log("Verified user:", verifiedUser)
            setView('firstSignIn')
        } catch (error) {
            console.log('Error verifying cognito user:', error);
            setError(true)
            setErrorMessage(error.message)
        }
    }

    async function resend() {
        try {
            await Auth.resendSignUp(userEmail);
            console.log('Confirmation code resent successfully');
            setError(true)
            setErrorMessage('Confirmation code resent.')
        } catch (error) {
            console.error('Error resending confirmation code', error);
            setError(true)
            setErrorMessage(error.message)
        }
    }

    async function signUp() {
        if (newPassword !== confirmPassword) {
            setError(true)
            setErrorMessage('Passwords do not match.')
        } else {
            const userId = await createCognitoUser()
            let randNum = Math.floor(100000 + Math.random() * 900000)
            const companyId = 'cv2-' + randNum.toString()
            const companyInput = {
                id: companyId,
                name: companyName,
                industry: industry,
                profile: '',
            }
            const userInput = {
                userid: userId,
                username: userName,
                emailid: userEmail,
                permissions: 'Admin',
                role: userRole,
                companyUserId: companyId
            }
            if (userId) {
            createCompany(companyInput)
            createUser(userInput)
            }
        }
    }

    async function createUser(input) {
        try {
            const newUser = await API.graphql(graphqlOperation(mutations.createUser, { input: input}));
            console.log("Created new user:", newUser.data.createUser)
        } catch (error) {
            console.log('Error creating user:', error);
        }
    }

    async function createCompany (input) {
        try {
            const newCompany = await API.graphql(graphqlOperation(mutations.createCompany, { input: input}));
            console.log("Created new company:", newCompany.data.createCompany)
        } catch (error) {
            console.log('Error creating company:', error);
        }
    }
    

    async function handleJoin (){
          if (!validKey) {
            setError(true)
            setErrorMessage('Please enter a valid company access code.')
          } else {
            if (newPassword !== confirmPassword) {
                setError(true)
                setErrorMessage('Passwords do not match.')
            } else {
                const userId = await createCognitoUser()
                const userInput = {
                    userid: userId,
                    username: userName,
                    emailid: userEmail,
                    permissions: permissions,
                    role: userRole,
                    companyUserId: companyId
                }
                createUser(userInput)
            }
          }
          setView('verify')
      }

      function handleKey (e) {
        const input = e.target.value
        const code = input.slice(1)
        setInputCode(input)
        setCompanyId(code)
        const firstChar = input.charAt(0)
        if (firstChar === 'A') {
            setPermissions('Admin')
        } else if (firstChar === 'S') {
            setPermissions('Standard')
        }
      }

      async function handleSignIn (e) {
        e.preventDefault();
        console.log('username:', username, 'password:', password)
        try {
          await Auth.signIn(username, password);
          const user = await Auth.currentAuthenticatedUser();
          props.authentication(true);
          console.log('user:', user)
          return user
        } catch (error) {
          console.log('error signing in', error);
            if (error.code === 'UserNotConfirmedException') {
                setUserEmail(username)
                setView('verify')
            } else {
            setError(true)
            setErrorMessage(error.message)
            }
        }
      }

      async function resetPassword () {
        try {
            await Auth.forgotPassword(username);
            console.log('Reset password email sent successfully');
            setError(true)
            setErrorMessage('Reset password email sent successfully.')
            setView('reset')
        } catch (error) {
            console.error('Error resending confirmation code', error);
            setError(true)
            setErrorMessage(error.message)
        }
      }

      async function changePassword () {
        try {
            await Auth.forgotPasswordSubmit(username, verification, password);
            setView('firstSignIn')
        } catch (error) {
            console.error('Error resending confirmation code', error);
            setError(true)
            setErrorMessage(error.message)
        }
      }


    return(
        <div className="new-user-start-main">
            {error && <div className='popup-mask'>
                <div className='popup small'>
                    <div>{errorMessage}</div>
                    <button className="button" onClick={() => setError(false)}>Ok</button>
                </div>
            </div>
            }
            {error && <div className='popup-mask'>
                <div className='popup small'>
                    <div>{errorMessage}</div>
                    <button className="button" onClick={() => setError(false)}>Ok</button>
                </div>
            </div>
            } 
            {showCalendly && <div className='calendly'>
                    <InlineWidget url="https://calendly.com/dan-bruder-blendification/ai-strategy-consultant-demo" />
                    <button className="button small" style={{position: 'absolute', bottom: '10px'}} onClick={() => setShowCalendly(false)}>Close</button>
            </div>
            }
            {view === 'signIn' ? <div className="new-user-section">
                <div className='new-user-subsection'>
                    <a href="https://www.blendification.com" target="_blank" rel="noopener noreferrer" style={{width: '60%'}}>
                        <img src={logo} style={{width: "100%"}}/>
                    </a>
                </div>
                <div className='new-user-subsection choice'>
                    <div className='new-user-subheader'>Sign in to your Company</div>
                    <div className='new-user-option-container vertical'>
                        <input className='new-user-create-input' placeholder='enter email id' name="username" value={username} onChange={(e) => setUsername(e.target.value)}></input>
                        <input className='new-user-create-input' type="password" placeholder='enter password' name="password" value={password} onChange={(e) => setPassword(e.target.value)}></input>
                        <button className="button" onClick={handleSignIn}>Sign In</button>
                        <button className="button text" style={{fontSize: '15px'}} onClick={resetPassword}>Forgot password?</button>
                    </div>
                    <div className='new-user-option-container vertical'>
                        <div className='new-user-subheader'>Don't have an account yet?</div>
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            <button className="button small" onClick={() => setView('createNew')}>Sign Up</button>
                            <div>Or</div>
                            <button className="button small" onClick={() => setShowCalendly(true)}>See Demo</button>
                        </div>
                    </div>
                </div>
            </div>
            : view === 'createNew' ?
            <div className="new-user-section">
                <div className='new-user-subsection create'>
                    <div className='new-user-subheader'>Join an existing company or create a new company account</div>
                    <div className='new-user-choice-slider'>
                        <div className={`new-user-slider-button ${selected === 'join' && 'selected'}`} onClick={(e)=>setSelected('join')}>Join Existing</div>
                        <div className={`new-user-slider-button ${selected === 'new' && 'selected'}`} onClick={(e)=>setSelected('new')}>Create New</div>
                    </div>
                    <div className='new-user-subtext'>
                        {selected === 'join' ? 'Enter the interview access key that was sent to you by your company administrator, and complete the form to get started with your interview.'
                        : selected === 'new' ? 'Complete the form below to create a new company account.'
                        : null
                        }
                    </div>
                    <div className='new-user-option-container vertical'>
                        { selected === 'new' ?
                        <input className='new-user-create-input' placeholder='enter company name' value={companyName} onChange={(e) => setCompanyName(e.target.value)}></input>
                        : selected === 'join' ?
                        <input className={'new-user-create-input' + (validKey ? " valid" : "")} placeholder='enter access code' value={inputCode} onChange={handleKey}></input>
                        : null
                        }
                        { selected === 'new' &&
                        <input className='new-user-create-input' placeholder='enter industry' value={industry} onChange={(e)=> setIndustry(e.target.value)}></input>
                        }
                        <input className='new-user-create-input' placeholder='enter first and last name' value={userName} onChange={(e) => setUserName(e.target.value)}></input>
                        <input className='new-user-create-input' placeholder='enter job title or role' value={userRole} onChange={(e) => setUserRole(e.target.value)}></input>
                        <input className='new-user-create-input' placeholder='enter new email id' value={userEmail} onChange={(e) => setUserEmail(e.target.value)}></input>
                        <input className='new-user-create-input' type='password' placeholder='new password' value={newPassword} onChange={(e) => setNewPassword(e.target.value)}></input>
                        <input className='new-user-create-input' type='password' placeholder='confirm password' value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)}></input>
                    </div>
                    <div className='new-user-option-container center'>
                        { selected === 'join' ?
                        <button className="button" onClick={handleJoin}>Submit</button>
                        : selected === 'new' ?
                        <button className="button" onClick={signUp}>Create</button> : null
                        }
                        <button className="button" onClick={() => setView('signIn')}>Cancel</button>
                    </div>
                </div>
            </div>
            : view === 'verify' ?
            <div className="new-user-section">
                <div className='new-user-subsection verify'>
                    <div className='new-user-subheader'>Verify Email Address</div>
                    <div className='new-user-subtext'>
                        Enter the verification code that was sent to your email address to finish setting up your account.
                    </div>
                    <div className='new-user-option-container vertical'>
                        <input className='new-user-create-input' placeholder='enter email id' value={userEmail} onChange={(e) => setUserEmail(e.target.value)}></input>
                        <input className='new-user-create-input' placeholder='enter verification code' value={verification} onChange={(e) => setVerification(e.target.value)}></input>
                    </div>
                    <div className='new-user-option-container center'>
                        <button className="button" onClick={verifyCognitoUser}>Verify</button>
                        <button className="button" onClick={resend}>Send Again</button>
                    </div>
                </div>
            </div>
            : view === 'reset' ?
            <div className="new-user-section">
                <div className='new-user-subsection verify'>
                    <div className='new-user-subheader'>Reset Your Password</div>
                    <div className='new-user-subtext'>
                        Enter a new password the verification code that was sent to your email address.
                    </div>
                    <div className='new-user-option-container vertical'>
                        <input className='new-user-create-input' placeholder='enter email id' value={username} onChange={(e) => setUsername(e.target.value)}></input>
                        <input className='new-user-create-input' placeholder='enter new password' type='password' value={password} onChange={(e) => setPassword(e.target.value)}></input>
                        <input className='new-user-create-input' placeholder='enter verification code' value={verification} onChange={(e) => setVerification(e.target.value)}></input>
                    </div>
                    <div className='new-user-option-container center'>
                        <button className="button" onClick={changePassword}>Submit</button>
                        <button className="button" onClick={() => setView('signIn')}>Cancel</button>
                    </div>
                </div>
            </div>
            : view === 'firstSignIn' ?
            <div className="new-user-section">
                <div className='new-user-subsection verify'>
                    <div className='new-user-subheader'>Success!</div>
                    <div className='new-user-subtext'>Your account has been created. Sign in to get started.</div>
                    <div className='new-user-option-container vertical'>
                        <input className='new-user-create-input' placeholder='enter email id' name="username" value={username} onChange={(e) => setUsername(e.target.value)}></input>
                        <input className='new-user-create-input' type="password" placeholder='enter password' name="password" value={password} onChange={(e) => setPassword(e.target.value)}></input>
                        <button className="button" onClick={handleSignIn}>Sign In</button>
                    </div>
                </div>
            </div>
            : null
            }
            <Demo/>
        </div>
    )}
import React, {useState, useEffect} from 'react'
import {API, graphqlOperation} from 'aws-amplify';
import * as mutations from '../../../graphql/mutations';
import * as queries from '../../../graphql/queries';


export default function Examination(props) {

    const [examinationInfo, setExaminationInfo] = useState({name: '', result: ''})
    const [result, setResult] = useState()

    useEffect(() => {
        if (props.info) {
            setExaminationInfo(props.info)
            console.log('Examination Info Set:', props.info)
        }
    }, [props.examinationInfo])

    useEffect(() => {
        if (examinationInfo.result) {
            // Only call styleMainheadings, as it now includes subheadings styling
            const styledText = styleMainheadings(examinationInfo.result);
            setResult(styledText);
        } else if (examinationInfo.id) {
            setTimeout(fetchExamination, 5000);
        }
    }, [examinationInfo]);

    async function fetchExamination () {
        try {
            const {data} = await API.graphql(graphqlOperation(queries.getExamination, {id: examinationInfo.id}))
            console.log('Examination:', data.getExamination)
            setExaminationInfo(data.getExamination)
        } catch (error) {
            console.log('Error:', error)
        }
    }

    function styleMainheadings(text) {
        const parts = text.split(/\*\*(.*?)\*\*/g);
        return (
            <span>
                {parts.map((part, index) =>
                    index % 2 === 1
                    ? <strong key={index}>{styleSubheadings(part)}</strong>
                    : styleSubheadings(part)
                )}
            </span>
        );
    }
    
    function styleSubheadings(text) {
        const parts = text.split(/\*(.*?)\*/g);
        return parts.map((part, index) =>
            index % 2 === 1 ? <em key={index}>{part}</em> : part
        );
    }
      

    return (
        <div className="standard-box" style={{height: '85vh'}}>
                    <div className='standard-whiteboard-magnet tl'></div>
                    <div className='standard-whiteboard-magnet tr'></div>
                    <div className='standard-whiteboard-magnet br'></div>
                    <div className='standard-whiteboard-magnet bl'></div>
                    <div className="profile-header">
                        <div className="profile-header-text">{examinationInfo.name}</div>
                    </div>
                    {!result ? <div className='standard-graphic-animation processing'></div> :
                        <div className="analysis-examination-result">{result}</div>
                    }
            </div>
    )
}
import React, {useState, useEffect} from 'react';
import {API, graphqlOperation} from 'aws-amplify';
import * as queries from '../../../graphql/queries.js';
import * as mutations from '../../../graphql/mutations.js';
import '../../AppSWB/swb.css'

export default function DetailedBlock(props){

    const [edit, setEdit] = useState(false)
    const [blockContent, setBlockContent] = useState(props.details.info)
    const [headerColor, setHeaderColor] = useState('')
    const isProcessing = props.isProcessing

    useEffect(() => {
        setEdit(props.edit)
    }, [props.edit])

    useEffect(() => {
        setBlockContent(props.details.info)
        if (props.details.name === 'Culture' || props.details.name === 'Foundation') {
            setHeaderColor('blue')
        } else if (props.details.name === 'External' || props.details.name === 'Internal') {
            setHeaderColor('red')
        } else setHeaderColor('green')
    }, [props.details.info])
    
    const button = (
        <button className="button text" 
        style={{fontFamily: 'patrick hand', fontWeight: 'bold', fontSize: '30px'}} 
        onClick={props.process}>
            Generate
        </button>
    )

    console.log('Detailed Block Props:', props.details)

    const instructions = (
        <div className="detailed-block-body">
            <div className="detailed-block-image-container">
                <img src="./Assets/brain.png" 
                    style={{width: "100%", marginTop: '6%'}}>
                </img></div>
            <div className="detailed-block-instructions">This Strategy Whiteboard block has not yet been generated. Once all interviews have been completed, then click {button} to proceed.</div>
        </div>
    )

    const content = () => {
        if (props.details.info !== null && props.details.info !== '') {
            return blockContent
        } else if (isProcessing){
            return 'Your strategy whiteboard block is being generated. This may take a moment.'
        }  else {
            return instructions
        }
    }

    const blockHeader = (
        <div className="large-swb-block-header">
            <div className="swb-block-menu-container"></div>
            <div className={`swb-block-title ${headerColor}`}>{props.details.name}</div>
            <div className="swb-block-menu-container"></div>
        </div>)

    return(
        <div className="standard-box" style={{height: '85vh'}}>
            <div className='standard-whiteboard-magnet tl'></div>
            <div className='standard-whiteboard-magnet tr'></div>
            <div className='standard-whiteboard-magnet br'></div>
            <div className='standard-whiteboard-magnet bl'></div>
            <div className="detailed-block-header">
                {blockHeader}
            </div>
            {!edit ? <div className="detailed-block-content">{content()}</div> :
            <div className="detailed-block-content">
                <textarea className="detailed-block-textarea" 
                    value={blockContent} 
                    onChange={(e)=>{props.update(e.target.value); setBlockContent(e.target.value)}}/>
            </div>}
            {isProcessing && <div className='standard-graphic-animation processing'></div>}
        </div>
    )
}
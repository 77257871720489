import React, { useState, useEffect } from 'react';
import './summarydemo.css'

export default function SummaryDemo(props) {

    const [showOne, setShowOne] = useState(false);
    const [showTwo, setShowTwo] = useState(false);
    const [showThree, setShowThree] = useState(false);
    const [showFour, setShowFour] = useState(false);
    const [showFive, setShowFive] = useState(false);
    const [showSix, setShowSix] = useState(false);

    const wait = 1500;
    const step = 1000;

    useEffect(() => {
        const timers = [
            setTimeout(() => setShowOne(true), wait),
            setTimeout(() => setShowTwo(true), step + wait),
            setTimeout(() => setShowThree(true), 2 * step + wait),
            setTimeout(() => setShowFour(true), 2 * step + wait),
            setTimeout(() => setShowFive(true), 4 * step + wait),
            setTimeout(() => setShowSix(true), 5 * step + wait),
            setTimeout(() => props.next('strategy'), 8 * step + wait)
        ];
        return () => timers.forEach(timer => clearTimeout(timer));
    }, []);

    return (
        <div className="demo-summary">
            <div className={`demo-summary-message tl ${showOne ? 'show' : ''}`}>
                <img src="./Assets/message-bubble.png" style={{ width: '200px' }} />
            </div>
            <div className={`demo-summary-message tr ${showTwo ? 'show' : ''}`}>
                <img src="./Assets/message-bubble.png" style={{ width: '200px' }} />
            </div>
            <div className={`demo-summary-message br ${showThree ? 'show' : ''}`}>
                <img src="./Assets/message-bubble.png" style={{ width: '200px' }} />
            </div>
            <div className={`demo-summary-message bl ${showFour ? 'show' : ''}`}>
                <img src="./Assets/message-bubble.png" style={{ width: '200px' }} />
            </div>
            <div className={`demo-summary-graphic ${showFive && 'show'} ${showSix && 'spinning'}`}>
                <img src="./Assets/network.png" style={{ width: '400px' }} />
            </div>
        </div>
    );
}
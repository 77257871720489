import React, {useState, useEffect, useRef} from 'react';
import './interviewdemo.css'




export default function InterviewDemo (props) {

    const [showOne, setShowOne] = useState(false)
    const [showTwo, setShowTwo] = useState(false)
    const [showThree, setShowThree] = useState(false)
    const [showFour, setShowFour] = useState(false)
    const [showFive, setShowFive] = useState(false);

    const wait = 1500
    const step = 1000

    const demoInterviewRef = useRef(null);
    const scrollToBottom = () => {
        if (demoInterviewRef.current) {
            demoInterviewRef.current.scrollTop = demoInterviewRef.current.scrollHeight;
        }
    };

    useEffect(() => {
        scrollToBottom();
    }, [showOne, showTwo, showThree, showFour, showFive]);

    useEffect(() => {
        const timers = [
            setTimeout(() => setShowOne(true), wait),
            setTimeout(() => setShowTwo(true), step + wait),
            setTimeout(() => setShowThree(true), 2*step + wait),
            setTimeout(() => setShowFour(true), 3*step + wait),
            setTimeout(() => setShowFive(true), 4*step + wait),
            setTimeout(() => props.next('summary'), 6*step + wait)
        ]
        return () => timers.forEach(timer => clearTimeout(timer))
    }, [])

    return (
        <div className="demo-interview" ref={demoInterviewRef}>
            {showOne && <div className="demo-interview-message right">Hello, I am, Fusion, a Virtual Strategy Consultant. Let's talk about your company Strategy.</div>}
            {showTwo && <div className="demo-interview-message">Ok, I am ready to start.</div>}
            {showThree && <div className="demo-interview-message right">We will first examine what external factors are likely to impact your company. What Technological changes do you anticipate will affect your business?</div>}
            {showFour && <div className="demo-interview-message">There are new techniques for synthesizing materials that could greatly reduce our manufacturing cost.</div>}
            {showFive && <div className="demo-interview-message right">That is great to know. Let's dig deeper into that topic. What percentage of cost reduction do you expect will result from these new techniques?</div>}
        </div>
    )
}
/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_appsync_graphqlEndpoint": "https://exo3vwvadne3rmmnioss32qfgi.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-7ap5xdk4qnez5mxhejtjywaevy",
    "aws_cloud_logic_custom": [
        {
            "name": "labrestapi",
            "endpoint": "https://2uzjzfregh.execute-api.us-west-2.amazonaws.com/dev",
            "region": "us-west-2"
        }
    ],
    "aws_cognito_identity_pool_id": "us-west-2:18acf370-0bb1-4ee2-bfe5-a0d67d58aa41",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_wBjgN9vRT",
    "aws_user_pools_web_client_id": "7k1fpip5soji9hvhqj74bnifc2",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "datafilereferences215155-dev",
    "aws_user_files_s3_bucket_region": "us-west-2"
};


export default awsmobile;

import './Main.css';
import './components/standard.css'
import React, { useState, useEffect} from 'react';
import '@aws-amplify/ui-react/styles.css';
import {Auth} from 'aws-amplify';
import {API, graphqlOperation} from 'aws-amplify';
import * as queries from './graphql/queries';
import * as customQueries from './CustomQueries';
import Home from './components/Home/Home';
import HomeSidePanel from './components/Home/HomeSidePanel';
import ChatLog from './components/AppCoFocus/ModuleInterview/ChatLog';
import Interview from './components/AppCoFocus/ModuleInterview/Interview';
import Admin from './components/SuperAdmin/Admin';
import Navigation from './components/AppCoFocus/Navigation';
import WhiteboardNav from './components/AppCoFocus/ModuleWhiteboard/WhiteboardNav';
import CompanyDescription from './components/AppCoFocus/ModuleProfile/CompanyDescription';
import Users from './components/Home/Users';
import Profile from './components/AppCoFocus/ModuleProfile/Profile';
import InterviewAdmin from './components/AppCoFocus/ModuleInterview/InterviewAdmin';
import NextSteps from './components/NextSteps';
import SWB from './components/AppSWB/SWB';
import SwbSidePanel from './components/AppSWB/SwbSidePanel';
import Roots from './components/AppRootsGroups/Roots';
import RootsSidePanel from './components/AppRootsGroups/RootsSidePanel';
import Analysis from './components/AppCoFocus/ModuleAnalysis/Analysis';


export default function Main(props) {
  const [app, setApp] = useState('')
  const [module, setModule] = useState('')
  const [moduleView, setModuleView] = useState('')
  const [userInfo, setUserInfo] = useState({});
  const [companyInfo, setCompanyInfo] = useState({});
  const [showMenu, setShowMenu] = useState(false);
  const [showAdmin, setShowAdmin] = useState(false);
  const [showNextSteps, setShowNextSteps] = useState(false);
  const [groupId, setGroupId] = useState('') 
  const [render, setRender] = useState(false)
  const [dataPackage, setDataPackage] = useState()

  useEffect( () => {
    async function getUser() {
      try {
        const user = await Auth.currentAuthenticatedUser();
        const {data} = await API.graphql(graphqlOperation(queries.getUser, {userid: user.username}));
        if (data.getUser) {
          console.log("Signed-in User Set:", data.getUser)
          setUserInfo(data.getUser)
          const company = await API.graphql(graphqlOperation(queries.getCompany, {id: data.getUser.companyUserId}))
          setCompanyInfo(company.data.getCompany)
          console.log("Company Info Set:", company.data.getCompany)
        } else {
          console.log("Authenticated user not in app:", user.username)
        }
      } catch (error) {
        console.log('Error:', error);
      }
    }
    getUser()
  }, []);

  useEffect(() => {
      if (userInfo.permissions === 'Standard' && !userInfo.strategyRole) {setModule('interview'); setApp('stratPrep')}
      else if (userInfo.permissions === 'Standard' && userInfo.strategyRole === 'Interview Only') {setModule('interview'); setApp('stratPrep')}
      else {setModule('Home')}
    }, [userInfo])

  const startInterview = (data) => {
    setGroupId(data)
    setRender(!render)
  }

  function toggleAdmin() {
    setShowAdmin(!showAdmin);
  }

  function selectModule(data) {
    setModule(data)
    setRender(!render)
  }

  function selectView(data) {
    setModuleView(data)
  }

  function selectApp(data) {
    setApp(data)
  }

  function signOut() {
    console.log("Signing out...")
    props.signOut()
  }

  function runAnalysis() {
    setModule('analysis')
  }

  function toggleNextSteps() {
    setShowNextSteps(!showNextSteps)
  }

  function sendInfo(data) {
    setDataPackage(data)
  }


  return (
    <div className="Home">
        {showAdmin && <Admin closeAdmin={toggleAdmin} userInfo={userInfo}/>}
        {showNextSteps && <NextSteps toggleNextSteps={toggleNextSteps}/>}
      <aside className={"sidemenu" + (module==="interview" || app==="SWB" || app==="roots" ? " hold" : "")}>
        <div className="side-menu-header">
          {userInfo.permissions === 'SuperAdmin' || userInfo.permissions === 'Admin' ? 
            <div className= "button graphic" style={{ height: '50%', width: 'auto', aspectRatio: '1/1'}}>
              <img
                src="./Assets/menu icon_white.png"
                style={{width: '80%', height: '60%'}}
                onClick={() => { setShowMenu(!showMenu) }}
                alt="settings"
              />
          </div> : null}
          <div className='button text' 
              onClick={()=>{window.open('https://blendification.com/', '_blank')}}
              style={{height: 'auto', width: 'auto', display: 'flex', justifyContent: 'center', alignItems: 'center', maxWidth: '400px', margin: '10px'}}>
              <img src="./Assets/full-logo_white.png" style={{width: "100%"}}/>
          </div>
          {showMenu && <div className="main-menu">
            <div className="main-menu-item" onClick={()=>{setModule('Home'); setShowMenu(false)}}>Home</div>
            <div className="main-menu-item" onClick={()=>{setApp('stratPrep'); setModule('profile'); setShowMenu(false)}}>Strategy 360</div>
            <div className="main-menu-item" onClick={()=>{setApp('SWB'); setModule(''); setShowMenu(false)}}>Strategy Whiteboard</div>
            <div className="main-menu-item" onClick={()=>{setApp('roots'); setModule(''); setShowMenu(false)}}>Roots Groups</div>
            {userInfo.permissions === "SuperAdmin" && <div className="main-menu-item" onClick={()=>{setShowAdmin(true); setShowMenu(false)}}>Admin Panel</div>}
          </div>}
        </div>
        <div className="side-menu-body" >
          {userInfo.permissions !== 'Standard' && module==="Home" || module==="users" ? <HomeSidePanel selectModule={selectModule} companyInfo={companyInfo}/>
          : module==="interview" ? <Interview handleInterviewStart={startInterview} userInfo={userInfo}/>
          : app === 'stratPrep' ? <Navigation userInfo={userInfo} selectModule={selectModule} module={module} selectView={selectView}/>
          : app==="SWB" ? <SwbSidePanel userInfo={userInfo}/>
          : app==="roots" ? <RootsSidePanel userInfo={userInfo}/>
          : null}
        </div>
        <div className="side-menu-footer-links">
          {userInfo.permissions !== 'Standard' && module ==="interview" ? <div className='side-menu-footer-link' onClick={()=>{setModule('profile'); setGroupId('')}}>Admin Dashboard</div> : null}
          {app === 'stratPrep' && <div className="side-menu-footer-link" onClick={toggleNextSteps}>Next Steps</div>}
          <div className='side-menu-footer-link' onClick={signOut}>Sign Out</div>
        </div>
      </aside>
      <section className="Primary">
        <div>
          {module === 'Home' && <Home userInfo={userInfo} selectModule={selectModule} selectApp={selectApp}/>}
          {module === 'interview' && <ChatLog groupId={groupId} userInfo={userInfo}/>}
          {module==="companydescription" && <CompanyDescription userInfo={userInfo} selectModule={selectModule}/>}
          {module === "users" && <Users userInfo={userInfo} selectModule={selectModule}/> }
          {module==="whiteboard" && <WhiteboardNav userInfo={userInfo} nextsteps={toggleNextSteps}/>}
          {module==="profile" && <Profile userInfo={userInfo} companyInfo={companyInfo} selectModule={selectModule} nextsteps={toggleNextSteps}/>}
          {module==="interviews" && <InterviewAdmin userInfo={userInfo} selectModule={selectModule} runAnalysis={runAnalysis} nextsteps={toggleNextSteps} info={sendInfo} selectView={selectView}/>}
          {module==="analysis" && <Analysis userInfo={userInfo} info={dataPackage} view={moduleView}/> }
          {app==="SWB" ? <SWB userInfo={userInfo} companyInfo={companyInfo}/>
          : app==="roots" ? <Roots userInfo={userInfo} companyInfo={companyInfo}/> : null}
        </div>
      </section>
    </div>
  );
}

import React, {useState, useEffect} from 'react'
import Select from 'react-select';
import './Settings.css'
import LoadTemplate from '../SuperAdmin/LoadTemplate'
import { API, graphqlOperation } from 'aws-amplify';
import * as mutations from '../../graphql/mutations';
import * as queries from '../../graphql/queries';


export default function TemplateBuilder(props){
    const [create, setCreate] = useState(false);
    const [update, setUpdate] = useState(false);
    const [showSuccessMessage, setShowSuccessMessage] = useState([false, '']);
    const [selectedOption, setSelectedOption] = useState('');
    const [templateData, setTemplateData] = useState({
        GPTmodel: 'gpt-3.5-turbo',
        MaxTokens: 200,
        Temperature: 0.6,
        tempid: '',
      });
    
    function handleChange(e) {
        setTemplateData(prevState => {
          if (e.target.name === 'temp') {
            return {...prevState, Temperature: e.target.value}
          } else if (e.target.name === 'maxTokens') {
            return {...prevState, MaxTokens: e.target.value}
            } else if (e.target.name === 'profile') {
            return {...prevState, profile: e.target.checked}
            } else if (e.target.name === 'goal') {
            return {...prevState, goal: e.target.value}
            } else if (e.target.name === 'context') {
            return {...prevState, systemcontext: e.target.value}
            } else if (e.target.name === 'instruction') {
            return {...prevState, instruction: e.target.value}
            } else if (e.target.name === 'templateName') {
            return {...prevState, name: e.target.value}
          } else if (e.target.name === 'firstMessage') {
            return {...prevState, firstMessage: e.target.value}
          } else {
            return prevState;
          }
        });
      }
    
    async function setTemplate(info) {
        if (!info) {
            return
        } else if (info === 'new') {
            setTemplateData({
                GPTmodel: 'gpt-3.5-turbo-16k',
                MaxTokens: 200,
                Temperature: 0.6,
                name: '',
                profile: true,
                goal: 'You are an AI-assistant interviewing the user to gather information that will be helpful for strategic analysis and planning. The overarching goal of this interview is to learn what the user thinks could be improved or should be changed regarding the company strategy. Discovery should be achieved by asking single a question at a time that enable step-by-step logical progression. The user should be given the opportunity to steer the interview down a particular path that is most relevant to them, but the overarching goal should be aimed at getting the user to provide a holistic perspective on all areas of the business that are important such as culture, customer and market, product, operations, and people. If the user asks a question, then answer appropriately, but then go back to asking the user questions. Give the user opportunities to give additional input that should be considered during strategic decision-making. Remember to only ask one question at a time and do not provide recommendations because information for strategic analysis is still being gathered. Also, do not be redundant with your messages by repeating back the same information that the user provides or repeating the same pleasantries such as “thank your for sharing”. Every assistant message should have a question seeking more information or details from the user until the user says they have nothing more to share.',
                systemcontext: 'Here is some context for the interview:',
                instruction: 'Ask the user another question to learn more about the current topic or to move on to the next topic, whichever is appropriate.',
              })
            setCreate(true)
            setUpdate(false)
        } else {
        const {data} = await API.graphql(graphqlOperation(queries.getTemplate, { tempid: info }));
            setTemplateData(prevState => ({...prevState, ...data.getTemplate}))
            setUpdate(true)
            setCreate(false)
            setSelectedOption({ value: data.getTemplate.GPTmodel, label: data.getTemplate.GPTmodel })
            console.log('templateData', data.getTemplate)
        }
        setShowSuccessMessage([false, ''])
    }

    async function handleCreate() {
        const tempid = templateData.name + (Math.floor(Math.random() * 900000) + 100000)
        const inputData = {...templateData, tempid: tempid}
        try {
            const newTemplate = await API.graphql(graphqlOperation(mutations.createTemplate, { input: inputData }));
            console.log('Created new template: ', newTemplate);
            setCreate(false)
            setShowSuccessMessage([true, 'Template Created'])
          } catch (error) {
            console.log('Error creating template: ', error);
          }
    }

    async function handleUpdate() {
        const { createdAt, updatedAt, instructions, ...inputData } = templateData;
        console.log('inputData', inputData)
        try {
            const updatedTemplate = await API.graphql(graphqlOperation(mutations.updateTemplate, { input: inputData }));
            console.log('Updated template: ', updatedTemplate);
            setUpdate(false)
            setShowSuccessMessage([true, 'Template Updated'])
          } catch (error) {
            console.log('Error updating template: ', error);
          }
    }

    async function handleDelete() {
        const confirmed = window.confirm("Confirm you want to delete this interview template.")
        if (confirmed) {
            console.log('template to be deleted:', templateData.tempid)
            try {
                const deletedTemplate = await API.graphql(graphqlOperation(mutations.deleteTemplate, { input: {tempid: templateData.tempid}}));
                console.log('Deleted template: ', deletedTemplate);
                setUpdate(false)
                setShowSuccessMessage([true, 'Template deleted'])
            } catch (error) {
                console.log('Error deleting template: ', error);
            }
        }
    }

    const modelOptions = [
        { value: 'gpt-3.5-turbo-16k', label: 'gpt-3.5-turbo-16k' },
        { value: 'gpt-4', label: 'gpt-4' },
    ]

    function handleModelSelect(option) {
        setSelectedOption(option);
        setTemplateData(prevState => ({...prevState, GPTmodel: option.value}))
      }

    return (
        <div className="settings-templatebuilder">
            <div className="settings-template-buttons">
                <div>Template Name</div>
                <LoadTemplate setTemplate={setTemplate} updated={showSuccessMessage} />
                <div>
                    { update && 
                        (<button className="button small" onClick={handleUpdate}>Update</button>)}
                    { update && 
                    (<button className="button small" onClick={handleDelete}>Delete</button>)}
                    { create && (
                        <>
                            <div className="settings-template-option">
                            New Template Name
                            <input value={templateData.name} name="templateName" onChange={handleChange}></input>
                            </div>
                            <button className="button small" disabled={!create} onClick={handleCreate}>Create</button>
                        </>)}
                </div>
                { showSuccessMessage[0] && <div className="settings-template-success">{showSuccessMessage[1]}</div>}
            </div>
            <div className="settings-template-options">
                <div className="settings-template-option">
                    Model:
                    <Select
                    value={selectedOption}
                    onChange={handleModelSelect}
                    options={modelOptions}
                    placeholder="Select a Model"
                    className="my-select"
                    />
                </div>
                <div className="settings-template-option">
                    Max Tokens: {templateData.MaxTokens}
                    <input type="range" value={templateData.MaxTokens} name="maxTokens" min="10" max="1000" step="10" onChange={handleChange}></input>
                </div>
                <div className="settings-template-option">
                    Temperature: {templateData.Temperature}
                    <input type="range" value={templateData.Temperature} name="temp" min="0" max="1" step="0.1" onChange={handleChange}></input>
                </div>
                <div className="settings-template-option">
                    Use Company Profile?
                    <input type="checkbox" name="profile" checked={templateData.profile} onChange={handleChange}></input>
                </div>
            </div>
            <div className="settings-instructions">
                <div className="instruction">
                    <div>System Goal</div>
                    <textarea className="instruction-text" name="goal" value={templateData.goal} onChange={handleChange}></textarea>
                </div>
                <div className="instruction">
                    <div>System Context</div>
                    <textarea className="instruction-text" name="context" value={templateData.systemcontext} onChange={handleChange}></textarea>
                </div>
                <div className="instruction">
                    <div>Next Message Instruction</div>
                    <textarea className="instruction-text" name="instruction" value={templateData.instruction} onChange={handleChange}></textarea>
                </div>
                <div className="instruction">
                    <div>First Chat Message</div>
                    <textarea className="instruction-text" name="firstMessage" value={templateData.firstMessage} onChange={handleChange}></textarea>
                </div>
            </div>            
        </div>
    )
}


import React, {useState, useEffect} from 'react'
import './meetings.css'
import {API, graphqlOperation} from 'aws-amplify';
import * as queries from '../../graphql/queries';
import * as mutations from '../../graphql/mutations';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";



export default function MeetingPanel(props) {

    const [tab, setTab] = useState('meetings')
    const [meetingInfo, setMeetingInfo] = useState({name: '', type: '', notes: ''})
    const [meetings, setMeetings] = useState([])
    const [agendaItems, setAgendaItems] = useState([])
    const [meetingSelected, setMeetingSelected] = useState(false)
    const [showConfirmation, setShowConfirmation] = useState(false)
    const [noteChanges, setNoteChanges] = useState(false)
    const [editMeeting, setEditMeeting] = useState(false)

    useEffect(() => {
        if (props.meetingType && props.userInfo)
            getMeetings(props.meetingType)
    }, [props.meetingType])
    
    async function getMeetings(filter) {
        try {
            const {data} = await API.graphql(graphqlOperation(queries.getCompany, {id: props.userInfo.companyUserId}))
            const meetingsList = data.getCompany.meetings.items
            const filteredMeetings = meetingsList.filter(meeting => meeting.type === filter)
            console.log('Meetings:', filteredMeetings)
            setMeetings(filteredMeetings)
        } catch (error) {
            console.log('Error:', error)
        }
    }

    const rootsGroupAgenda = [
        {name: 'Fortify Culture', status: "unchecked", description: 'Select an item from the Culture block and spend a few minutes discussing how you see this alive in the organization.'},
        {name: 'Review Actions', status: "unchecked", description: 'Review your Strategic Actions and report on status.'},
        {name: 'Evaluate Progress', status: "unchecked", description: 'Evaluate progress for each Strategic Outcome.'},
        {name: 'Identify Solutions', status: "unchecked", description: 'Describe how the group plans to resolve any issues that were discovered.'},
    ]
    const connectionAgenda = [
        {name: 'Fortify Culture', status: "unchecked", description: 'Select an item from the Culture block and spend a few minutes discussing how you see this alive in the organization.'},
        {name: 'Review Progress', status: "unchecked", description: 'Each Roots Group summarizes the status of their respective Strategic Outcomes.'},
        {name: 'Propose Solutions', status: "unchecked", description: 'Each Roots Group advises what needs to happen to course correct any Strategic Outcomes that have fallen off path.'},
        {name: 'Leadership Approvals', status: "unchecked", description: 'Approve any changes or resource requests that accompany decided solutions for each Roots Group.'},
    ]
    const calibrationAgenda = [
        {name: 'Fortify Culture', status: "unchecked", description: 'Select an item from the Culture block and spend a few minutes discussing how you see this alive in the organization.'},
        {name: 'Assess Results', status: "unchecked", description: 'Guage strategic execution by comparing actual results to expected results.'},
        {name: 'Evaluate Roots Groups', status: "unchecked", description: 'Determine if the effectiveness of the Roots Groups is satisfactory to achieve strategic success.'},
        {name: 'Calibrate Strategy Whiteboard', status: "unchecked", description: 'Go through each block of the Strategy Whiteboard and verify information is still currect and correct.'},
    ]
    const destinationAgenda = [
        {name: 'Strategy Preparation', status: "unchecked", description: 'Complete Strategy 360 interviews and/or gather any external/internal information relevant to strategic planning.'},
        {name: 'Group Session', status: "unchecked", description: 'Review the Strategy 360 output and/or use the destination meeting workbook as a facilitation guide.'},
        {name: 'Strategy Whiteboard', status: "unchecked", description: 'Convert the Strategy 360 output into final Strategy Whiteboard blocks, or manually enter block items directly.'},
        {name: 'Activate', status: "unchecked", description: 'Form or update the Roots Groups app and hold a Connection Meeting to activate the new Strategy Whiteboard.'},
    ]

    function formatDate(dateString) {
        const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        const date = new Date(`${dateString}T12:00:00`);
        const year = date.getFullYear();
        const month = months[date.getMonth()];
        const day = date.getDate();
    
        return `${month} ${day.toString().padStart(2, '0')}, ${year}`;
    }
    
    const meetingList = meetings.map((meeting, index) => {
        return (
            <div className="meetingPanel-meeting-item-container" key={index} >
                <div className="meetingPanel-meeting-item" onClick={()=>{handleSelect(meeting)}}>
                    <div className="meetingPanel-meeting-item-name">{meeting.name}</div>
                    <div className="meetingPanel-meeting-item-date">{formatDate(meeting.meetingDate)}</div>
                </div>
                <div className="meetingPanel-item-button-container">
                    <div className="meetingPanel-item-button remove" onClick={()=>{confirmDeleteMeeting(meeting)}}></div>
                </div>
            </div>
        )
    })

    function handleCheckBox (index) {
        let newAgendaItems = [...agendaItems]
        newAgendaItems[index].status = newAgendaItems[index].status === 'checked' ? 'unchecked' : 'checked'
        setAgendaItems(newAgendaItems)
    }

    const agendaList = agendaItems.map((item, index) => {
        return (
        <div className="meetingPanel-agenda-item" key={index}>
            <div className="meetingPanel-agenda-item-checkbox" onClick={()=>handleCheckBox(index)}>
                {item.status === 'checked' ? <img src={'./Assets/box_checked_black.png'} style={{width: '100%', cursor: 'pointer'}}></img> :
                <img src={'./Assets/box_unchecked_black.png'} style={{width: '100%', cursor: 'pointer'}}></img>}
            </div>
            <div className="meetingPanel-agenda-item-info">
                <div className="meetingPanel-agenda-item-name">{item.name}</div>
                <div className="meetingPanel-agenda-item-description">{item.description}</div>
            </div>
        </div>
        )
    })

    const meetingsBox = (
        <div className="meetingPanel-content-box">
            <div className="meetingPanel-new-meeting">
                <div className="meetingPanel-new-meeting-button" onClick={handleStart}></div>
                <div className="meetingPanel-new-meeting-text" onClick={handleStart}>Start a new meeting</div>
            </div>   
            {meetingList}
        </div>
    )

    function handleSelect(meeting) {
        setMeetingInfo(meeting)
        getAgenda(meeting.id)
        setTab('agenda')
        setMeetingSelected(true)
    }

    async function getAgenda(meetingId) {
        try {
            const {data} = await API.graphql(graphqlOperation(queries.getMeeting, {id: meetingId}))
            const agendaList = data.getMeeting.agenda.items
            setAgendaItems(agendaList)
        } catch (error) {
            console.log('Error:', error)
        }
    }

    async function handleStart() {
        let name
        let agenda
        if (props.meetingType === 'rootsgroup') {
            agenda = rootsGroupAgenda
            name = 'Roots Group Meeting'
        } else if (props.meetingType === 'connection') {
            agenda = connectionAgenda
            name = 'Connection Meeting'
        } else if (props.meetingType === 'calibration') {
            agenda = calibrationAgenda
            name = 'Calibration Meeting'
        } else if (props.meetingType === 'destination') {
            agenda = destinationAgenda
            name = 'Destination Meeting'
        }
        
        const date = new Date().toISOString().split('T')[0] //
        console.log('Date:', date)
        let newMeeting = {
            companyMeetingsId: props.userInfo.companyUserId,
            name: name,
            type: props.meetingType,
            notes: '',
            meetingDate: date
        }
        setMeetingInfo(newMeeting)
        setMeetingSelected(true)
        setTab('agenda')
        let meetingId
        try {
            const {data} = await API.graphql(graphqlOperation(mutations.createMeeting, {input: newMeeting}))
            console.log('Created New Meeting:', data.createMeeting)
            meetingId = data.createMeeting.id
        } catch (error) {
            console.log('Error:', error)
        }
        newMeeting.id = meetingId
        const newMeetings = [...meetings]
        newMeetings.push(newMeeting)
        setMeetings(newMeetings)
        const newAgenda = agenda.map(item => {
            return {
                meetingAgendaId: meetingId,
                name: item.name,
                status: item.status,
                description: item.description
            }
        })
        setAgendaItems(newAgenda)
        try {
            const promises = newAgenda.map(item => {
                return API.graphql(graphqlOperation(mutations.createAgendaItem, { input: item }));
            });
            const results = await Promise.all(promises);
            console.log("Agenda Items Created:", results);
        } catch (error) {
            console.error("Error processing agenda items:", error);
            throw error;
        }
    }

    function confirmDeleteMeeting(meeting) {
        console.log('Meeting to Delete:', meeting)
        getAgenda(meeting.id)
        setMeetingInfo(meeting)
        setShowConfirmation(true)
        setMeetingSelected(false)
    }

    async function handleDeleteMeeting() {
        console.log('Deleting Agenda Items:', agendaItems)
        const promises = agendaItems.map(item => {
            return API.graphql(graphqlOperation(mutations.deleteAgendaItem, { input: {id: item.id} }));
        });
        const results = await Promise.all(promises);
        console.log('Deleting Meeting:', meetingInfo)
        const newMeetings = meetings.filter(item => item.id !== meetingInfo.id)
        setMeetings(newMeetings)
        setShowConfirmation(false)
        try {
            const {data} = await API.graphql(graphqlOperation(mutations.deleteMeeting, {input: {id: meetingInfo.id}}))
            console.log('Deleted Meeting:', data.deleteMeeting)
        } catch (error) {
            console.log('Error:', error)
        }
    }

    const agenda = (
        <div className="meetingPanel-content-box">
            {agendaList}
        </div>
    )

    const notes = (
        <textarea className="meetingPanel-notes-box" placeholder="Type your meeting notes here" value={meetingInfo.notes} onChange={handleNotes}>
        </textarea>
    )

    function handleNotes(event) {
        const updatedMeetingInfo = {...meetingInfo}
        updatedMeetingInfo.notes = event.target.value
        setMeetingInfo(updatedMeetingInfo)
        setNoteChanges(true)
    }

    async function saveNotes() {
        try {
            const {data} = await API.graphql(graphqlOperation(mutations.updateMeeting, {input: {id: meetingInfo.id, notes: meetingInfo.notes}}))
            console.log('Meeting Notes Saved:', data.updateMeeting)
        }
        catch (error) {
            console.log('Error:', error)
        }
        setNoteChanges(false)
    }

    function openVideoLibrary() {
        window.open('https://vimeo.com/683442139?share=copy', '_blank');
    }
    
    function downloadWorkbook() {
        window.open('https://blendification.com/wp-content/uploads/2023/02/Blendification.Strategy-Whiteboard-Workbook-Download.pdf', '_blank');
    }

    const assist = (
        <div className="meetingPanel-content-box" >
            {props.meetingType === "destination" ? <div className="meetingPanel-assist-link" onClick={()=>window.open('https://vimeo.com/683442025?share=copy', '_blank')}>Destination Meeting Video</div> : 
            props.meetingType === "calibration" ? <div className="meetingPanel-assist-link" onClick={()=>window.open('https://vimeo.com/683442008?share=copy', '_blank')}>Calibration Meeting Video</div> :
            props.meetingType === "connection" ? <div className="meetingPanel-assist-link" onClick={()=>window.open('https://vimeo.com/683441988?share=copy', '_blank')}>Connection Meeting Video</div> :
            props.meetingType === "rootsgroup" ? <div className="meetingPanel-assist-link" onClick={()=>window.open('https://vimeo.com/683442139?share=copy', '_blank')}>Roots Group Meeting Video</div> :
            null}
            {props.meetingType === "destination" && <div className="meetingPanel-assist-link" onClick={downloadWorkbook} >Download Workbook</div>}
            <div><img src="./Assets/meetings.png" style={{height: '500px'}}alt="meetings" /></div>
        </div>
    )

    const confirmationWindow = (
        <div className="popup-mask standard">
            <div className="popup" style={{height: '200px'}}>
                <div>Are you sure you want to delete this meeting?</div>
                <div>
                    <button className="button small" onClick={handleDeleteMeeting}>Delete</button>
                    <button className="button small" onClick={()=>setShowConfirmation(false)}>Cancel</button>
                </div>
            </div>
        </div>
    )

    const saveNoteButton = (
        <div className="meetingPanel-notes-button-container">
            <div className="meetingPanel-item-button" onClick={saveNotes}></div>
        </div>
    )

    function handleChangeMeetingName(event) {
        const updatedMeetingInfo = {...meetingInfo}
        updatedMeetingInfo.name = event.target.value
        setMeetingInfo(updatedMeetingInfo)
        const newMeetingList = [...meetings]
        const index = newMeetingList.findIndex(item => item.id === meetingInfo.id)
        newMeetingList[index].name = event.target.value
        setMeetings(newMeetingList)
    }

    function handleDateChange(date) {
        const updatedMeetingInfo = {...meetingInfo}
        updatedMeetingInfo.meetingDate = date.toISOString().split('T')[0]
        setMeetingInfo(updatedMeetingInfo)
        const newMeetingList = [...meetings]
        const index = newMeetingList.findIndex(item => item.id === meetingInfo.id)
        newMeetingList[index].meetingDate = date.toISOString().split('T')[0]
        setMeetings(newMeetingList)
    }

    async function saveMeetingDetails() {
        try {
            const {data} = await API.graphql(graphqlOperation(mutations.updateMeeting, {input: {id: meetingInfo.id, name: meetingInfo.name, meetingDate: meetingInfo.meetingDate}}))
            console.log('Meeting Details Saved:', data.updateMeeting)
        }
        catch (error) {
            console.log('Error:', error)
        }
        setEditMeeting(false)
    }


    const editMeetingDetails = (
        <div className="meetingPanel-meeting-name-edit">
            <div className="meetingPanel-item-button" onClick={saveMeetingDetails}></div>
            <input value={meetingInfo.name} onChange={handleChangeMeetingName}></input>
            <div><DatePicker 
                selected={new Date(`${meetingInfo.meetingDate}T12:00:00`)} 
                onChange={date => handleDateChange(date)} 
                dateFormat="yyyy-MM-dd"
                className="meetingPanel-date-picker"
                />
            </div>
        </div>
    )

    const meetingDetails = (
        <div className="meetingPanel-meeting-name click" onClick={()=>setEditMeeting(true)}>
            {meetingInfo.name + ": " + formatDate(meetingInfo.meetingDate)}
        </div>
    )

    const newMeeting = (
        <div className="meetingPanel-meeting-name">Select or Start a New Meeting</div>
    )


    return (
        <div className="meetingPanel">
            {showConfirmation && confirmationWindow}
            {meetingSelected ? <div className="meetingPanel-tabs">
                <div className={`meetingPanel-tab ${tab==='meetings' && 'active'}`} onClick={()=>setTab('meetings')}>Meetings</div>
                <div className={`meetingPanel-tab ${tab==='agenda' && 'active'}`} onClick={()=>setTab('agenda')}>Agenda</div>
                <div className={`meetingPanel-tab ${tab==='notes' && 'active'}`} onClick={()=>setTab('notes')}>Notes</div>
                <div className={`meetingPanel-tab ${tab==='videos' && 'active'}`} onClick={()=>setTab('videos')}>Assist</div>
            </div> : 
            <div className="meetingPanel-tabs">
            </div> }
            <div className="meetingPanel-box">
                {tab === 'meetings' ? newMeeting 
                : editMeeting ? editMeetingDetails
                : meetingDetails}
                <div className="meetingPanel-meeting-name tab">
                    {tab === 'meetings' ? "Meetings" : tab === 'agenda' ? "Agenda" : tab === "notes" ? "Notes" : "Assist"}
                    {tab === 'notes' && noteChanges ? saveNoteButton : null}
                </div>
                <div className="standard-divider short"></div>
                {tab === 'meetings' ? meetingsBox : tab === 'agenda' ? agenda : tab === 'notes' ? notes : assist}
            </div>
            <div className="meetingPanel-footer">
                <button className="button small light" onClick={props.closeMeeting}>Close</button>
            </div>
        </div>
    )
}
import './App.css';
import React, { useState, useEffect} from 'react';
import Main from './Main'
import {Auth} from 'aws-amplify';
import Access from './components/SignIn/Access';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect( () => {
    async function getUser() {
      try {
        const user = await Auth.currentAuthenticatedUser();
        if (user) {
          setIsAuthenticated(true);
        }
      } catch (error) {
        console.log('Error:', error);
      }
    }
    getUser()
  }, []);

  async function signOut() {
    try {
      await Auth.signOut();
      setIsAuthenticated(false);
    } catch (error) {
      console.log('error signing out: ', error);
    }
  }

  return (
    <div className="App">
      {isAuthenticated ? <Main signOut={signOut}/> : <Access authentication={(value) => setIsAuthenticated(value)}/>}
    </div>
  );
}

export default App
